import { datadogRum } from '@datadog/browser-rum';
import { environment } from '@environments/environment';

export function initializeRUM() {
  datadogRum.init({
    applicationId: '779515bc-632a-456a-9e65-d002c220fb4b',
    clientToken: 'puba6ef816d6f772d4abc5fb7de05276886',
    site: 'ap1.datadoghq.com',
    service: 'mnes-fundus',
    env: environment.environment,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

export function rumAddUserInformation(user: {
  id: string;
  email?: string;
  name: string;
  facilityName?: string;
  facilityId?: string;
}) {
  datadogRum.setUser({
    email: user.email,
    name: user.name,
    id: user.id,
    facilityId: user.facilityId,
    facilityName: user.facilityName,
  });
}
