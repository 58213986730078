<ng-container *ngIf="!loaded; else select">
  <ngx-skeleton-loader
    [theme]="{
      width: '264px',
      height: '20px',
      'border-radius': '10px'
    }"
  ></ngx-skeleton-loader>
</ng-container>
<ng-template #select>
  <textarea
    [placeholder]="placeholder"
    [formControl]="textArea"
    (ngModelChange)="onChange($event)"
    [readOnly]="readonly"
    *ngIf="!textonly"
    (focus)="this.focusTextArea.emit(true)"
    (blur)="this.focusTextArea.emit(false)"
    [class.error]="errorMessage"
  ></textarea>
  <div class="text-only-version" *ngIf="textonly">
    {{textArea.value}}
  </div>
  <div class="error-message" *ngIf="errorMessage && !textonly">
    {{errorMessage}}
  </div>
</ng-template>
