export type DownloadEncoding = 'shift-jis' | 'utf-8';

export type DownloadType = 'pdf' | 'pdflist' | 'csvlist' | 'pdf,pdflist' | 'pdf,csvlist' | 'pdf,pdflist,csvlist';

export type FileGrouping = '' | 'byStudyDate' | 'byDiagnosisDate' ;

export const DownloadTypeOptions = [
  {label: '個票 (PDF)', value: 'pdf'},
  {label: '一覧 (PDF)', value: 'pdflist'},
  {label: '一覧 (CSV)', value: 'csvlist'},
  {label: '個票 (PDF) と一覧 (PDF)', value: 'pdf,pdflist'},
  {label: '個票 (PDF) と一覧 (CSV)', value: 'pdf,csvlist'},
  {label: 'すべて', value: 'pdf,pdflist,csvlist'},
];

export const GroupingOptions = [
  {label: '受診日単位', value: 'byStudyDate'},
  {label: '診断日単位', value: 'byDiagnosisDate'},
  {label: 'すべて', value: ''},
];
