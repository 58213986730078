import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Subscription} from "rxjs";
import {MatSelect} from "@angular/material/select";
export interface OptionData {
  value: string;
  label: string;
}

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true,
    },
  ],
})
export class MultiSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild('input') input!: ElementRef<HTMLInputElement>;
  @ViewChild('selectPanel') selectPanel!: MatSelect;
  @Input() set options( options: Array<string | OptionData>) {
    let optionsObj = [];

    for(let option of options) {
      if(typeof option === 'string') {
        optionsObj.push({
          value: option,
          label: option,
        });
      } else {
        optionsObj.push(option);
      }
    }
    this._options = optionsObj;
  }

  @Input() placeholder?: string;
  @Input() disabled : boolean = false;
  @Output() changed = new EventEmitter<void>();
  selectControl = new FormControl([] as string[]);
  selectControlChangeSubscription! : Subscription;

  onChange!: Function;
  OnTouched!: Function;


  _options: OptionData[] = []

  get values() {
    return this.selectControl.value ?? [];
  }

  constructor() {}

  async ngOnInit() {
    this.selectControlChangeSubscription = this.selectControl.valueChanges.subscribe((value) => {
        if(this.onChange) {
          this.onChange(value);
          this.selectPanel.close();
        }
        this.changed.emit();
      }
    );
  }

  writeValue(obj: any) {
    const values = obj ? obj : [] as string[];
    this.selectControl.setValue(values);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.OnTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  remove(value: string) {
    let values = this.selectControl.value ? [...this.selectControl.value] : [];
    const index = values.indexOf(value);

    if (index >= 0) {
      values.splice(index, 1);
      this.selectControl.setValue(values);
    }
  }

  removeAll($event : Event) {
    $event.stopPropagation();
    this.selectControl.setValue([] as string[]);
  }

  getLabelFromValue(value: string) {
    return this._options.find(option => option.value === value)?.label ?? '';
  }

  ngOnDestroy() {
    this.selectControlChangeSubscription.unsubscribe();
  }
}

