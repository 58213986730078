import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { Injectable } from '@angular/core';

@Injectable()
export class DiagnosisMatPaginatorIntl extends MatPaginatorIntl {
  override itemsPerPageLabel = '表示件数';

  override getRangeLabel = (page: number, pageSize: number, length: number) => {
    return `${page * pageSize + 1} - ${(page + 1) * pageSize} / ${length}`;
  };
}
