import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-and-select',
  templateUrl: './input-and-select.component.html',
  styleUrls: ['./input-and-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputAndSelectComponent),
      multi: true,
    },
  ],
})
export class InputAndSelectComponent implements ControlValueAccessor {
  @Input() options!: string[] | {[p: string]: string};
  @Input() color: 'light' | 'error' | '' = '';
  @Input() placeholder?: string;
  @Input() readonly = false;
  @Input() selectable = true;
  @Input() opacity = 1;
  @Input() disabled = false;
  @Input() hideDropdownIcon = false;

  @Input() selectTooltip = '';

  value = { email: '', role: '' };
  onChange!: Function;
  OnTouched!: Function;

  writeValue(obj: any) {
    obj && (this.value = obj);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.OnTouched = fn;
  }

  setInputValue(value: string) {
    this.value.email = value;
    this.onChange(this.value);
  }

  setSelectValue(value: string) {
    this.value.role = value;
    this.onChange(this.value);
  }
}
