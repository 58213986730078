import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

import {
  AssignmentResponse,
  AssignmentRequest,
  AssignmentRequestResult,
} from '@app/core/models';
import { environment } from '@environments/environment';
import { TransferDiagnosticAssignmentResponse } from '@app/core/models/assignment.model';

@Injectable({
  providedIn: 'root',
})
export class AssignmentsApiService {
  apiPath = `${environment.apiUrl}`;

  constructor(private http: HttpClient) {}

  /**
   * 割当情報取得API
   * @param id 眼底検査ID
   * @returns 割当情報
   */
  getAssignments(id: string) {
    return firstValueFrom(
      this.http.get<AssignmentResponse>(
        `${this.apiPath}/fundus-studies/${id}/assignments`
      )
    );
  }

  /**
   * 割当情報一括作成更新API
   * @param id ユーザーID
   * @param request 割当情報
   * @returns 割当情報
   */
  upsertAssignments(id: string, request: AssignmentRequest) {
    return firstValueFrom(
      this.http.post<AssignmentRequestResult>(
        `${this.apiPath}/users/${id}/assignments`,
        request
      )
    );
  }

  /**
   * 割当情報一括削除API
   * @param id ユーザーID
   * @param request 割当情報
   * @returns 割当情報
   */
  deleteAssignments(id: string, request: AssignmentRequest) {
    return firstValueFrom(
      this.http.post<AssignmentRequestResult>(
        `${this.apiPath}/users/${id}/assignments/delete`,
        request
      )
    );
  }

  /**
   * 診断権限を移譲するAPI
   * @param id 眼底検査ID
   * @param userId ユーザID
   * @returns 診断権限を移譲情報
   */
  transferDiagnosticAssignment(id: string, userId: string) {
    return firstValueFrom(
      this.http.post<TransferDiagnosticAssignmentResponse>(
        `${this.apiPath}/fundus-studies/${id}/assignments`,
        { userId }
      )
    );
  }
}
