import {Component, Input, forwardRef, OnInit, Output, EventEmitter} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
})
export class TextareaComponent implements ControlValueAccessor, OnInit {
  @Input() options?: string[];
  @Input() placeholder = '';
  @Input() loaded: boolean = true;
  @Input() readonly = false;
  @Input() textonly = false;
  @Output() focusTextArea = new EventEmitter();
  @Input() errorMessage = '';

  value = '';
  textArea!: UntypedFormControl;
  disabled = false;
  onChange!: Function;
  OnTouched!: Function;

  ngOnInit() {
    this.textArea = new UntypedFormControl({value: this.value, disabled: this.disabled});
  }

  writeValue(obj: any) {
    this.textArea.setValue(obj || '');
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.OnTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if(isDisabled) {
      this.textArea.disable();
    } else {
      this.textArea.enable();
    }

  }
}
