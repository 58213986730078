import { Injectable } from '@angular/core';
import * as cornerstone from "cornerstone-core";

// @ts-ignore
import cornerstoneTools from "cornerstone-tools/dist/cornerstoneTools.js"

// @ts-ignore
import cornerstoneMath from "cornerstone-math/dist/cornerstoneMath.js"

// @ts-ignore
import Hammer from 'hammerjs/hammer.js'

// @ts-ignore
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader/dist/cornerstoneWADOImageLoader.bundle.min";

// @ts-ignore
import cornerstoneWebImageLoader from "cornerstone-web-image-loader/dist/cornerstoneWebImageLoader"

import dicomParser from "dicom-parser";

interface DicomDisplayOptions {
  height?: number;
  width?: number;
  enableZoom?: boolean;
  enablePan?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DicomImagesService {
  constructor() { }

  async loadImage(url: string, element: HTMLElement, type : "dicom" | "web"= "dicom" , options?: DicomDisplayOptions) {
    setTimeout(async () => {
      if(type === "dicom") {
        await this._loadDicom(url, element, options);
      } else {
        await this._loadWebImage(url, element, options);
      }

    });
  }

  async preloadImage(url: string, type : "dicom" | "web"= "dicom") {
    let imageId : string;

    if(type === "dicom") {
      imageId = "wadouri:" + url;
    } else {
      imageId = url;
    }
    cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
    cornerstoneWebImageLoader.external.cornerstone = cornerstone;
    cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

    cornerstoneTools.external.cornerstone = cornerstone;

    await cornerstone.loadAndCacheImage(imageId);

  }

  private async _loadDicom(url: string, element: HTMLElement, options?: DicomDisplayOptions) {
    const imageId = "wadouri:" +  url;

    element.style.height = (options?.height ?? 300) + "px";
    element.style.width = (options?.width ?? 408) + "px";

    // @ts-ignore
    cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
    cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

    cornerstoneTools.external.cornerstone = cornerstone;
    cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
    cornerstoneTools.external.Hammer = Hammer;

    cornerstoneTools.init();

    localStorage.setItem("debug", "cornerstoneTools");

    cornerstone.enable(element);

    cornerstoneWADOImageLoader.configure({
      beforeSend: function() {},
    });

    if(imageId) {
      try {
        const image = await cornerstone.loadAndCacheImage(imageId);
        const viewport = cornerstone.getDefaultViewportForImage(element, image);
        cornerstone.displayImage(element, image, viewport);

        if(options?.enableZoom) {
          cornerstoneTools.addToolForElement(element, cornerstoneTools.ZoomMouseWheelTool)
          cornerstoneTools.setToolActiveForElement(element,'ZoomMouseWheel', {mouseButtonMask: 1}, ['MouseWheel'])
        }

        if(options?.enablePan) {
          cornerstoneTools.addToolForElement(element, cornerstoneTools.PanTool)
          cornerstoneTools.setToolActiveForElement(element,'Pan', {mouseButtonMask: 1}, ['Mouse'])
        }

      } catch (error) {
        console.error("Error loading and displaying image:", error);
      }
    }
  }

  private async _loadWebImage(url: string, element: HTMLElement, options?: DicomDisplayOptions) {
    const imageId = url;

    element.style.height = (options?.height ?? 300) + "px";
    element.style.width = (options?.width ?? 408) + "px";

    // @ts-ignore
    cornerstoneWebImageLoader.external.cornerstone = cornerstone;

    cornerstoneTools.external.cornerstone = cornerstone;
    cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
    cornerstoneTools.external.Hammer = Hammer;

    cornerstoneTools.init();

    localStorage.setItem("debug", "cornerstoneTools");

    cornerstone.enable(element);

    cornerstoneWADOImageLoader.configure({
      beforeSend: function() {},
    });

    if(imageId) {
      try {
        const image = await cornerstone.loadAndCacheImage(imageId);
        const viewport = cornerstone.getDefaultViewportForImage(element, image);
        cornerstone.displayImage(element, image, viewport);

        if(options?.enableZoom) {
          cornerstoneTools.addToolForElement(element, cornerstoneTools.ZoomMouseWheelTool)
          cornerstoneTools.setToolActiveForElement(element,'ZoomMouseWheel', {mouseButtonMask: 1}, ['MouseWheel'])
        }

        if(options?.enablePan) {
          cornerstoneTools.addToolForElement(element, cornerstoneTools.PanTool)
          cornerstoneTools.setToolActiveForElement(element,'Pan', {mouseButtonMask: 1}, ['Mouse'])
        }

      } catch (error) {
        console.error("Error loading and displaying image:", error);
      }
    }
  }

  async extractDicomDataFromFile(file: File) {
    const dicomArrayBuffer = await file.arrayBuffer();
    const uint8Array = new Uint8Array(dicomArrayBuffer);
    const dicomParser = require('dicom-parser');
    const dataSet = dicomParser.parseDicom(uint8Array);

    const dicomData : {[key: string]: string} = {};
    const elements = dataSet.elements;
    const byteArray = dataSet.byteArray;

    for (const tag in elements) {
      if (elements.hasOwnProperty(tag)) {
        const element = elements[tag];
        const vr = element.vr;

        // 要素に文字列データの有効な VR があるかどうかを確認する
        if (vr === 'LO' || vr === 'PN' || vr === 'SH' || vr === 'ST' || vr === 'UT') {
          dicomData[tag] = dicomParser.readFixedString(byteArray, element.dataOffset, element.length);
        } else {
          dicomData[tag] = dicomParser.readFixedString(byteArray, element.dataOffset, element.length);
        }
      }
    }

    return dicomData;
  }
}
