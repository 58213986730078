import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import {Auth0Service, Auth0StoreService} from "@app/core/services";
import {SnackBarComponent} from "@app/shared/components";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import { DownloadType, DownloadEncoding, DownloadTypeOptions, GroupingOptions, FileGrouping } from '@app/core/models/report.model';
import moment from "moment";

interface ReportSettings {
  showHeader?: boolean;
  downloadType?: DownloadType | '';
  downloadEncoding?: DownloadEncoding;
  fileGrouping?: FileGrouping;
}

@Component({
  selector: 'app-report-setting',
  templateUrl: './report-setting.component.html',
  styleUrls: ['./report-setting.component.scss']
})
export class ReportSettingComponent implements OnInit {
  lastSavedValue!: ReportSettings
  loaded = false;

  downloadTypeOptions = DownloadTypeOptions;
  groupingOptions = GroupingOptions;

  form = new FormGroup({
    showHeader: new FormControl<boolean>(true, {nonNullable: true}),
    downloadType: new FormControl<DownloadType>('pdf', {nonNullable: true}),
    downloadEncoding: new FormControl<DownloadEncoding>('shift-jis', {nonNullable: true}),
    fileGrouping: new FormControl<FileGrouping>('byStudyDate', {nonNullable: true}),
  });

  constructor(
    private auth0Service: Auth0Service,
    private matSnackBar: MatSnackBar,
    private auth0StoreService: Auth0StoreService,
  ) { }

  async ngOnInit() {
    this.loaded = false;
    const currentSettings = await this.auth0Service.getUserSettings();
    this.form.patchValue(currentSettings);
    this.lastSavedValue = this.form.getRawValue();
    this.loaded = true;
  }

  async submit() {
    await this.auth0Service.saveUserSettings(this.form.getRawValue());

    this.matSnackBar.openFromComponent(SnackBarComponent, {
      data: {
        success: true,
        message: 'レポートの出力設定を保存しました',
      },
      duration: 3 * 1000,
    });

    localStorage.removeItem('fundusApiCache');
    this.lastSavedValue = this.form.getRawValue();
    await this.auth0StoreService.fetchCurrentUserMeta();
  }

  getSampleFileName(type : string): string {
    let filename : string;
    const date = moment().format("YYYYMMDD");
    if(type === 'pdf') filename = "診断結果詳細";
    else filename = "診断結果一覧";

    if(this.form.value.fileGrouping === 'byStudyDate') filename += "_受診日_" + date;
    else if(this.form.value.fileGrouping === 'byDiagnosisDate') filename += "_診断日_" + date;

    if(type === 'csvlist') filename += ".csv";
    else filename += ".pdf";

    return filename;
  }

  downloadTypeHas(type: string) {
    return this.downloadType()?.split(',').includes(type);
  }

  downloadType() {
    return this.form.value.downloadType;
  }

  hasChanges(): boolean {
    return JSON.stringify(this.lastSavedValue) !== JSON.stringify(this.form.getRawValue());
  }
}
