import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { MaterialModule } from '@app/material/material.module';
import {
  ButtonComponent,
  ChipListComponent,
  ChipsAutocompleteComponent,
  ConfirmDialogComponent,
  ErrorDialogComponent,
  ImportButtonComponent,
  InputComponent,
  InputAndSelectComponent,
  LabelComponent,
  MenuComponent,
  OutlinedButtonComponent,
  OutlinedInputComponent,
  ProgressSpinnerComponent,
  SelectComponent,
  SelectNumberComponent,
  SelectRomanComponent,
  SnackBarComponent,
  TextareaComponent,
} from '@app/shared/components';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CenterTooltipComponent } from './components/center-tooltip/center-tooltip.component';
import { CustomTooltipComponent } from './components/custom-tooltip/custom-tooltip.component';
import { SelectExpandDialogComponent } from './components/select-expand-dialog/select-expand-dialog.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { CollapsibleContentComponent } from './components/collapsible-content/collapsible-content.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { TextAutoTruncateComponent } from './components/text-auto-truncate/text-auto-truncate.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';

@NgModule({
  declarations: [
    ButtonComponent,
    ChipListComponent,
    ChipsAutocompleteComponent,
    ConfirmDialogComponent,
    ErrorDialogComponent,
    ImportButtonComponent,
    InputComponent,
    InputAndSelectComponent,
    LabelComponent,
    MenuComponent,
    OutlinedButtonComponent,
    OutlinedInputComponent,
    ProgressSpinnerComponent,
    SelectComponent,
    SelectNumberComponent,
    SelectRomanComponent,
    SnackBarComponent,
    TextareaComponent,
    CheckboxComponent,
    CenterTooltipComponent,
    CustomTooltipComponent,
    CustomTooltipComponent,
    SelectExpandDialogComponent,
    SelectExpandDialogComponent,
    DropdownComponent,
    DropdownComponent,
    CollapsibleContentComponent,
    DateRangePickerComponent,
    TextAutoTruncateComponent,
    MultiSelectComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    MaterialModule,
  ],
  exports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    MaterialModule,
    ButtonComponent,
    ChipListComponent,
    ChipsAutocompleteComponent,
    ConfirmDialogComponent,
    ErrorDialogComponent,
    ImportButtonComponent,
    InputComponent,
    InputAndSelectComponent,
    LabelComponent,
    MenuComponent,
    OutlinedButtonComponent,
    OutlinedInputComponent,
    ProgressSpinnerComponent,
    SelectComponent,
    SelectNumberComponent,
    SelectRomanComponent,
    SnackBarComponent,
    TextareaComponent,
    CheckboxComponent,
    CenterTooltipComponent,
    CustomTooltipComponent,
    SelectExpandDialogComponent,
    DropdownComponent,
    CollapsibleContentComponent,
    DateRangePickerComponent,
    TextAutoTruncateComponent,
    MultiSelectComponent,
  ],
})
export class SharedModule {}
