import {Component, OnInit} from '@angular/core';
import {Auth0Service, Auth0StoreService} from "@app/core/services";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {SnackBarComponent} from "@app/shared/components";

@Component({
  selector: 'app-user-info-setting',
  templateUrl: './user-info-setting.component.html',
  styleUrls: ['./user-info-setting.component.scss']
})
export class UserInfoSettingComponent implements OnInit {
  loaded : boolean = false;
  userName : string = '';
  userEmail : string = '';
  googleName : string = '';
  lastSavedValue : string = '';

  constructor(
    private auth0Service: Auth0Service,
    private matSnackBar: MatSnackBar,
    private auth0StoreService: Auth0StoreService,
  ) { }

  get canResetGoogleName() : boolean {
    return this.googleName !== '' && this.googleName !== this.userName;
  }

  async ngOnInit() {
    this.loaded = false;
    let ownUser = await this.auth0Service.getOwnUserInfo();
    if(ownUser.sub) {
      let user = await this.auth0Service.getUser(ownUser.sub);
      let userSettings = user.user_metadata;

      if(user.name) {
        this.userName = user.name;
        this.lastSavedValue = user.name;
      }

      if(userSettings.googleName) {
        this.googleName = userSettings.googleName;
      } else {
        this.googleName = this.userName;
      }


      if(ownUser.email) {
        this.userEmail = ownUser.email;
      }
    }

    this.loaded = true;
  }

  async submit() {
    if(this.lastSavedValue === this.userName || !this.userName) return;

    await this.auth0Service.updateUser({
      name: this.userName,
      user_metadata: {
        googleName: this.googleName,
      }
    });

    this.matSnackBar.openFromComponent(SnackBarComponent, {
      data: {
        success: true,
        message: 'プロフィール設定を保存しました',
      },
      duration: 3 * 1000,
    });

    localStorage.removeItem('fundusApiCache');
    this.lastSavedValue = this.userName;

    await this.auth0StoreService.fetchCurrentUser();
    await this.auth0StoreService.fetchCurrentUserMeta();
  }

  resetToGoogleName() {
    this.userName = this.googleName;
  }
}
