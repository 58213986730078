<ng-container *ngIf="!loaded; else autocomplete">
  <ngx-skeleton-loader
    [theme]="{
      width: '264px',
      height: '20px',
      'border-radius': '10px'
    }"
  ></ngx-skeleton-loader>
</ng-container>
<ng-template #autocomplete>
  <mat-form-field
    class="form-field"
    [class.textonly]="textonly"
    appearance="legacy"
    floatLabel="never"
    (click)="openSelectDialog()"
    [class.has-errors]="selectLimitReached"
    #input>
    <mat-label>{{ placeholder }}</mat-label>
    <mat-chip-list [selectable]="!readonly && !textonly" [disabled]="disabled" #chipList [style.cursor]="disabled || readonly || textonly ? 'default' : 'pointer'">
      <div *ngIf="!textonly && !disabled" [style.width]="'100%'">
        <div fxLayout="row" [style.width]="'100%'">
          <div [style.flex-grow]="1">
            <mat-chip [disableRipple]="readonly" *ngFor="let value of values" (removed)="remove(value)">
              {{ value }}
              <button class="remove-chip-button" matChipRemove *ngIf="!disabled && !readonly">
                <mat-icon class="material-icons-outlined remove-chip">cancel</mat-icon>
              </button>
            </mat-chip>
          </div>
          <div class="clear-button" *ngIf="values.length > 0" (click)="removeAll($event)">
            <mat-icon>close</mat-icon>
          </div>
        </div>
      </div>
      <div class="text-only-version" *ngIf="textonly">
        <mat-chip [disableRipple]="true" [disabled]="true" *ngFor="let value of values; let i = index;" (removed)="remove(value)">
          {{ value }} {{ i < values.length-1 ? "," : "" }}
        </mat-chip>
      </div>
    </mat-chip-list>
  </mat-form-field>
  <span class="error" *ngIf="selectLimitReached">
      11個以上選択しています
    </span>
</ng-template>

<ng-template #expandedSelect>
  <div class="checkboxes-dialog" [class.has-errors]="selectLimitReached">
    <div class="checkboxes-wrapper" >
      <span class="error-message" *ngIf="selectLimitReached">11個以上選択しています</span>
      <div fxLayout="column" fxLayoutGap="12px">
        <div class="checkboxes-init" (click)="clickedDialog = true;">
            <mat-checkbox [checked]="values.includes(initialValue)" (change)="checkboxChanged($event, initialValue)">
              {{initialValue}}
            </mat-checkbox>
        </div>
        <mat-divider></mat-divider>
        <div class="checkboxes" (click)="clickedDialog = true;">
          <div *ngFor="let item of nonInitialValues">
            <mat-checkbox [checked]="values.includes(item)" (change)="checkboxChanged($event, item)">
              {{item}}
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
