import {Component, ElementRef, HostListener, Input, AfterViewInit, ViewChild, OnDestroy} from '@angular/core';
import { MatLegacyTooltip as MatTooltip } from '@angular/material/legacy-tooltip';
import {MouseEvent} from "react";

@Component({
  selector: 'app-center-tooltip',
  templateUrl: './center-tooltip.component.html',
  styleUrls: ['./center-tooltip.component.scss']
})
export class CenterTooltipComponent implements AfterViewInit, OnDestroy {
  @Input() tooltipMessage = '';
  display = false;
  containerBounds! : DOMRect;
  visibilityObserver! : IntersectionObserver;
  visible = false;

  @ViewChild('container') container! : ElementRef;
  @ViewChild('tooltip') tooltip! : MatTooltip;
  ngAfterViewInit() {
     this.visibilityObserver = new IntersectionObserver((entries) => {
       entries.forEach((entry) => {
         if(entry.isIntersecting) {
           this.visible = true;
           this.containerBounds = entry.boundingClientRect;
           return;
         } else {
           this.visible = false;
         }
       });
     });

     this.visibilityObserver.observe(this.container.nativeElement);
  }
  @HostListener('window:resize', ['$event']) onWindowResize(_ : Event) {
    this.containerBounds = this.container.nativeElement.getBoundingClientRect();
  }

  @HostListener('window:mousemove', ['$event']) onWindowMouseMove(event : MouseEvent) {
    if(this.visible && (
      (event.clientX < this.containerBounds.left || event.clientX > this.containerBounds.right)
      || (event.clientY < this.containerBounds.top || event.clientY > this.containerBounds.bottom)
    )) {
      this.tooltip.hide();
    }
  }
  ngOnDestroy() {
    this.visibilityObserver.disconnect();
  }
}
