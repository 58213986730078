import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-select-number',
  templateUrl: './select-number.component.html',
  styleUrls: ['./select-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectNumberComponent),
      multi: true,
    },
  ],
})
export class SelectNumberComponent implements ControlValueAccessor {
  @Input() selectedNum?: string;
  @Input() disabled?: boolean;
  @Input() loaded: boolean = true;
  @Input() readonly?: boolean;
  @Input() allowEmpty: boolean = true;

  onChange!: Function;
  OnTouched!: Function;

  readonly elements = [
    {
      num: '0',
      class: 'zero',
    },
    {
      num: '1',
      class: 'one',
    },
    {
      num: '2',
      class: 'two',
    },
    {
      num: '3',
      class: 'three',
    },
    {
      num: '4',
      class: 'four',
    },
  ];

  writeValue(obj: any) {
    if(!this.allowEmpty) {
      this.selectedNum = obj || '0';
    } else {
      this.selectedNum = obj;
    }


  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.OnTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  setNum(index: string) {
    this.selectedNum = index;
    this.onChange(index);
  }
}
