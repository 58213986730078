import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { AuthService, User } from '@auth0/auth0-angular';
import { mergeMap, Observable } from 'rxjs';

import { environment } from '@environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  user!: User | null;

  constructor(private auth: AuthService) {
    this.auth.user$.subscribe((user) => {
      this.user = user || null;
    });
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Auth0のセッションが確立している組織(currentOrgId)とユーザがログインしたい組織(selectedOrgId)がずれている場合
    // キャッシュしているトークンを破棄してユーザがログインしたい組織に切り替える
    // ログイン直後はcurrentOrgIdが空（どこの組織も指定せずにログインした状態）になっている
    const currentOrgId = this.user?.['org_id'];
    const selectedOrgId = this.user?.['fundus/selectedOrganization'];

    return this.auth
      .getAccessTokenSilently({
        audience: environment.audience,
        organization: selectedOrgId,
        ignoreCache: currentOrgId !== selectedOrgId,
      })
      .pipe(
        mergeMap((token) => {
          if (
            !request.url.includes(environment.apiUrl) &&
            !request.url.includes(environment.auth0Url)
          ) {
            return next.handle(request);
          }
          const req = request.clone({
            headers: request.headers.set('Authorization', `Bearer ${token}`),
          });
          return next.handle(req);
        })
      );
  }
}
