import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Patient } from '@app/diagnosis/models';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PatientsApiService {
  apiPath = `${environment.apiUrl}/patients`;

  constructor(private http: HttpClient) {}

  updatePatient(id: string, patient: Patient) {
    return this.http.put<any>(`${this.apiPath}/${id}`, patient);
  }
}
