import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-chips-autocomplete',
  templateUrl: './chips-autocomplete.component.html',
  styleUrls: ['./chips-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipsAutocompleteComponent),
      multi: true,
    },
  ],
})
export class ChipsAutocompleteComponent
  implements ControlValueAccessor, OnInit
{
  @ViewChild('input') input!: ElementRef<HTMLInputElement>;
  @Input() values: string[] = [];
  @Input() allValues: string[] = [];
  @Input() placeholder?: string;
  @Input() loaded?: boolean;
  @Output() changed = new EventEmitter<void>();

  onChange!: Function;
  OnTouched!: Function;
  disabled = false;

  readonly separatorKeysCodes = [ENTER, COMMA];
  formCtrl = new UntypedFormControl();
  filtered$!: Observable<string[]>;

  ngOnInit() {
    this.filtered$ = this.formCtrl.valueChanges.pipe(
      startWith(null),
      map((value) => (value ? this._filter(value) : this.allValues.slice()))
    );
  }

  writeValue(obj: any) {
    this.values = obj ? obj : [];
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.OnTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  add(event: MatChipInputEvent) {
    const value = (event.value || '').trim();

    if (value) {
      this.values.push(value);
      this.onChange(this.values);
      this.changed.emit();
    }

    event.chipInput!.clear();

    this.formCtrl.setValue(null);
  }

  remove(value: string) {
    const index = this.values.indexOf(value);

    if (index >= 0) {
      this.values.splice(index, 1);
      this.onChange(this.values);
      this.changed.emit();
    }
  }

  selected(event: MatAutocompleteSelectedEvent) {
    this.values.push(event.option.viewValue);
    this.onChange(this.values);
    this.changed.emit();
    this.input.nativeElement.value = '';
    this.formCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allValues.filter((v) => v.toLowerCase().includes(filterValue));
  }
}
