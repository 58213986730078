import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DateService } from '@app/core/services';
import {FundusStudiesParams, FundusStudiesSearchFilters} from '@app/diagnosis/models';
import { Auth0StoreService } from '@app/core/services';
import { Subject, takeUntil } from 'rxjs';

/**
 * 眼底検査のクエリパラメータを扱うクラス
 */
@Injectable({
  providedIn: 'root',
})
export class FundusStudiesParamsService {
  constructor(
    private route: ActivatedRoute,
    private dateService: DateService,
    private auth0StoreService: Auth0StoreService,
  ) {
    this.auth0StoreService.currentUserDataSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (userData) => {
        this.defaultPageSize = userData?.user_metadata?.defaultPageSize ?? this.defaultPageSize;
      });
  }

  private defaultPageSize = 300;
  private readonly destroy$ = new Subject<void>();

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * ページ番号を取得する
   * @returns ページ番号
   */
  getPageIndex() {
    const offset = this.route.snapshot.queryParamMap.get('offset') ?? 0;
    const limit = this.route.snapshot.queryParamMap.get('limit') ?? this.defaultPageSize;
    return Number(offset) / Number(limit);
  }

  /**
   * ページサイズを取得する
   * @returns ページサイズ
   */
  getPageSize() {
    const limit = this.route.snapshot.queryParamMap.get('limit') ?? this.defaultPageSize;
    return Number(limit);
  }

  /**
   * 診断のステータスを取得する
   * @returns 診断のステータス
   */
  getCondition() {
    const condition = this.route.snapshot.queryParamMap.get('condition') as
      | null
      | 'diagnosed'
      | 'undiagnosed';
    return condition ? condition : '';
  }

  /**
   * パラメータを取得する
   * @returns パラメータ
   */
  getFundusStudiesParams() {
    const paramMap = this.route.snapshot.queryParamMap;

    const type = paramMap.get('type') || '';
    const condition = paramMap.get('condition') || '';
    const _startTime = paramMap.get('startTime');
    const _endTime = paramMap.get('endTime');
    const limit = paramMap.get('limit') || this.getPageSize();
    const offset = paramMap.get('offset') || 0;
    const startTime = _startTime
      ? this.dateService.getStartTime(_startTime)
      : '';
    const endTime = _endTime ? this.dateService.getEndTime(_endTime) : '';

    return {
      type,
      condition,
      startTime,
      endTime,
      limit,
      offset,
    } as FundusStudiesParams;
  }

  getFundusStudiesSearchParams() {
    const params = this.route.snapshot.queryParamMap;
    let fundusStudiesSearchFilters: FundusStudiesSearchFilters = {
      limit: this.getPageSize(),
      offset: 0,
    };
    let fundusStudiesSearchParamsKeys = [
      'studyName',
      'studyDateFrom',
      'studyDateTo',
      'importDateFrom',
      'importDateTo',
      'sharedDateFrom',
      'sharedDateTo',
      'receptionDateFrom',
      'receptionDateTo',
      'diagnosisDateFrom',
      'diagnosisDateTo',
      'sharedFacilityId',
      'sharedFacilityType',
      'assignedUserId',
      'assignedUserType',
      'sex',
      'history',
      'importFacilityId',
      'templateId',
      'receptionStatus',
      'limit',
      'offset',
      'condition',
      'tags',
      'downloadStatus',
      'sharingStatus',
      'assignmentStatus',
      'tagStatus',
    ];

    for(let key of fundusStudiesSearchParamsKeys) {
      if(params.get(key)) {
        if(['importDateFrom', 'diagnosisDateFrom','receptionDateFrom', 'sharedDateFrom'].includes(key)) {
          fundusStudiesSearchFilters[key] = this.dateService.getStartTime(params.get(key) as string);
        } else if(key === 'studyDateFrom') {
          fundusStudiesSearchFilters[key] = this.dateService.getStartTime(params.get(key) as string, false);
        } else if(['importDateTo', 'diagnosisDateTo','receptionDateTo', 'sharedDateTo'].includes(key))  {
          fundusStudiesSearchFilters[key] = this.dateService.getEndTime(params.get(key) as string);
        } else if(key === 'studyDateTo') {
          fundusStudiesSearchFilters[key] = this.dateService.getEndTime(params.get(key) as string, false);
        } else {
          fundusStudiesSearchFilters[key] = params.get(key) as string | number;
        }

      }
    }

    return fundusStudiesSearchFilters;
  }
}
