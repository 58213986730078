import {
  Component,
  Input,
  forwardRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface OptionData {
  value: string;
  label: string;
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements ControlValueAccessor {
  @Input() set options( options: Array<string | OptionData>) {
    let optionsObj = [];

    for(let option of options) {
      if(typeof option === 'string') {
        optionsObj.push({
          value: option,
          label: option,
        });
      } else {
        optionsObj.push(option);
      }
    }
    this._options = optionsObj;
  }
  @Input() placeholder = '';
  @Input() width! : string;
  @Input() height? = '25px';
  @Input() loaded: boolean = true;
  @Input() errorMessage = '';
  @Output() changed = new EventEmitter<string>();
  @Input() readonly?: boolean;
  @Input() textonly?: boolean;
  @Input() allowEmpty = true;

  value = '';
  disabled = false;
  onChange!: Function;
  OnTouched!: Function;
  _options: OptionData[] = []

  get currentValueLabel() {
    const currentOption = this._options.find(option => option.value === this.value);
    if(currentOption) {
      return currentOption.value
    } else {
      return '';
    }
  }

  setValue(value: string) {
    this.value = value;
    this.onChange(value);
    this.changed.emit(value);
  }

  writeValue(obj: any) {
    if(!this.allowEmpty) {
      this.value = obj || this._options[0].value;
    } else {
      this.value = obj;
    }

  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.OnTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
