<div [style.padding-left.px]="2">
  <mat-form-field
    class="form-field multi-select"
    appearance="legacy"
    floatLabel="never"
    #input>
    <mat-label *ngIf="this.values.length === 0 && !disabled">選択しない</mat-label>
    <mat-select #selectPanel multiple [formControl]="selectControl" disableOptionCentering panelClass="select-panel" [disabled]="disabled">
      <mat-select-trigger>
        <mat-chip-list #chipList>
          <div fxLayout="row" [style.width]="'100%'">
            <div [style.flex-grow]="1">
              <mat-chip *ngFor="let value of values" (removed)="remove(value)" [disabled]="disabled">
            <span class="mat-chip-text">
              {{getLabelFromValue(value)}}
            </span>
                <button class="remove-chip-button" matChipRemove *ngIf="!disabled">
                  <mat-icon class="material-icons-outlined remove-chip">cancel</mat-icon>
                </button>
              </mat-chip>
            </div>
            <div class="clear-button" *ngIf="values.length > 0 && !disabled" (click)="removeAll($event)">
              <mat-icon>close</mat-icon>
            </div>
          </div>
        </mat-chip-list>
      </mat-select-trigger>
      <mat-option *ngFor="let option of _options" [value]="option.value" class="multi-select"
                  [ngClass]="values.includes(option.value) ? 'selected' : ''"  [disabled]="disabled">
        <span class="left-icon" fxFlexAlign="center">
          <mat-icon class="check" fontSet="material-icons-outlined" *ngIf="values.includes(option.value)">
            check
          </mat-icon>
        </span>
        <span>
            {{ option.label }}
          </span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
