<ng-container *ngIf="!loaded; else label">
  <div fxLayout="row" fxLayoutAlign=" center" [style.height]="height">
    <ngx-skeleton-loader
      [theme]="{
        width: '120px',
        height: '20px',
        'border-radius': '10px'
      }"
    ></ngx-skeleton-loader>
  </div>
</ng-container>
<ng-template #label>
  <label [style.line-height]="height">
    <ng-content></ng-content>
  </label>
</ng-template>
