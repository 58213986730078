
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  ElementRef,
  ViewChild, TemplateRef, HostListener, OnDestroy,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl, UntypedFormGroup,
} from '@angular/forms';

import {LegacyDialogPosition as DialogPosition, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {MatLegacyCheckboxChange as MatCheckboxChange} from "@angular/material/legacy-checkbox";

@Component({
  selector: 'app-select-expand-dialog',
  templateUrl: './select-expand-dialog.component.html',
  styleUrls: ['./select-expand-dialog.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectExpandDialogComponent),
      multi: true,
    },
  ],
})

export class SelectExpandDialogComponent implements ControlValueAccessor, OnInit, OnDestroy
{
  @ViewChild('expandedSelect', { static: false } as any) expandedSelect!: TemplateRef<any>;
  @Input() values: string[] = [];
  @Input() allValues: string[] = [];
  @Input() placeholder?: string;
  @Input() loaded: boolean = true;
  @Output() changed = new EventEmitter<void>();
  @Input() readonly: boolean = false;
  @Input() textonly: boolean = false;
  onChange!: Function;
  OnTouched!: Function;
  disabled = false;
  checkboxesListForm!: UntypedFormGroup;
  isDialogOpen: boolean = false;
  dialogRef!: MatDialogRef<SelectExpandDialogComponent>;
  clickedDialog : boolean = false;
  selectLimitReached = false;
  initialValue : string = '';
  nonInitialValues! : string[];

  constructor(
    private dialog: MatDialog,
    private  input: ElementRef,
  ) {}

  @HostListener('document:click', ['$event','$event.target'])
  onDocumentClick(event: MouseEvent) {
    if(!this.input.nativeElement.contains(event.target) && !this.clickedDialog) {
      if(this.isDialogOpen) {
        this.dialogRef.close();
        this.isDialogOpen = false;
      }
    } else {
      this.clickedDialog = false;
    }
  }

  ngOnInit() {
    this.checkboxesListForm = new UntypedFormGroup({});

    this.allValues.forEach((val) => {
      const isChecked = this.values.includes(val);
      this.checkboxesListForm.addControl( val, new UntypedFormControl(isChecked))
    });

    this.initialValue = this.allValues[0] ?? '';
    this.nonInitialValues = this.allValues.filter((val) => val!== this.initialValue);

  }

  writeValue(obj: any) {
    this.values = obj ? obj : [];
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.OnTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  checkboxChanged($event : MatCheckboxChange, value: string) {
    value = (value || '').trim();
    if (value && $event.checked) {
      if(value === this.initialValue) {
        this.values = [this.initialValue];
      } else {
        this.values.push(value);

        if(this.values.includes(this.initialValue)) {
          this.values = this.values.filter((val) => val !== this.initialValue);
        }
      }
    } else {
      this.values.splice(this.values.indexOf(value), 1);
    }

    this.selectLimitReached = this.values && this.values.length > 10;

    this.onChange(this.values);
    this.changed.emit();
  }
  openSelectDialog() {
    if(!this.disabled && !this.isDialogOpen && !this.readonly && !this.textonly) {
      setTimeout(() => {
        let dialogPosition = this.getDialogPosition();
        this.dialogRef = this.dialog.open(this.expandedSelect, {
            width: 'auto',
            height: 'auto',
            position: dialogPosition,
            hasBackdrop: false
          });
        this.isDialogOpen = true;
      },0);
    }
  }

  remove(value: string) {
    const index = this.values.indexOf(value);

    if (index >= 0) {
      this.values.splice(index, 1);
      this.selectLimitReached = this.values && this.values.length > 10;
      this.onChange(this.values);
      this.changed.emit();
    }
  }

  removeAll($event : MouseEvent) {
    $event.stopPropagation();
    this.values = [];
    this.onChange(this.values);
    this.changed.emit();
  }

  getDialogPosition() : DialogPosition {
    const inputRect = this.input.nativeElement.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    let position: DialogPosition;

    if (inputRect.top < viewportHeight/2 && inputRect.left < viewportWidth / 2) {
      position = {
        left: inputRect.left + 'px',
        bottom: inputRect.bottom ? (viewportHeight-inputRect.bottom - 385) + 'px' : undefined
      };
    } else if (inputRect.top >= viewportHeight/2 && inputRect.left < viewportWidth / 2) {
      position = {
        left: inputRect.left + 'px',
        top: inputRect.top ? (inputRect.top - 385) + 'px' : undefined
      };
    } else if (inputRect.top >= viewportHeight/2 && inputRect.left >= viewportWidth / 2) {
      position = {
        right: (viewportWidth-inputRect.right) + 'px',
        top: inputRect.top ? (inputRect.top - 385) + 'px' : undefined
      };
    } else {
      position = {
        right: (viewportWidth-inputRect.right) + 'px',
        bottom: inputRect.bottom ? (viewportHeight-inputRect.bottom - 385) + 'px' : undefined
      };
    }

    return position;

  }

  ngOnDestroy() {
    this.dialog.closeAll();
  }
}
