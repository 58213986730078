<div class="container">
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <div class="content" mat-dialog-content>
    {{ data.content }}
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center">
    <button mat-button (click)="dialogRef.close(false)" *ngIf="data.actions[0]">
      {{ data.actions[0] }}
    </button>
    <button
      mat-flat-button
      [color]="data.color"
      (click)="dialogRef.close(true)"
      *ngIf="data.actions[1]"
    >
      {{ data.actions[1] }}
    </button>
  </div>
</div>
