import { Component, OnInit } from '@angular/core';

import { Auth0Service } from '@app/core/services';
import {ThemeService} from "@app/core/services/theme.service";
import {RevisionService} from "@app/core/services/revision-service.service";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs";
import {SnackBarComponent} from "@app/shared/components";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  currentRevision!: string;
  checkRevisionOnRouteChange = false;
  constructor(
    private auth0Service: Auth0Service,
    private themeService: ThemeService,
    private revisionService: RevisionService,
    private router : Router,
    private matSnackBar: MatSnackBar
  ) {}

  ngOnInit() {
    const invitation = sessionStorage.getItem('invitation') || '';
    if (invitation || location.href.includes('invitation')) {
      this.auth0Service.acceptInvitation();
    }

    this.initRevision()
  }

  toggleTheme(): void {
    this.themeService.toggleTheme();
  }

  getCurrentTheme(): string {
    return this.themeService.getCurrentTheme();
  }

  initRevision() {
    const urlParams = new URLSearchParams(window.location.search);

    // @ts-ignore
    this.currentRevision = urlParams.get('revision') || window.fundus_revision;

    let savedRevision = sessionStorage.getItem('fundus_revision');
    if(savedRevision && savedRevision!== this.currentRevision) {
      this.matSnackBar.openFromComponent(SnackBarComponent, {
        data: {
          success: true,
          message: '最新バージョンにアップデートしました',
        },
        duration: 3 * 1000,
      });
    }
    sessionStorage.setItem('fundus_revision', this.currentRevision);

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      if(this.checkRevisionOnRouteChange) {
        this.checkRevision();
      }
    });

    setTimeout(() => {
      this.checkRevisionOnRouteChange = true;
    },1000);


  }

  checkRevision() {
    this.revisionService.getRevision().subscribe(revision => {
      if (this.currentRevision !== revision.revision) {
        window.location.reload();
      }
    });
  }
}
