<div class="container">
  <h2 mat-dialog-title>インポートエラー</h2>
  <div mat-dialog-content>
    <p>正しくインポートができませんでした。</p>
    <p>もう一度操作をしてください。</p>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center">
    <button mat-button (click)="dialogRef.close()">閉じる</button>
  </div>
</div>
