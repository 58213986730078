<ng-container *ngIf="!loaded; else autocomplete">
  <ngx-skeleton-loader
    [theme]="{
      width: '264px',
      height: '20px',
      'border-radius': '10px'
    }"
  ></ngx-skeleton-loader>
</ng-container>
<ng-template #autocomplete>
  <mat-form-field class="form-field" appearance="legacy" floatLabel="never">
    <mat-label>{{ placeholder }}</mat-label>
    <mat-chip-list #chipList>
      <mat-chip *ngFor="let value of values" (removed)="remove(value)">
        {{ value }}
        <button matChipRemove *ngIf="!disabled">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
      <input
        #input
        [formControl]="formCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [disabled]="disabled"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-list>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selected($event)"
    >
      <mat-option *ngFor="let value of filtered$ | async" [value]="value">
        {{ value }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-template>
