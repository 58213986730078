import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

import { environment } from '@environments/environment';

export interface Collaborators {
  from: Collaborator[];
  to: Collaborator[];
}

export interface Collaborator {
  id: string;
  displayName: string;
  name: string;
  onetimekey?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CollaboratorsApiService {
  apiPath = `${environment.apiUrl}`;

  constructor(private http: HttpClient) {}

  getCollaborators() {
    return firstValueFrom(
      this.http.get<Collaborators>(`${this.apiPath}/collaborators`)
    );
  }

  createCollaborator(request: { key: string; isPreview: boolean }) {
    return firstValueFrom(
      this.http.post<Collaborator>(`${this.apiPath}/collaborators`, request)
    );
  }

  deleteCollaborator(id: string) {
    return firstValueFrom(
      this.http.delete<void>(`${this.apiPath}/collaborators/${id}`)
    );
  }
}
