import { Injectable } from '@angular/core';
import { startOfDay, endOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import moment from "moment";

@Injectable({
  providedIn: 'root',
})
export class DateService {
  getStartTime(startTime: string, utcTimeZone = true) {
    if(utcTimeZone) {
      const date = startOfDay(new Date(startTime));
      const utc = zonedTimeToUtc(date, 'Asia/Tokyo');
      return utc.toISOString().replace('.000Z', 'Z');
    } else {
      return moment(startTime).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
    }

  }

  getEndTime(endTime: string, utcTimeZone = true) {
    if(utcTimeZone) {
      const date = endOfDay(new Date(endTime));
      const utc = zonedTimeToUtc(date, 'Asia/Tokyo');
      return utc.toISOString().replace('.000Z', 'Z');
    } else {
      return moment(endTime).endOf('day').format('YYYY-MM-DDTHH:mm:ss[.999Z]');
    }
  }
}
