<form
  class="card"
  fxLayout="column"
  fxLayoutGap="20px"
  *ngIf="form"
  [formGroup]="form"
>
  <span class="title">診断画面ショートカットキー設定</span>
  <div fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="column" >
      <div class="row heading-row" fxLayout="row" fxLayoutGap="4px">
        <div class="label heading">機能</div>
        <div class="heading">修飾キー</div>
        <div class="heading">通常キー</div>
      </div>
      <div class="row" fxLayout="row" *ngFor="let field of fields" fxLayoutGap="4px">
        <div class="label">{{field.label}}</div>
        <div fxLayout="column" fxLayoutGap="4px" class="key-input-with-error">
          <div fxLayout="row" fxLayoutGap="4px" class="key-input">
            <select
              [formControlName]="field.id + '_modifier'"
              [id]="field.id + '_modifier'"
            >
              <option value="">なし</option>
              <option value="Control">Ctrl</option>
              <option value="Shift">Shift</option>
              <option value="Alt">Alt</option>
              <option value="Meta">⌘ (macOSのみ）</option>
            </select>
            <select
              [formControlName]="field.id + '_key'"
              [id]="field.id + '_key'"
            >
              <option value="">なし</option>
              <option *ngFor="let key of keys" [value]="key.code"
                      [disabled]="keyAlreadyUsed(field.id, {modifier: shortcutKeys[field.id].modifier, key: key.code})">
                {{key.key}}
              </option>
            </select>
          </div>
          <div class="error-message" *ngIf="!!errors[field.id]">
            {{errors[field.id]}}
          </div>
        </div>

      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
      <app-button color="gray" type="button" width="94px" height="25px" [mat-dialog-close]="true"
      >キャンセル</app-button
      >
      <app-button
        type="submit"
        color="primary"
        width="52px"
        height="25px"
        [disabled]="!canSubmit"
        (click)="canSubmit && submit()"
      >保存</app-button
      >
    </div>
  </div>
</form>
