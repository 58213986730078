import {Component, ElementRef, HostListener, Input, AfterViewInit, ViewChild, OnDestroy} from '@angular/core';
import { MatLegacyTooltip as MatTooltip } from '@angular/material/legacy-tooltip';
import {MouseEvent} from "react";

@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss']
})
export class CustomTooltipComponent implements AfterViewInit, OnDestroy {
  @Input() tooltipMessage = '';
  @Input() tooltipPosition : 'above' | 'below' | 'left' | 'right' = 'below';
  @Input() tooltipOffset : [number,number] = [0,0];
  display = false;
  containerBounds! : DOMRect;
  containerHeight! : number;
  containerWidth! : number;
  visibilityObserver! : IntersectionObserver;
  visible = false;

  @ViewChild('container') container! : ElementRef;
  @ViewChild('tooltip') tooltip! : MatTooltip;
  ngAfterViewInit() {
    this.visibilityObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if(entry.isIntersecting) {
          this.visible = true;
          this.containerBounds = entry.boundingClientRect;
          this.containerHeight = this.containerBounds.height;
          this.containerWidth = this.containerBounds.width;
          return;
        } else {
          this.visible = false;
        }
      });
    });

    this.visibilityObserver.observe(this.container.nativeElement);
  }
  @HostListener('window:resize', ['$event']) onWindowResize(_ : Event) {
    this.containerBounds = this.container.nativeElement.getBoundingClientRect();
  }

  @HostListener('window:mousemove', ['$event']) onWindowMouseMove(event : MouseEvent) {
    if(this.visible && (
      (event.clientX < this.containerBounds.left || event.clientX > this.containerBounds.right)
      || (event.clientY < this.containerBounds.top || event.clientY > this.containerBounds.bottom)
    )) {
      this.tooltip?.hide();
    }
  }

  get style() {
    let xOffset, yOffset;

    if(this.tooltipPosition === 'above') {
      xOffset = this.tooltipOffset[0]
      yOffset = -Math.floor(this.containerHeight/2) - this.tooltipOffset[1];
    } else if(this.tooltipPosition === 'below') {
      xOffset = this.tooltipOffset[0]
      yOffset = Math.floor(this.containerHeight/2) + this.tooltipOffset[1];
    } else if(this.tooltipPosition === 'left') {
      xOffset = this.containerWidth - this.tooltipOffset[1];
      yOffset = this.tooltipOffset[1];
    } else {
      xOffset = -this.containerWidth - this.tooltipOffset[1];
      yOffset = this.tooltipOffset[1];
    }

    return {top: yOffset +  "px", right: xOffset + "px"};
  }

  ngOnDestroy() {
    this.visibilityObserver.disconnect();
  }
}
