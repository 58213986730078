import { Injectable } from '@angular/core';
import { firstValueFrom, timer } from 'rxjs';

import { ProgressService } from '@app/core/services';
import { FundusStudiesApiService } from '@app/diagnosis/services';
import {
  FundusStudiesParams,
  FundusStudiesResponse,
  FundusStudiesSearchFilters,
  FundusStudy,
  Template,
} from '@app/diagnosis/models';
import { Messages } from '@constants/messages';

/**
 * 眼底検査 API のビジネスロジックが実装されたクラス
 */
@Injectable({
  providedIn: 'root',
})
export class FundusStudiesService {
  constructor(
    private progressService: ProgressService,
    private fundusStudiesApiService: FundusStudiesApiService
  ) {}

  createFundusStudy(name: string, studyDate: string, tags: string[] = []) {
    return firstValueFrom(
      this.fundusStudiesApiService.createFundusStudy(name, studyDate, tags)
    );
  }

  /**
   * 眼底検査一覧を取得する
   * @param fundusStudiesParams クエリパラメータ
   * @returns 眼底検査一覧
   */
  getFundusStudyList(fundusStudiesParams: FundusStudiesParams) {
    return firstValueFrom(
      this.fundusStudiesApiService.getFundusStudyList(fundusStudiesParams)
    ).then((res) => fillDiagnosticTemplate(res));
  }

  searchFundusStudies(fundusStudiesSearchParams: FundusStudiesSearchFilters) {
    return firstValueFrom(
      this.fundusStudiesApiService.searchFundusStudies(fundusStudiesSearchParams)
    ).then((res) => fillDiagnosticTemplate(res));
  }

  /**
   * 眼底検査を取得する
   * @param id 眼底検査 ID
   * @returns 眼底検査
   */
  getFundusStudy(id: string) {
    return firstValueFrom(this.fundusStudiesApiService.getFundusStudy(id));
  }

  /**
   * 眼底検査を削除する
   * @param id 眼底検査 ID
   * @returns 空のオブジェクト
   */
  deleteFundusStudy(id: string) {
    return firstValueFrom(this.fundusStudiesApiService.deleteFundusStudy(id));
  }

  /**
   * 眼底検査数を取得する
   * @returns 眼底検査数
   */
  getFundusStudyCount() {
    return firstValueFrom(this.fundusStudiesApiService.getFundusStudyCount());
  }

  /**
   * 眼底検索結果の件数を取得する
   * @returns 眼底検査数
   * @param fundusStudiesSearchParams
   */
  searchFundusStudyCount(fundusStudiesSearchParams: FundusStudiesSearchFilters) {
    return firstValueFrom(this.fundusStudiesApiService.searchFundusStudyCount(fundusStudiesSearchParams));
  }

  /**
   * 眼底検査を複数削除する
   * @param fundusStudies 複数の眼底検査
   */
  async deleteFundusStudies(fundusStudies: FundusStudy[]) {
    this.progressService.startProgress();

    try {
      for (const fundusStudy of fundusStudies) {
        await this.deleteFundusStudy(fundusStudy.id);
      }
      await firstValueFrom(timer(3 * 1000));
    } catch (error) {
      alert(Messages.errors.delete);
    }

    this.progressService.endProgress();
  }

  /**
   * 眼底検査のテンプレートを変更する
   * @param id 眼底検査 ID
   * @param templateId 眼底検査のテンプレート ID
   * @returns 眼底検査
   */
  changeFundusStudyTemplate(id: string, templateId: string) {
    return firstValueFrom(this.fundusStudiesApiService.changeFundusStudyTemplate(id, templateId));
  }

  /**
   * 眼底検査タグ一覧を取得する
   * @returns 眼底検査タグ一覧
   */
  getFundusStudyTags() {
    return firstValueFrom(
      this.fundusStudiesApiService.getFundusStudyTags()
    );
  }
}

const fillDiagnosticTemplate = (res: FundusStudiesResponse) => {
  const studeis: FundusStudy[] = res.fundusStudies || [];
  const templates: Template[] = res.diagnosticTemplates || [];
  for (const s of studeis) {
    if (s.diagnosticTemplate) {
      continue;
    }
    if (!s.diagnosticTemplateId) {
      continue;
    }
    let found = false;
    for (const t of templates) {
      if (s.diagnosticTemplateId === t.id) {
        s.diagnosticTemplate = t;
        found = true;
        break;
      }
    }
    if (!found) {
      throw new Error(`diagnostic template not found. study: ${s.id}, template: ${s.diagnosticTemplateId}`)
    }
  }
  return res;
}
