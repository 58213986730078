<div class="container" (mouseenter)="tooltip && tooltip.show()" #container>
  <div
    class="tooltipHolder"
    matTooltipClass="tooltip"
    [matTooltip]="tooltipMessage"
    [matTooltipPosition]="tooltipPosition"
    #tooltip="matTooltip"
    [style]="style"
    *ngIf="tooltipMessage"
  >
  </div>
  <div class="content" >
    <ng-content></ng-content>
  </div>

</div>
