<button
  [type]="type"
  [disabled]="disabled"
  [ngClass]="color"
  [class.shadow]="shadow"
  [style.width]="width"
  [style.height]="height"
  (click)="!disabled && clicked.emit()"
>
  <ng-content></ng-content>
</button>
