export const environment = {
  domain: 'dev-jpdx5ars.jp.auth0.com',
  clientId: 'rAOuQz8PIc3cASLZf6wuIIF9am4xziTB',
  audience: 'https://hello-no3n5kgiwq-an.a.run.app/v1/fundus-studies',
  production: true,
  apiUrl: 'https://hello-no3n5kgiwq-an.a.run.app/v1',
  auth0Url: 'https://auth0-proxy-no3n5kgiwq-an.a.run.app',
  connection_id: 'con_bk1sVhAiyFqoGcqb',
  environment: 'development',
};
