import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DiagnosticReport } from '@app/diagnosis/models';
import { environment } from '@environments/environment';

/**
 * 診断レポート API の I/F が定義されたクラス
 */
@Injectable({
  providedIn: 'root',
})
export class DiagnosticReportsApiService {
  apiPath = `${environment.apiUrl}/diagnostic-report`;

  constructor(private http: HttpClient) {}

  /**
   * 診断レポートを取得する
   * @param id 診断レポート ID
   * @returns 診断レポート
   */
  getDiagnosticReport(id: string) {
    return this.http.get<DiagnosticReport>(`${this.apiPath}/${id}`);
  }

  /**
   * 診断レポート PDF を取得する
   * @param id 診断レポート ID
   * @returns 署名付き URL
   */
  getDiagnosticReportPDF(id: string) {
    return this.http.get<{ signedUrl: string }>(
      `${environment.apiUrl}/diagnostic-reports/${id}/print`
    );
  }

  /**
   * 診断レポート PDF を取得する
   * @param ids 診断レポートID
   * @returns PDF (base64)
   */
  getDiagnosticReportsPDF(ids: string[]) {
    return this.http.post<{ pdf: string }>(
      `${environment.apiUrl}/diagnostic-reports/print`,
      { ids: ids, format: 'pdf', type: 'detail' }
    );
  }

  /**
   * 診断レポート PDF を取得する
   * @param ids 診断レポートID
   * @returns SignedURL
   */
  getMergedDiagnosticReportPDF(ids: string[]) {
    return this.http.post<{ signedUrl: string }>(
      `${environment.apiUrl}/diagnostic-reports/merge`,
      { ids: ids }
    );
  }

  /**
   * 診断レポート一覧 PDF を取得する
   * @param ids 診断レポートID
   * @returns PDF (base64)
   */
  getDiagnosticReportListPDF(ids: string[]) {
    return this.http.post<{ pdf: string }>(
      `${environment.apiUrl}/diagnostic-reports/print`,
      { ids: ids, format: 'pdf', type: 'summary' }
    );
  }

  /**
   * 診断レポート一覧 CSV を取得する
   * @param ids 診断レポートID
   * @param encoding エンコーディング
   * @param excludeHeader ヘッダーを除外する
   * @returns CSV (base64)
   */
  getDiagnosticReportListCSV(ids: string[], encoding: string = 'shift-jis', excludeHeader: boolean = false) {
    return this.http.post<{ pdf: string }>(
      `${environment.apiUrl}/diagnostic-reports/print`,
      {
        ids: ids,
        format: 'csv',
        type: 'summary',
        encoding,
        excludeHeader
      }
    );
  }

  /**
   * 診断レポートを作成する
   * @param diagnosticReport 診断レポート
   * @returns 診断レポート
   */
  createDiagnosticReport(diagnosticReport: DiagnosticReport) {
    return this.http.post<DiagnosticReport>(this.apiPath, diagnosticReport);
  }

  /**
   * 診断レポートを更新する
   * @param diagnosticReport 診断レポート
   * @returns 診断レポート
   */
  updateDiagnosticReport(diagnosticReport: DiagnosticReport) {
    return this.http.put<DiagnosticReport>(
      `${this.apiPath}/${diagnosticReport.id}`,
      diagnosticReport
    );
  }
}
