import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment } from '@environments/environment';
import type { OrganizationSettings } from '@app/core/models/organization-settings.model';

export interface Collaborator {
  id: string;
  displayName: string;
  name: string;
  onetimekey?: string;
}

@Injectable({
  providedIn: 'root',
})
export class OrganizationSettingsApiService {
  apiPath = `${environment.apiUrl}`;

  constructor(private http: HttpClient) {}

  getSettings() {
    return firstValueFrom(
      this.http.get<OrganizationSettings>(`${this.apiPath}/organization/settings`)
    );
  }

  saveSettings(request: OrganizationSettings) {
    return firstValueFrom(
      this.http.post<void>(`${this.apiPath}/organization/settings`, request)
    );
  }
}
