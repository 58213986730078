import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

import { environment } from '@environments/environment';

export interface Onetimekey {
  key: string;
  expiresAt: Date;
  hasKey: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class OnetimekeyApiService {
  apiPath = `${environment.apiUrl}`;

  constructor(private http: HttpClient) {}

  getOnetimekey() {
    return firstValueFrom(
      this.http.get<Onetimekey>(`${this.apiPath}/onetimekey`)
    );
  }

  createOnetimekey() {
    return firstValueFrom(
      this.http.put<Onetimekey>(`${this.apiPath}/onetimekey`, null)
    );
  }
}
