import { Injectable } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { ProgressSpinnerComponent } from '@app/shared/components/progress-spinner/progress-spinner.component';

@Injectable({
  providedIn: 'root',
})
export class ProgressService {
  overlayRef: OverlayRef;
  progress = false;

  constructor(private overlay: Overlay) {
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
    });
  }

  startProgress() {
    this.progress = true;
    this.showProgress();
  }

  endProgress() {
    this.progress = false;
    this.hideProgress();
  }

  showProgress() {
    if (!this.overlayRef.hasAttached()) {
      this.overlayRef.attach(new ComponentPortal(ProgressSpinnerComponent));
    }
  }

  hideProgress() {
    if (this.overlayRef.hasAttached()) {
      !this.progress && this.overlayRef.detach();
    }
  }
}
