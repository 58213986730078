import { Injectable } from '@angular/core';
import {filter, tap} from 'rxjs';

import {
  FundusStudiesService,
  FundusStudiesStoreService,
  FundusStudyImagesService,
  FundusStudiesParamsService,
  DiagnosticReportsService,
  SelectedFundusStudiesService,
} from '@app/diagnosis/services';
import {
  DiagnosticReport,
  FundusStudy,
  FundusStudyImages, Patient, Template,
} from '@app/diagnosis/models';
import { DiagnosisSelection } from '@app/diagnosis/classes';
import {ProgressService, SharesApiService} from '@app/core/services';
import { PatientCsvUploadService } from './patient-csv-upload.service';
import {ConfirmDialogComponent, DialogData} from "@app/shared/components/confirm-dialog/confirm-dialog.component";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import moment from "moment-timezone";

/**
 * 眼底検査、眼底検査画像、診断レポート を扱う Facade クラス
 */
@Injectable({
  providedIn: 'root',
})
export class FundusStudiesFacadeService {
  private _selection = new DiagnosisSelection();

  /**
   * 眼底検査一覧の Observable を取得する
   * @returns 眼底検査一覧
   */
  get fundusStudies$() {
    return this.fundusStudiesStoreService.fundusStudies$.pipe(
      filter((response) => !!response?.fundusStudies),
      tap((response) =>
        this.selection.initCurrentPageSelection(
          response?.fundusStudies as FundusStudy[]
        )
      )
    );
  }

  /**
   * 眼底検査数の Observable を取得する
   * @returns 眼底検査数
   */
  get fundusStudiesCount$() {
    return this.fundusStudiesStoreService.fundusStudiesCount$;
  }

  /**
   * 眼底検査テンプレートの一覧 Observable を取得する
   * @returns 眼底検査テンプレートの一覧
   */
  get fundusStudyTemplates$() {
    return this.fundusStudiesStoreService.fundusStudyTemplates$;
  }

  /**
   * 選択している眼底検査を取得する
   * @returns 選択している眼底検査
   */
  get selection() {
    return this._selection;
  }

  constructor(
    private diagnosticReportsService: DiagnosticReportsService,
    private fundusStudiesStoreService: FundusStudiesStoreService,
    private fundusStudiesService: FundusStudiesService,
    private fundusStudyImagesService: FundusStudyImagesService,
    private fundusStudiesParamsService: FundusStudiesParamsService,
    private selectedFundusStudiesService: SelectedFundusStudiesService,
    private progressService: ProgressService,
    private shareApiService: SharesApiService,
    private patientCsvUploadService: PatientCsvUploadService,
    private dialog: MatDialog,
  ) {}

  /**
   * 診断レポートを取得する
   * @param id 診断レポート ID
   * @returns 診断レポート
   */
  getDiagnosticReport(id: string) {
    return this.diagnosticReportsService.getDiagnosticReport(id);
  }

  /**
   * 診断レポートを作成する
   * @param diagnosticReport 診断レポート
   * @returns 診断レポート
   */
  createDiagnosticReport(diagnosticReport: DiagnosticReport) {
    return this.diagnosticReportsService.createDiagnosticReport(
      diagnosticReport
    );
  }

  /**
   * 診断レポートを更新する
   * @param diagnosticReport 診断レポート
   * @returns 診断レポート
   */
  updateDiagnosticReport(diagnosticReport: DiagnosticReport) {
    return this.diagnosticReportsService.updateDiagnosticReport(
      diagnosticReport
    );
  }

  /**
   * 検査をグループ化する (受診日単位)
   * @param fundusStudies
   */
  groupFundusStudiesByStudyDate(fundusStudies: FundusStudy[]) {
    let groupedFundusStudies = new Map<string, FundusStudy[]>();

    for (let study of fundusStudies) {
      if(!study.studyDate || !study.diagnosticReport?.createdAt) continue;

      let studyDate = moment(study.studyDate).tz("GMT").format('YYYYMMDD');

      if (!groupedFundusStudies.has(studyDate)) {
        groupedFundusStudies.set(studyDate, []);
      }
      groupedFundusStudies.get(studyDate)?.push(study);
    }

    groupedFundusStudies = new Map([...groupedFundusStudies.entries()].sort());

    return groupedFundusStudies;
  }

  /**
   * 検査をグループ化する (診断日単位)
   * @param fundusStudies
   */
  groupFundusStudiesByDiagnosisDate(fundusStudies: FundusStudy[]) {
    let groupedFundusStudies = new Map<string, FundusStudy[]>();

    for (let study of fundusStudies) {
      if(!study.studyDate || !study.diagnosticReport?.createdAt) continue;

      let diagnosisDate = moment(study.diagnosticReport?.createdAt).tz("Asia/Tokyo").format('YYYYMMDD');
      if (!groupedFundusStudies.has(diagnosisDate)) {
        groupedFundusStudies.set(diagnosisDate, []);
      }
      groupedFundusStudies.get(diagnosisDate)?.push(study);
    }

    groupedFundusStudies = new Map([...groupedFundusStudies.entries()].sort());

    return groupedFundusStudies;
  }

  /**
   * 診断レポート PDF をダウンロードする
   */
  async downloadDiagnosticReportsPDF(grouping = "") {
    const fundusStudies = this.selection.getSelectedSortedByStudyName();

    if(grouping === "byStudyDate") {
      let groupedFundusStudies = this.groupFundusStudiesByStudyDate(fundusStudies);
      for(let [studyDate, studies] of groupedFundusStudies) {
        const filename = `診断結果詳細_受診日_${studyDate}`
        await this.diagnosticReportsService.downloadMergedDiagnosticReportPDF(
          studies,
          filename
        );
      }

    } else if(grouping === "byDiagnosisDate") {
      let groupedFundusStudies = this.groupFundusStudiesByDiagnosisDate(fundusStudies);
      for(let [diagnosisDate, studies] of groupedFundusStudies) {
        const filename = `診断結果詳細_診断日_${diagnosisDate}`
        await this.diagnosticReportsService.downloadMergedDiagnosticReportPDF(
          studies,
          filename
        );
      }

    } else {
      await this.diagnosticReportsService.downloadMergedDiagnosticReportPDF(
        fundusStudies,
        `診断結果詳細`
      );
    }
  }

  /**
   * 診断レポート一覧 PDF をダウンロードする
   */
  async downloadDiagnosticReportListPDF(grouping = "") {
    const fundusStudies = this.selection.getSelectedSortedByStudyName();

    if(grouping === "byStudyDate") {
      let groupedFundusStudies = this.groupFundusStudiesByStudyDate(fundusStudies);
      for(let [studyDate, studies] of groupedFundusStudies) {
        const filename = `診断結果一覧_受診日_${studyDate}`
        await this.diagnosticReportsService.downloadDiagnosticReportListPDF(
          studies,
          filename
        );
      }

    } else if(grouping === "byDiagnosisDate") {
      let groupedFundusStudies = this.groupFundusStudiesByDiagnosisDate(fundusStudies);
      for(let [diagnosisDate, studies] of groupedFundusStudies) {
        const filename = `診断結果一覧_診断日_${diagnosisDate}`
        await this.diagnosticReportsService.downloadDiagnosticReportListPDF(
            studies,
            filename
        );
      }

    } else {
      await this.diagnosticReportsService.downloadDiagnosticReportListPDF(
        fundusStudies,
        `診断結果一覧`
      );
    }
  }

  /**
   * 診断レポート一覧 CSV をダウンロードする
   */
  async downloadDiagnosticReportListCSV(encoding = 'shift-jis', excludeHeader: boolean = false, grouping = "") {
    const fundusStudies = this.selection.getSelectedSortedByStudyName();

    if(grouping === "byStudyDate") {
      let groupedFundusStudies = this.groupFundusStudiesByStudyDate(fundusStudies);
      for(let [studyDate, studies] of groupedFundusStudies) {
        const filename = `診断結果一覧_受診日_${studyDate}`
        await this.diagnosticReportsService.downloadDiagnosticReportListCSV(
          studies,
          encoding,
          excludeHeader,
          filename
        );
      }

    } else if(grouping === "byDiagnosisDate") {
      let groupedFundusStudies = this.groupFundusStudiesByDiagnosisDate(fundusStudies);
      for(let [diagnosisDate, studies] of groupedFundusStudies) {
        const filename = `診断結果一覧_診断日_${diagnosisDate}`
        await this.diagnosticReportsService.downloadDiagnosticReportListCSV(
          studies,
          encoding,
          excludeHeader,
          filename
        );
      }

    } else {
      await this.diagnosticReportsService.downloadDiagnosticReportListCSV(
        fundusStudies,
        encoding,
        excludeHeader,
        `診断結果一覧`
      );
    }
  }

  /**
   * 眼底検査一覧と眼底検査数をフェッチする
   */
  async fetchFundusStudies() {
    this.progressService.startProgress();
    await Promise.all([
      this.fundusStudiesStoreService.fetchFundusStudyList(),
      this.fundusStudiesStoreService.fetchFundusStudyCount(),
      this.fundusStudiesStoreService.fetchFundusStudyTemplates(),
    ]).finally(() => this.progressService.endProgress());
  }

  /**
   * 眼底検査一覧を取得する
   */
  async getFundusStudyList() {
    const params = this.fundusStudiesParamsService.getFundusStudiesParams();
    return await this.fundusStudiesService.getFundusStudyList(params);
  }

  /**
   * 眼底検査、眼底検査画像、診断レポートを取得する
   * @param id 眼底検査 ID
   * @returns 眼底検査、眼底検査画像、診断レポート
   */
  async getFundusStudyWithDiagnosticReport(id: string) {
    let fundusStudy: FundusStudy | null;
    let fundusStudyImages: FundusStudyImages | null = null;
    let diagnosticReport: DiagnosticReport | null = null;
    let diagnosticTemplate: Template | null;

    fundusStudy = await this.fundusStudiesService.getFundusStudy(id);
    diagnosticTemplate = fundusStudy.diagnosticTemplate;

    if (fundusStudy?.fundusImages) {
      fundusStudyImages =
        await this.fundusStudyImagesService.downloadFundusStudyImages(
          fundusStudy?.fundusImages
        );
    }
    if (fundusStudy?.diagnosticReport?.id) {
      diagnosticReport =
        await this.diagnosticReportsService.getDiagnosticReport(
          fundusStudy.diagnosticReport?.id
        );
    }
    this.selectedFundusStudiesService.setFundusStudy(fundusStudy);

    return { fundusStudy, fundusStudyImages, diagnosticReport, diagnosticTemplate };
  }

  /**
   * 眼底検査を取得する
   * @param id 眼底検査 ID
   * @returns 眼底検査
   */
  getFundusStudy(id: string) {
    return this.fundusStudiesService.getFundusStudy(id);
  }

  /**
   * 眼底検査を複数削除する
   */
  async deleteFundusStudies(fundusStudies: FundusStudy[]) {
    await this.fundusStudiesService.deleteFundusStudies(fundusStudies);
    this.selection.clear();
  }

  /**
   * 選択した眼底検査をインメモリーに保存する
   */
  setFundusStudies() {
    const fundusStudies = this.selection.getSelected();
    this.selectedFundusStudiesService.setFundusStudies(fundusStudies);
  }

  /**
   * 左右複数の眼底検査画像をダウンロードする
   * @param fundusStudyImages 左右複数の眼底検査画像
   * @returns 左右複数の眼底検査画像の SafeResourceUrl
   */
  downloadFundusStudyImages(fundusStudyImages: FundusStudyImages) {
    return this.fundusStudyImagesService.downloadFundusStudyImages(
      fundusStudyImages
    );
  }

  /**
   * 眼底検査画像・画像と患者情報 CSVアップロードする
   * @param fileList ファイルリスト
   * @param suppressAlerts アラートを抑制する
   * @param tags タグ
   * @param useDicomForPatient 患者情報をDICOMから取得する
   */
  async uploadFundusStudies(fileList: FileList | File[], suppressAlerts = false, tags: string[] = [], useDicomForPatient = false) {
    let imageFiles: File[] = [];
    let csvFiles: File[] = [];

    if(fileList instanceof FileList) {
      for (let i = 0; i < fileList.length; i++) {
        let file = fileList.item(i);
        if (file?.type === 'image/jpeg') {
          imageFiles.push(file);
        } else if (file?.name.endsWith('.csv')) {
          csvFiles.push(file);
        }
      }
    } else {
      fileList.forEach((file) => {
        if (file?.name.endsWith('.csv')) {
          csvFiles.push(file);
        } else {
          imageFiles.push(file);
        }
      });
    }

    if(imageFiles.length > 0) {
      let imageUploadError = await this.fundusStudyImagesService.uploadFundusStudyImages(imageFiles, tags);
      if(imageUploadError) {
        if(!suppressAlerts) {
          alert(imageUploadError);
        } else {
          console.log(imageUploadError);
        }
      }
    }

    if(csvFiles.length > 0) {
      let studiesWithDicom = new Map<string, Patient>();

      if(useDicomForPatient) {
        studiesWithDicom = this.fundusStudyImagesService.studiesWithDicomData;
      }

      let csvUploadResult = await this.patientCsvUploadService.upload(csvFiles, tags, studiesWithDicom);

      if(!suppressAlerts) {
        let csvResultMessage = '';
        let dialogType : 'primary' | 'warn' = 'primary';

        csvResultMessage += `${csvUploadResult.patients}件の患者情報が設定されました。`;

        if(csvUploadResult.errors) {
          Object.entries(csvUploadResult.errors).forEach(([_, error]) => {
            if(error.studies.length > 0) {
              csvResultMessage += `\n\n${error.errorMessage}\t`;
              csvResultMessage += error.studies.join('\n\t');
            }
            dialogType = 'warn';
          })
        }

        this.showDialog("患者情報設定", csvResultMessage, dialogType);
      }

    }

    await this.fundusStudiesStoreService.fetchFundusStudyList();
    await this.fundusStudiesStoreService.fetchFundusStudyCount();
  }

  /**
   * 眼底検査のテンプレートを変更する
   * @param id 眼底検査 ID
   * @param templateId 眼底検査のテンプレート ID
   * @returns 眼底検査
   */

  async changeFundusStudyTemplate(id: string, templateId: string) {
    await this.fundusStudiesService.changeFundusStudyTemplate(id, templateId);
  }

  /**
   * ファイルリストからマップを取得する
   * @param files File[]
   * @param tags タグ
   * @returns キーが眼底検査名がで、バリューが眼底検査画像のメタとファイルの配列のマップ
   */

  async getMetaAndFileMap(files: File[], tags: string[] = []) {
    return await this.fundusStudyImagesService.getMetaAndFileMap(files,tags);
  }

  /**
   * 選択した眼底検査の受付状態を変更する
   * @param status 受付状態 (approve | disapprove)
   * @returns boolean
   */


  async setShareRequestStatus(status: 'approve' | 'disapprove') {
    try {
      for(const fundusStudy of this.selection.getSelected()) {
        await this.shareApiService.changeShareRequest(fundusStudy?.diagnosticTemplate?.organizationId, fundusStudy?.id, status);
      }
      this.selection.clear();
      return true;
    } catch (error) {
      alert('サーバーエラー');
      return false;
    }
  }

  /**
   * ダイアログを表示する
   * @param title タイトル (string)
   * @param message メッセージ (string)
   * @param type 種類 (primary | accent | warn)
   */

  async showDialog(title="", message="", type : 'primary' | 'accent' | 'warn' = 'warn') {
    const data: DialogData = {
      title: title,
      content: message,
      actions: ['閉じる', ''],
      color: type,
    };
    this.dialog.open(ConfirmDialogComponent, {
        autoFocus: false,
        data,
      });
  }
}
