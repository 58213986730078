<div class="content" fxLayout="column" fxLayoutGap="20px">
  <div class="content-header" fxLayout="row" fxLayoutGap="20px">
    <span class="title" (click)="collapsed = !collapsed">{{heading}}</span>
    <span>
          <mat-icon *ngIf="collapsed" (click)="collapsed = false">
            <span class="material-symbols-outlined">expand_more</span>
          </mat-icon>
          <mat-icon *ngIf="!collapsed" (click)="collapsed = true">
            <span class="material-symbols-outlined">expand_less</span>
          </mat-icon>
        </span>
  </div>
  <div class="content-body" *ngIf="!collapsed">
    <ng-content></ng-content>
  </div>

</div>
