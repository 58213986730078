<form
  class="card"
  fxLayout="column"
  fxLayoutGap="20px"
  [formGroup]="form"
  *ngIf="loaded"
>
  <span class="title">患者情報の優先度設定</span>
  <div fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="row" fxLayoutAlign="left center" fxLayoutGap="20px">
      <div class="label">デフォルト値</div>
      <mat-radio-group fxFlex fxLayoutGap="20px" formControlName="patientPrioritySource">
        <mat-radio-button value="dicom">DICOMタグ</mat-radio-button>
        <mat-radio-button value="csv">患者情報CSV</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="content" fxLayout="column" fxLayoutGap="20px">
      <div fxLayout="row" fxLayoutGap="20px">
        <span class="title">共有設定</span>
      </div>
      <div fxLayout="row" fxLayoutGap="30px" class="select-field">
        <label fxLayout="row" [style.align-items]="'center'" fxLayoutGap="5px">
          <span>
            診断依頼先の施設
          </span>
          <mat-icon
            fontSet="material-icons-outlined"
            matTooltip="選択した施設へ診断目的で共有する。インポート後の設定も可能。"
          >
            info
          </mat-icon>
        </label>
        <app-dropdown
          formControlName="selectedFacility"
          [options]="sharedFacilities"
          placeholder="選択しない"
        >
        </app-dropdown>

      </div>
      <div fxLayout="row" fxLayoutGap="30px" class="select-field">
        <label>診断テンプレート</label>
        <app-dropdown
          formControlName="selectedTemplate"
          [options]="templates"
          placeholder="選択しない"
        >
        </app-dropdown>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
      <app-button color="gray" type="button" width="94px" height="25px" [mat-dialog-close]="true"
      >キャンセル</app-button
      >
      <app-button
        type="submit"
        color="primary"
        width="52px"
        height="25px"
        (click)="submit()"
        [disabled]="userRole !== 'Admin' || !hasChanges()"
      >保存</app-button
      >
    </div>
  </div>
</form>
