import intersectionby from 'lodash/intersectionBy';
import differenceBy from 'lodash/differenceBy';

import { SelectionModelOverride } from '@app/shared/classes/selection-model-override';
import { FundusStudy } from '@app/diagnosis/models/fundus-study.model';

/**
 * 選択している眼底検査を扱うクラス
 */
export class DiagnosisSelection {
  selectedMap = new Map<string, FundusStudy>();

  currentPageSelection = new SelectionModelOverride<FundusStudy>(true, []);
  beforeSelection = new SelectionModelOverride<FundusStudy>(true, []);

  fundusStudyList: FundusStudy[] = [];

  /**
   * 選択している眼底検査を取得する
   * @returns 選択している眼底検査
   */
  getSelected() {
    return Array.from(this.selectedMap.values());
  }

  /**
   * 選択した眼底検査を一覧画面でソート順に取得する
   * @returns リスト画面でソートされた選択している眼底検査
   */
  getSelectedSorted() {
    return this.fundusStudyList.filter(fundusStudy => this.selectedMap.has(fundusStudy.id));
  }

  /**
   * 選択した眼底検査を検査名の順に取得する
   * @returns 検査名でソートされた選択している眼底検査
   */
  getSelectedSortedByStudyName() {
    let maxNameLen = 0;
    this.getSelected().forEach(fundusStudy => {
      if(maxNameLen < fundusStudy.name.length) {
        maxNameLen = fundusStudy.name.length;
      }
    });

    return this.getSelectedSorted().sort((a, b) => {
      const valueA = a.name.padStart(maxNameLen, '0');
      const valueB = b.name.padStart(maxNameLen, '0');
      return valueA.localeCompare(valueB);
    });
  }

  /**
   * 現在のページのセレクションを初期化する
   * @param fundusStudies ページに表示されている眼底検査
   */
  initCurrentPageSelection(fundusStudies: FundusStudy[]) {
    // ページを移動したとき、そのページの選択している状態を取得するため
    // マップの眼底検査とそのページに表示されている眼底検査の積集合を求める
    this.fundusStudyList = fundusStudies;
    const _fundusStudies = this.getSelected();
    const intersection = intersectionby(fundusStudies, _fundusStudies, 'id');

    this.currentPageSelection.clear();
    this.currentPageSelection.select(...intersection);

    this.beforeSelection.clear();
    this.beforeSelection.select(...intersection);

    // 選択している眼底検査は更新されることがあるためマップを更新する
    intersection.forEach((fundusStudy) =>
      this.selectedMap.set(fundusStudy.id, fundusStudy)
    );
  }

  /**
   * 眼底検査を選択しているか取得する
   * @returns 眼底検査を選択しているか
   */
  hasValue() {
    return !!this.selectedMap.size;
  }

  /**
   * 選択している眼底検査のテンプレートは変更可能ですか取得する
   * @returns 選択している眼底検査のテンプレートは変更可能ですか
   */
  isTemplateChangeable() {
    let changeable = true;
    for(const [_, value] of this.selectedMap) {
      if(value.diagnosticReport?.updatedAt) {
        changeable = false;
        break;
      }
    }

    return changeable;
  }



  /**
   * 選択している眼底検査を保存する
   */
  save() {
    const before = this.beforeSelection.selected;
    const current = this.currentPageSelection.selected;

    // 選択解除した眼底検査をマップに反映するため、選択前との差分を求める
    differenceBy(before, current, 'id').forEach((d) =>
      this.selectedMap.delete(d.id)
    );
    // 選択した眼底検査をマップに反映するため、選択前との差分を求める
    differenceBy(current, before, 'id').forEach((d) =>
      this.selectedMap.set(d.id, d)
    );

    // 差分を求めるために現在選択している眼底検査を保存する
    this.beforeSelection.clear();
    this.beforeSelection.select(...current);
  }

  /**
   * 選択している眼底検査をクリアする
   */
  clear() {
    this.selectedMap = new Map<string, FundusStudy>();
  }
}
