<div fxLayout="row" fxLayoutGap="8px">
  <ng-container *ngIf="!loaded; else romans">
    <ng-container *ngFor="let element of elements">
      <ngx-skeleton-loader
        [theme]="{
          width: '32px',
          height: '32px',
          'border-radius': '50%'
        }"
      ></ngx-skeleton-loader>
    </ng-container>
  </ng-container>
  <ng-template #romans>
    <ng-container *ngFor="let element of elements">
      <div
        class="circle"
        [ngClass]="element.class"
        [class.selected]="selectedRoman === element.roman"
        [class.disabled]="disabled"
        [class.readonly]="readonly"
        (click)="!disabled && !readonly && setRoman(element.roman)"
        *ngIf="loaded"
      >
        {{ element.roman }}
      </div>
    </ng-container>
  </ng-template>
</div>
