<form
  class="card"
  fxLayout="column"
  fxLayoutGap="20px"
  [formGroup]="form"
  *ngIf="loaded"
>
  <span class="title">CSVファイル出力設定</span>
  <div fxLayout="column" fxLayoutGap="20px" *ngIf="loaded">
    <div fxLayout="row" fxLayoutAlign="left center" fxLayoutGap="20px">
      <div class="label">項目名の表示</div>
      <div>
        <mat-slide-toggle formControlName="showHeader"></mat-slide-toggle>
      </div>
    </div>
  </div>

  <div class="content" fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="row" fxLayoutGap="20px">
      <span class="title">出力設定</span>
    </div>
    <div fxLayout="row" fxLayoutGap="30px" class="select-field">
      <label fxLayout="row" [style.align-items]="'center'" fxLayoutGap="5px">
          <span>
            出力形態
          </span>
      </label>
      <app-dropdown
        formControlName="downloadType"
        [options]="downloadTypeOptions"
        placeholder="選択しない"
        [required]="true"
      >
      </app-dropdown>

    </div>
    <div fxLayout="row" fxLayoutGap="30px" class="select-field">
      <label>CSVの文字コード</label>
      <div>
        <mat-radio-group formControlName="downloadEncoding" fxFlex fxLayoutGap="20px">
          <mat-radio-button value="utf-8">UTF-8</mat-radio-button>
          <mat-radio-button value="shift-jis">Shift_JIS</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="30px" class="select-field" [style.align-items]="'baseline'">
      <label fxLayout="row"  fxLayoutGap="5px">
          <span>
            ファイル名
          </span>
      </label>
      <div class="dropdown-wrapper">
        <app-dropdown
          formControlName="fileGrouping"
          [options]="groupingOptions"
          [required]="true"
        >
        </app-dropdown>
        <div class="sample-filename" fxLayout="row" fxLayoutGap="4px">
          <div>例 : </div>
          <div fxLayout="column">
            <div *ngIf="this.downloadTypeHas('pdf')">{{this.getSampleFileName('pdf')}}</div>
            <div *ngIf="this.downloadTypeHas('pdflist')">{{this.getSampleFileName('pdflist')}}</div>
            <div *ngIf="this.downloadTypeHas('csvlist')">{{this.getSampleFileName('csvlist')}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
    <app-button color="gray" type="button" width="94px" height="25px" [mat-dialog-close]="true"
    >キャンセル</app-button
    >
    <app-button
      type="submit"
      color="primary"
      width="52px"
      height="25px"
      (click)="submit()"
      [disabled]="!hasChanges()"
    >保存</app-button
    >
    </div>
</form>
