<div fxLayout="column" fxLayoutGap="20px">

  <div class="trial-message" fxLayout="column" fxLayoutGap="12px" [ngClass]="{'hidden': collapseTrialMessage}">
    <div class="card info" fxLayout="column" fxLayoutGap="20px">
      <div class="info-title" fxLayout="row" fxLayoutGap="5.6px">
        <mat-icon fontSet="material-icons-outlined">campaign</mat-icon>
        <span class="title-message">施設作成から14日間は無料トライアル期間です</span>
        <a class="toggle-trial" (click)="collapseTrialMessage=!collapseTrialMessage">
          <mat-icon *ngIf="!collapseTrialMessage">expand_less</mat-icon>
          <mat-icon *ngIf="collapseTrialMessage">expand_more</mat-icon>
        </a>
      </div>
      <div class="info-details" fxLayout="column" fxLayoutGap="20px">
        <div class="info-description">
          施設作成日から14日を過ぎると、作成された施設はロックされます。<br>
           引き続きルックレック ファンダスをご利用になる場合は、
          <a href="https://docs.google.com/forms/d/e/1FAIpQLScJdmBpRhpLedDw46ZSoCRf2o6csDwlxIvwURVRga3xt45l9w/viewform" target="_blank">
            サポートフォーム
          </a>
          よりご連絡ください。
        </div>
        <div class="info-footer" fxLayout="column" fxLayoutGap="8px">
          <div>※ トライアル期間中は診断用途で使用しないでください</div>
          <div>※ 契約締結後もこのメッセージは表示されます</div>
        </div>
      </div>
    </div>
  </div>

  <form class="card" fxLayout="column" fxLayoutGap="20px" (submit)="submit()">
    <div fxLayout="column" fxLayoutGap="8px">
      <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="20px">
        <label>施設名 *</label>
        <app-input
          width="100%"
          height="40px"
          placeholder="---"
          fxFlex
          [disabled]="userRole !== 'Admin'"
          [formControl]="name"
          [matTooltip]="this.userRole !== 'Admin' ? '操作権限なし' : ''"
        ></app-input>
      </div>
      <span class="error-message" *ngIf="empty">
        必須項目が入力されていません
      </span>
      <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="20px">
        <label>施設ID</label>
        <span class="text">{{ organization?.name }}</span>
      </div>
    </div>

    <div fxLayout="column" fxLayoutGap="12px">
      <mat-divider></mat-divider>
      <app-custom-tooltip
        [tooltipMessage]="this.userRole !== 'Admin' ? '操作権限なし' : ''"
      >
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
          <app-button type="button" width="70px" height="25px" [mat-dialog-close]="true" [disabled]="userRole !== 'Admin'"
            >キャンセル</app-button
          >
          <app-button
            type="submit"
            color="primary"
            width="52px"
            height="25px"
            [disabled]="empty || userRole !== 'Admin'"
            >保存</app-button
          >
        </div>
      </app-custom-tooltip>
    </div>
  </form>

  <form class="card" fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="column" fxLayoutGap="8px" *ngIf="!onetimekey?.key">
      <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="20px">
        <label>連携キー</label>
        <app-custom-tooltip
          [tooltipMessage]="this.userRole !== 'Admin' ? '操作権限なし' : ''"
          [style]="{display: 'inline-block'}"
        >
          <app-outlined-button
            width="122px"
            height="25px"
            color="primary"
            (click)="recreate()"
            [disabled]="userRole !== 'Admin'"
          >
            連携キーを発行
          </app-outlined-button>
        </app-custom-tooltip>
      </div>
    </div>

    <div
      fxLayout="column"
      fxLayoutGap="8px"
      *ngIf="onetimekey?.key && isExpired"
    >
      <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="20px">
        <label>連携キー</label>
        <span class="key expired">{{ onetimekey?.key }}</span>
        <span class="expires-at expired"
          >{{
            onetimekey?.expiresAt | date: "yyyy年MM月dd日 hh:mm":"+0900"
          }}まで有効</span
        >
      </div>
      <div>
        <app-custom-tooltip
          [tooltipMessage]="this.userRole !== 'Admin' ? '操作権限なし' : ''"
          [style]="{display: 'inline-block'}"
        >
          <button [class]="{'active': userRole === 'Admin'}" class="text-link"  (click)="recreate()" [disabled]="userRole !== 'Admin'">
            連携キーを再発行する
          </button>
        </app-custom-tooltip>
      </div>
    </div>

    <div
      fxLayout="column"
      fxLayoutGap="8px"
      *ngIf="onetimekey?.key && !isExpired"
    >
      <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="20px">
        <label>連携キー</label>
        <span class="key">{{ onetimekey?.key }}</span>
        <span class="expires-at"
          >{{
            onetimekey?.expiresAt | date: "yyyy年MM月dd日 hh:mm":"+0900"
          }}まで有効</span
        >
        <mat-icon
          [cdkCopyToClipboard]="onetimekey?.key || ''"
          (click)="showMessage()"
          >content_copy</mat-icon
        >
      </div>
      <div>
        <app-custom-tooltip
          [tooltipMessage]="this.userRole !== 'Admin' ? '操作権限なし' : ''"
          [style]="{display: 'inline-block'}"
          [tooltipOffset]="[0,-6]"
        >
          <button [class]="{'active': userRole === 'Admin'}" class="text-link" (click)="recreate()" [disabled]="userRole !== 'Admin'">
            連携キーを再発行する
          </button>
        </app-custom-tooltip>
      </div>

    </div>
    <div fxFlex fxLayout="row" fxLayoutGap="4px" class="key-use-warning">
      <div>※</div>
      <div>
        連携キーを提供する際は、提供先が契約を締結した施設であることをよくご確認ください。<br>
        提供先を間違えた場合は、連携キーを再発行し、改めて正しい施設へ提供してください。
      </div>
    </div>
  </form>
</div>
