// TODO: containers に移動する

import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { compareAsc } from 'date-fns';

import { Organization } from '@app/core/models';
import {
  Auth0Service,
  Auth0StoreService,
  OnetimekeyApiService,
} from '@app/core/services';
import { SnackBarComponent } from '@app/shared/components';

import { Onetimekey } from '@app/core/services/onetimekey-api.service';
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-organization-info-setting',
  templateUrl: './organization-info-setting.component.html',
  styleUrls: ['./organization-info-setting.component.scss'],
})
export class OrganizationInfoSettingComponent implements OnInit, OnDestroy {
  organization?: Organization;

  onetimekey?: Onetimekey;

  name! : UntypedFormControl;

  userRole!: string | undefined;

  collapseTrialMessage: boolean = false;

  private readonly destroy$ = new Subject<void>();

  get empty() {
    return this.name && this.name.dirty && this.name.hasError('required');
  }

  get isExpired() {
    const expiresAt = new Date(this.onetimekey?.expiresAt || '');
    return compareAsc(expiresAt, new Date()) === -1;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private matSnackBar: MatSnackBar,
    private auth0Service: Auth0Service,
    private auth0StoreService: Auth0StoreService,
    private onetimekeyApiService: OnetimekeyApiService
  ) {}

  async ngOnInit() {
    this.name = this.fb.control('', [Validators.required]);
    this.organization = await this.auth0Service.getOrganization();
    this.name.setValue(this.organization.display_name);
    this.onetimekey = await this.onetimekeyApiService.getOnetimekey();


    this.auth0StoreService.currentUserRoleSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe(role => this.userRole = role);


  }

  async submit() {
    try {
      await this.auth0Service.updateOrganization({
        display_name: this.name.value,
      });
      await this.auth0StoreService.fetchBelongedOrganizations();
      this.matSnackBar.openFromComponent(SnackBarComponent, {
        data: {
          success: true,
          message: '施設の情報を更新しました',
        },
        duration: 3 * 1000,
      });
    } catch (error) {
      this.matSnackBar.openFromComponent(SnackBarComponent, {
        data: {
          failure: true,
          message: 'サーバーエラー',
        },
        duration: 3 * 1000,
      });
    }
  }

  async recreate() {
    this.onetimekey = await this.onetimekeyApiService.createOnetimekey();
  }

  showMessage() {
    this.matSnackBar.openFromComponent(SnackBarComponent, {
      data: {
        success: true,
        message: 'クリップボードにコピーしました',
      },
      duration: 3 * 1000,
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
