import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

import {
  Auth0Service,
} from '@app/core/services';
import {ShortcutKey} from "@app/core/models/user.model";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {SnackBarComponent} from "@app/shared/components";

@Component({
  selector: 'app-shortcut-key-setting',
  templateUrl: './shortcut-key-setting.component.html',
  styleUrls: ['./shortcut-key-setting.component.scss']
})
export class ShortcutKeySettingComponent implements OnInit {

  form! : UntypedFormGroup;
  fields!: {label: string, id: string}[];
  shortcutKeyLabels : {[key: string] : string} = {
    next: '検査画像送り',
    back: '検査画像戻し',
    zoom_right: '右眼検査画像の拡大 / 縮小',
    zoom_left: '左眼検査画像の拡大 / 縮小',
  };
  keys : {code: string, key: string}[] = [];
  specialKeys: {[key: string]: string} = {
    "←": "ArrowLeft",
    "→": "ArrowRight",
    "↑": "ArrowUp",
    "↓": "ArrowDown",
    ";": "Semicolon",
    ",": "Comma",
    ".": "Period",
    "/": "Slash",
  }

  constructor(
    private fb: UntypedFormBuilder,
    private auth0Service: Auth0Service,
    private matSnackBar: MatSnackBar,
  ) {}

  initKeys() {
    const keys : {code: string, key: string}[] = [];
    Array.from(Array(10)).forEach((_, i) => {
      keys.push({
        code: `Digit${i}`,
        key: i.toString()
      });
    });

    Object.keys(this.specialKeys).forEach(key => {
      keys.push({
        code: this.specialKeys[key],
        key: key
      })
    });

    Array.from(Array(26)).map((_, i) => {
      const letter = String.fromCharCode(97 + i).toUpperCase();
      keys.push({
        code: `Key${letter}`,
        key: letter
      });
    });
    this.keys = keys;
  }

  get disallowedShortcuts() : ShortcutKey[] {
    let disallowedKeysWithModifier = ['C','X','V','Z','Y','A','S','P','F','N','W','T','H','J','L','0','1','2','3','4','5','6','7','8','9','-',"←","→","↑","↓","@","[","]","+"];
    let disallowedShortcuts : ShortcutKey[] = [];
    disallowedKeysWithModifier.forEach(key => {
      const k = this.keys.find(k => k.key === key);
      if(k) {
        disallowedShortcuts.push({modifier: 'Control', key: k.code})
        disallowedShortcuts.push({modifier: 'Meta', key: k.code})
      }

    });

    return disallowedShortcuts;
  }

  get shortcutKeys() {
    let shortcutKeys : {[key: string] : ShortcutKey} = {};

    for(let k in this.shortcutKeyLabels) {
      shortcutKeys[k] = {
        modifier: this.form.value[`${k}_modifier`] ?? '',
        key: this.form.value[`${k}_key`] ?? '',
      };
    }

    return shortcutKeys;
  }

  get errors() {
    let errors : {[key: string] : string} = {};

    for(let k in this.shortcutKeys) {
      const shortcutKey = this.shortcutKeys[k];

      if(!!shortcutKey.modifier && !shortcutKey.key) {
        errors[k] = '通常キーが入力されていません';
      }

      // check if key is disallowed
      if(this.disallowedShortcuts.find(disallowedShortcut => disallowedShortcut.key === shortcutKey.key && disallowedShortcut.modifier === shortcutKey.modifier)) {
        // cannot use default shortcuts used by browsers and OSs
        errors[k] = 'ブラウザやOSの標準ショートカットキーは使用できません';
      }
    }


    return errors;
  }

  get canSubmit() : boolean {
    return this.form.dirty && Object.keys(this.errors).length === 0;
  }

  keyAlreadyUsed(key: string, shortcutKey: ShortcutKey) {
    return Object.keys(this.shortcutKeys).find(k => {
      const k2 = this.shortcutKeys[k];
      return k !== key && k2.key === shortcutKey.key && k2.modifier === shortcutKey.modifier;
    });
  }

  async ngOnInit() {
    let form = this.fb.group({});
    let currentSettings = await this.auth0Service.getUserSettings();
    let shortcutKeys = currentSettings.shortcutKeys as {[key: string] : ShortcutKey} ?? {
      next: {modifier: '', key: 'KeyF'},
      back: {modifier: '', key: 'KeyB'},
      zoom_right: {modifier: '', key: 'KeyZ'},
      zoom_left: {modifier: '', key: 'KeyD'},
    };

    this.initKeys();

    this.fields = Object.keys(this.shortcutKeyLabels).map(field => {
      return {
        label: this.shortcutKeyLabels[field],
        id: field
      }
    });

    for(let k in this.shortcutKeyLabels) {
      form.addControl(`${k}_modifier`, this.fb.control(
        shortcutKeys[k] ? shortcutKeys[k].modifier : ''
      ));

      form.addControl(`${k}_key`, this.fb.control(
        shortcutKeys[k] ? shortcutKeys[k].key : ''
      ));
    }
    this.form = form;
  }

  async submit() {
    await this.auth0Service.saveUserSettings({
      shortcutKeys: this.shortcutKeys
    });

    this.matSnackBar.openFromComponent(SnackBarComponent, {
      data: {
        success: true,
        message: 'ショートカットキー設定を保存しました',
      },
      duration: 3 * 1000,
    });

    this.form.reset(this.form.value);
  }
}
