import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import {
  FundusStudiesService,
  FundusStudiesParamsService,
} from '@app/diagnosis/services';
import {
  FundusStudiesResponse,
  FundusStudiesCount,
  TemplatesApiResponse
} from '@app/diagnosis/models';
import { TemplatesApiService } from './templates-api.service';

/**
 * 眼底検査の Store クラス
 */
@Injectable({
  providedIn: 'root',
})
export class FundusStudiesStoreService {
  fundusStudiesSubject = new BehaviorSubject<FundusStudiesResponse | null>(
    null
  );
  fundusStudiesCountSubject = new BehaviorSubject<FundusStudiesCount | null>(
    null
  );

  fundusStudyTemplatesSubject = new BehaviorSubject<TemplatesApiResponse | null>(
    null
  )

  /**
   * 眼底検査一覧の Observable を取得する
   * @returns 眼底検査一覧
   */
  get fundusStudies$() {
    return this.fundusStudiesSubject.asObservable();
  }

  /**
   * 眼底検査数の Observable を取得する
   * @returns 眼底検査数
   */
  get fundusStudiesCount$() {
    return this.fundusStudiesCountSubject.asObservable();
  }

  /**
   * 眼底検査テンプレートの一覧の Observable を取得する
   * @returns 眼底検査テンプレートの一覧
   */
  get fundusStudyTemplates$() {
    return this.fundusStudyTemplatesSubject.asObservable();
  }



  constructor(
    private fundusStudiesService: FundusStudiesService,
    private fundusStudiesParamsService: FundusStudiesParamsService,
    private templateApiService: TemplatesApiService
  ) {}

  /**
   * 眼底検査一覧をフェッチする
   */
  async fetchFundusStudyList() {
    const params = this.fundusStudiesParamsService.getFundusStudiesSearchParams();
    const response = await this.fundusStudiesService.searchFundusStudies(params);
    this.fundusStudiesSubject.next(response);
  }

  /**
   * 眼底検査数をフェッチする
   */
  async fetchFundusStudyCount() {
    const params = this.fundusStudiesParamsService.getFundusStudiesSearchParams();
    const response = await this.fundusStudiesService.searchFundusStudyCount(params);
    this.fundusStudiesCountSubject.next(response);
  }

  async fetchFundusStudyTemplates() {
    const response = await this.templateApiService.getTemplates();
    this.fundusStudyTemplatesSubject.next(response);
  }
}
