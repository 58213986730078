<mat-form-field
  appearance="fill"
  [style.opacity]="disabled ? 0.4 : 1"
>
  <mat-date-range-input [rangePicker]="picker" class="date-range-picker">
    <input [disabled]="disabled"
           [readOnly]="readonly"
           placeholder="YYYY/MM/DD"
           matStartDate
           (click)="!disabled && !readonly && picker.open()"
           [ngModel]="value.start"
           (change)="dateChangeByText($event, 'start')"
           (dateChange)="dateChange($event, 'start')"
           [ngModelOptions]="{standalone: true}"
      />
    <input [disabled]="disabled"
           [readOnly]="readonly"
           placeholder="YYYY/MM/DD"
           matEndDate
           (click)="!disabled && !readonly && picker.open()"
           [ngModel]="value.end"
           (change)="dateChangeByText($event, 'end')"
           (dateChange)="dateChange($event, 'end')"
           [ngModelOptions]="{standalone: true}"
      />
  </mat-date-range-input>
  <mat-datepicker-toggle
    matSuffix
    [disabled]="disabled || readonly"
    [for]="picker"
  ></mat-datepicker-toggle>
  <mat-date-range-picker [disabled]="disabled || readonly" #picker></mat-date-range-picker>
</mat-form-field>
