import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-select-roman',
  templateUrl: './select-roman.component.html',
  styleUrls: ['./select-roman.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectRomanComponent),
      multi: true,
    },
  ],
})
export class SelectRomanComponent implements ControlValueAccessor {
  @Input() selectedRoman?: string;
  @Input() disabled?: boolean;
  @Input() loaded: boolean = true;
  @Input() readonly?: boolean;
  @Input() allowEmpty = true;

  onChange!: Function;
  OnTouched!: Function;

  readonly elements = [
    {
      roman: '0',
      class: 'zero',
    },
    {
      roman: 'I',
      class: 'i',
    },
    {
      roman: 'IIa',
      class: 'iia',
    },
    {
      roman: 'IIb',
      class: 'iib',
    },
    {
      roman: 'III',
      class: 'iii',
    },
    {
      roman: 'IV',
      class: 'iv',
    },
  ];

  writeValue(obj: any) {
    if (!this.allowEmpty) {
      this.selectedRoman = obj || '0';
    } else {
      this.selectedRoman = obj;
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.OnTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  setRoman(roman: string) {
    this.selectedRoman = roman;
    this.onChange(roman);
  }
}
