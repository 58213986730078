<div fxLayout="column" fxLayoutGap="20px">
  <form class="card" fxLayout="column" fxLayoutGap="20px" (submit)="submit()">
    <span class="title">{{ organization?.display_name }}が連携した施設</span>

    <div fxLayout="column" fxLayoutGap="4px">
      <app-custom-tooltip
        tooltipPosition="below"
        [tooltipOffset]="[0,8]"
        [tooltipMessage]="userRole !== 'Admin' ? '操作権限なし' : ''"
      >
        <div fxLayout="row"
             fxLayoutAlign=" center"
             fxLayoutGap="8px"
        >
          <app-input
            width="100%"
            height="40px"
            placeholder="連携キーを入力してください"
            fxFlex
            [formControl]="onetimekey"
            [disabled]="userRole !== 'Admin'"
          ></app-input>
          <app-button  [disabled]="userRole !== 'Admin'" color="primary" width="70px" height="25px">連携</app-button>
        </div>
      </app-custom-tooltip>

      <span class="error-message" *ngIf="error">無効な連携キーです</span>
    </div>

    <div class="list" fxLayout="column" fxLayoutGap="4px">
      <span class="empty" *ngIf="!collaborators?.to?.length"
        >連携している施設はありません</span
      >
      <ng-container *ngFor="let collaborator of collaborators?.to">
        <div class="list-item" fxLayout="row" fxLayoutAlign=" center">
          <span class="item" fxFlex>{{ collaborator.displayName }}</span>
          <app-custom-tooltip
            tooltipPosition="below"
            [tooltipOffset]="[0,-18]"
            [tooltipMessage]="userRole !== 'Admin' ? '操作権限なし' : ''"
          >
             <button
                 mat-icon-button aria-label="施設連携を削除"
                 (click)="deleteCollaborator(collaborator); $event.preventDefault()"
                 (mouseenter)="hoverdCollaboratorId = collaborator.id"
                 (mouseleave)="hoverdCollaboratorId = ''"
                 [disabled]="userRole !== 'Admin'"
               >          <mat-icon
                 *ngIf="collaborator.id !== hoverdCollaboratorId"
               >link</mat-icon>
              <mat-icon
                *ngIf="collaborator.id === hoverdCollaboratorId"
              >link_off</mat-icon>
            </button>
          </app-custom-tooltip>

        </div>
      </ng-container>
    </div>
  </form>

  <form class="card" fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="8px">
      <span class="title"
        >{{ organization?.display_name }}へ連携された施設</span
      >
    </div>

    <div class="list" fxLayout="column" fxLayoutGap="4px">
      <span class="empty" *ngIf="!collaborators?.from?.length"
        >連携されている施設はありません</span
      >
      <ng-container *ngFor="let collaborator of collaborators?.from">
        <span class="list-item item">{{ collaborator.displayName }}</span>
      </ng-container>
    </div>
  </form>
</div>
