import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsApiService {
  apiPath = `${environment.apiUrl}`;

  constructor(private http: HttpClient) {}

  createDefaultTemplate(organizationId: string) {
    return firstValueFrom(
      this.http.post<any>(
        `${this.apiPath}/organizations/${organizationId}/templates/default`,
        {}
      )
    );
  }

  deleteOrganizationUser(organizationId: string, userId: string) {
    return firstValueFrom(
      this.http.delete<void>(
        `${this.apiPath}/organizations/${organizationId}/users/${userId}`
      )
    );
  }
}
