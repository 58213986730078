import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { firstValueFrom } from 'rxjs';
import { fromBlob } from 'file-type/browser';
import md5 from 'md5';

import { Auth0Service } from '@app/core/services';
import {
  FundusStudiesService,
  FundusStudyImagesApiService,
  PatientsService
} from '@app/diagnosis/services';
import {
  FundusStudyImageSignedURLRequest,
  FundusStudyImages,
  MetaAndFile, FundusStudiesResponse, Patient, FundusStudyImageMeta,
} from '@app/diagnosis/models';
import { Messages } from '@constants/messages';
import {DicomImagesService} from "@app/diagnosis/services/dicom-images.service";
import moment from "moment";

/**
 * 眼底検査画像 API のビジネスロジックが実装されたクラス
 */
@Injectable({
  providedIn: 'root',
})
export class FundusStudyImagesService {
  public lastUploadedStudyName : string = "";
  public studiesWithDicomData! : Map<string, Patient>;

  constructor(
    private domSanitizer: DomSanitizer,
    private auth0Service: Auth0Service,
    private fundusStudyImagesApiService: FundusStudyImagesApiService,
    private fundusStudiesService: FundusStudiesService,
    private dicomImagesService: DicomImagesService,
    private patientsService: PatientsService,
  ) {}

  /**
   * 眼底検査画像をダウンロードする URL を発行する
   * @param id 眼底検査画像 ID
   * @returns 署名付き URL
   */
  async getDownloadUrl(id: string) {
    return firstValueFrom(this.fundusStudyImagesApiService.getDownloadUrl(id));
  }

  /**
   * 眼底検査画像をアップロードする URL を発行する
   * @param fundusStudyImage 眼底検査画像のメタ
   * @returns 署名付き URL
   */
  async getUploadUrl(fundusStudyImage: FundusStudyImageSignedURLRequest) {
    return firstValueFrom(
      this.fundusStudyImagesApiService.getUploadUrl(fundusStudyImage)
    );
  }

  /**
   * 眼底検査画像をダウンロードする
   * @param signedUrl 署名付き URL
   * @returns Blob
   */
  async download(signedUrl: string) {
    return firstValueFrom(this.fundusStudyImagesApiService.download(signedUrl));
  }

  /**
   * 眼底検査データを取得する
   * @param fundusStudyName 眼底検査名
   * @param tags タグ
   * @returns FundusStudyData
   */
  async getFundusStudyData(fundusStudyName: string, tags: string[] = [])  {
    try {
      let fundusStudyId : string;
      let leftFundusImageCount : number;
      let rightFundusImageCount : number;
      let leftOctImageCount : number;
      let rightOctImageCount : number;
      let diagnosisSet : boolean;

      const fundusStudyList : FundusStudiesResponse = await this.fundusStudiesService.searchFundusStudies({
        studyNameExact: fundusStudyName,
        tags: tags.length > 0 ? tags.join(",") : "",
        limit: 1,
        offset: 0
      });

      if(fundusStudyList?.fundusStudies && fundusStudyList.fundusStudies.length > 0) {
        fundusStudyId = fundusStudyList.fundusStudies[0].id
        leftFundusImageCount = fundusStudyList.fundusStudies[0].fundusImages?.left
          .filter(image => image.type === 'fundus').length || 0;
        rightFundusImageCount = fundusStudyList.fundusStudies[0].fundusImages?.right
          .filter(image => image.type === 'fundus').length || 0;
        leftOctImageCount = fundusStudyList.fundusStudies[0].fundusImages?.left
          .filter(image => image.type === 'oct').length || 0;
        rightOctImageCount = fundusStudyList.fundusStudies[0].fundusImages?.right
          .filter(image => image.type === 'oct').length || 0;
        diagnosisSet = !!fundusStudyList.fundusStudies[0].diagnosticReport?.createdAt;
      } else {
        fundusStudyId = '';
        leftFundusImageCount = 0;
        rightFundusImageCount = 0;
        leftOctImageCount = 0;
        rightOctImageCount = 0;
        diagnosisSet = false;
      }

      return {
        fundusStudyId,
        leftFundusImageCount,
        rightFundusImageCount,
        leftOctImageCount,
        rightOctImageCount,
        diagnosisSet,
      };
    } catch (e) {
      return null;
    }
  }

  /**
   * 眼底検査画像をアップロードする
   * @param signedUrl 署名付き URL
   * @param file ファイル
   * @param fundusStudyImage 眼底検査画像
   */
  async upload(
    signedUrl: string,
    file: File,
    fundusStudyImage: FundusStudyImageSignedURLRequest
  ) {
    return await firstValueFrom(
      await this.fundusStudyImagesApiService.upload(signedUrl, file, fundusStudyImage)
    );
  }

  /**
   * 眼底検査画像をダウンロードする
   * @param id 眼底検査画像 ID
   * @returns 眼底検査画像の SafeResourceUrl
   */
  async downloadFundusStudyImage(id: string) {
    const { signedUrl } = await this.getDownloadUrl(id);
    const downloadData = await this.download(signedUrl);

    const objectUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
      URL.createObjectURL(downloadData.response.body as Blob)
    );

    return {
      objectUrl,
      signedUrl: signedUrl,
      type: downloadData.type
    }
  }

  /**
   * 左右複数の眼底検査画像をダウンロードする
   * @param fundusStudyImages 左右複数の眼底検査画像
   * @returns 眼底検査画像
   */
  async downloadFundusStudyImages(fundusStudyImages: FundusStudyImages) {
    const _fundusStudyImages = {
      right: [],
      left: [],
    } as FundusStudyImages;

    try {
      for (const fundusStudyImage of fundusStudyImages?.right || []) {
        const imageDownload = await this.downloadFundusStudyImage(
          fundusStudyImage.id
        );
        _fundusStudyImages.right.push({
          ...fundusStudyImage,
          imageUrl: imageDownload.objectUrl,
          signedUrl: imageDownload.signedUrl,
          mimeType: imageDownload.type
        });
      }
      for (const fundusStudyImage of fundusStudyImages?.left || []) {
        const imageDownload = await this.downloadFundusStudyImage(
          fundusStudyImage.id
        );
        _fundusStudyImages.left.push({
          ...fundusStudyImage,
          imageUrl: imageDownload.objectUrl,
          signedUrl: imageDownload.signedUrl,
          mimeType: imageDownload.type
        });
      }
    } catch (error) {
      alert('サーバーエラー');
    }

    return _fundusStudyImages;
  }

  /**
   * 署名付き URL を発行して、その URL に対して眼底検査画像をアップロードする
   * @param MetaAndFile 眼底検査画像のメタとファイル
   */
  async uploadFundusStudyImage({ meta, file }: MetaAndFile) {
    try {
      const { signedUrl } = await this.getUploadUrl(meta);
      await this.upload(signedUrl, file, meta);
      this.lastUploadedStudyName = meta.name;
    } catch (error) {
      return false;
    }

    return true;
  }

  /**
   * ポーリング：成功するまで画像のアップロード
   * @param MetaAndFile 眼底検査画像のメタとファイル
   */
  async uploadUntilSuccess({meta, file}: MetaAndFile) {
    let uploadStatus = await this.uploadFundusStudyImage({ meta, file });

    if(!uploadStatus) {
      throw new Error('アップロードに失敗しました');
    }
  }

  /**
   * 複数の眼底検査画像をアップロードする
   * @param files File[]
   * @param tags string[]
   */
  async uploadFundusStudyImages(files: File[], tags: string[] = []) : Promise<string> {
    let serverError = false;

    try {
      const metaAndFileMap = await this.getMetaAndFileMap(files, tags);
      const studiesWithError : string[] = [];

      const fileNameError = [];
      for (const [name, metaAndFileList] of metaAndFileMap) {
        let ok = true;

        for (const metaAndFile of metaAndFileList) {
          const {id, date, time, type, direction, number} = metaAndFile.meta;

          if (!id || !date || !time || !type || !direction || !number) {
            ok = false;
          }

          if(direction?.toUpperCase() !== 'R' && direction?.toUpperCase() !== 'L') {
            ok = false;
          }

          if(!date) {
            ok = false;
          } else {
            const formatDate = new Date(date.substr(0, 4) + "-" + date.substr(4, 2) + "-" + date.substr(6, 2));
            const timestamp = formatDate.getTime();
            if (Number.isNaN(timestamp)) {
              ok = false;
            }
          }

          if(!time?.match(/^[0-9]{6}$/)) {
            ok = false;
          } else {
            const [hh, mm, ss] = [time.substr(0,2),time.substr(2,2),time.substr(4,2)];
            if(parseInt(hh) > 23 || parseInt(mm) > 59 || parseInt(ss) > 59) {
              ok = false;
            }
          }

          if(!ok) {
            fileNameError.push(name);
            studiesWithError.push(name);
            break;
          }
        }
      }

      // 画像の形式をチェック
      const errorsFundusStudy1 = [];
      for (const [name, metaAndFileList] of metaAndFileMap) {
        let ok = true;
        const files = metaAndFileList
          .filter((metaAndFile) => metaAndFile.file.type === 'image/jpeg')
          .map((metaAndFile) => metaAndFile.file);
        ok = await this.checkAllJPG(files);
        if (!ok) {
          errorsFundusStudy1.push(name);
          !studiesWithError.includes(name) && studiesWithError.push(name);
        }
      }

      // 画像の枚数をチェック
      const errorsFundusStudy2 = [];
      for (const [name, metaAndFileList] of metaAndFileMap) {
        const okLeft =
          metaAndFileList
            .filter((metaAndFile) => metaAndFile.meta.direction === 'L' && metaAndFile.meta.type === 'fundus')
            .length <= 3
          && metaAndFileList
            .filter((metaAndFile) => metaAndFile.meta.direction === 'L' && metaAndFile.meta.type === 'oct')
            .length <= 3
        ;
        const okRight =
          metaAndFileList
            .filter((metaAndFile) => metaAndFile.meta.direction === 'R' && metaAndFile.meta.type === 'fundus')
            .map((metaAndFile) => metaAndFile.file).length <= 3
          && metaAndFileList
            .filter((metaAndFile) => metaAndFile.meta.direction === 'R' && metaAndFile.meta.type === 'oct')
            .length <= 3
        ;

        if (!okLeft || !okRight) {
          errorsFundusStudy2.push(name);
          !studiesWithError.includes(name) && studiesWithError.push(name);
        }
      }

      // 検査名 -> 検査ID
      const nameIdMap = new Map<string, string>();

      // ファイル名の末尾をチェック
      const errorsFundusStudy3 = [];
      const diagnosisSetError = [];

      for (const [name, metaAndFileList] of metaAndFileMap) {
        const leftImageUploadCount = metaAndFileList
          .filter((metaAndFile) => metaAndFile.meta.direction === 'L' && metaAndFile.meta.type === 'fundus')
          .map((metaAndFile) => metaAndFile.meta).length;
        const rightImageUploadCount = metaAndFileList
          .filter((metaAndFile) => metaAndFile.meta.direction === 'R' && metaAndFile.meta.type === 'fundus')
          .map((metaAndFile) => metaAndFile.meta).length;
        const leftOctUploadCount = metaAndFileList
          .filter((metaAndFile) => metaAndFile.meta.direction === 'L' && metaAndFile.meta.type === 'oct')
          .map((metaAndFile) => metaAndFile.meta).length;
        const rightOctUploadCount = metaAndFileList
          .filter((metaAndFile) => metaAndFile.meta.direction === 'R' && metaAndFile.meta.type === 'oct')
          .map((metaAndFile) => metaAndFile.meta).length;


        let fundusData = await this.getFundusStudyData(name, tags);
        if(fundusData == null) {
          serverError = true;
          break;
        }

        if (fundusData.fundusStudyId) {
          nameIdMap.set(name, fundusData.fundusStudyId);
        }

        let isOK = true;
        if(fundusData?.leftFundusImageCount + leftImageUploadCount > 3) isOK = false;
        if(fundusData?.rightFundusImageCount + rightImageUploadCount > 3) isOK = false;
        if(fundusData?.leftOctImageCount + leftOctUploadCount > 3) isOK = false;
        if(fundusData?.rightOctImageCount + rightOctUploadCount > 3) isOK = false;


        if (!isOK) {
          !errorsFundusStudy2.includes(name) && errorsFundusStudy3.push(name);
          !studiesWithError.includes(name) && studiesWithError.push(name);
        }

        if(fundusData?.diagnosisSet) {
          diagnosisSetError.push(name);
          !studiesWithError.includes(name) && studiesWithError.push(name);
        }
      }

      // エラーメッセージ表示
      let message = '';
      if (fileNameError.length) {
        message += `インポートに失敗しました。\n無効なファイル名です。\nインポート失敗した検査：\n${fileNameError.join(
          '\n'
        )}\n\n`;
      }
      if (errorsFundusStudy1.length) {
        message += `インポートに失敗しました。\nインポート対象のファイルはJPEGファイルではありません。\nインポート失敗した検査：\n${errorsFundusStudy1.join(
          '\n'
        )}\n\n`;
      }
      if (errorsFundusStudy2.length) {
        message += `インポートに失敗しました。\nインポート可能な検査画像の上限を超えています。\n最大インポート枚数は【3枚 / 片目】です。\nインポート失敗した検査 :\n${errorsFundusStudy2.join(
          '\n'
        )}\n\n`;
      }
      if (errorsFundusStudy3.length) {
        message += `インポートに失敗しました。\n既に3枚の画像がインポートされています。\n最大インポート枚数は【3枚 / 片目】です。\nインポート失敗した検査 :\n${errorsFundusStudy3.join(
          '\n'
        )}\n\n`;
      }

      if (diagnosisSetError.length) {
        message += `インポートに失敗しました。\n診断確定済みの検査が含まれています。\nインポート失敗した検査 :\n${diagnosisSetError.join(
          '\n'
        )}\n\n`;
      }

      if(serverError) {
        message += 'サーバーエラー';
      }

      // アップロード
      let hasUploaded = false;
      let studiesWithDicomData = new Map<string, Patient>();

      for (const [name, metaAndFileList] of metaAndFileMap) {
        if(studiesWithError.includes(name)) { // 検査にエラーがある場合は、インポートしない
          continue;
        }
        let fundusStudyId: string = '';

        try {
          const {
            meta: { date },
          } = metaAndFileList[0];

          if (!nameIdMap.has(name)) {
            const response = await this.fundusStudiesService.createFundusStudy(name, date, tags);
            fundusStudyId = response.id;
            nameIdMap.set(name, fundusStudyId);
          } else {
            fundusStudyId = nameIdMap.get(name) || '';
          }
        } catch (e) {
          console.log(name + ": 既に検査が作成しました。");
        }


        for (const { meta, file } of metaAndFileList) {
          await this.uploadUntilSuccess({ meta, file })
          hasUploaded = true;
        }

        const imageWithDicom = metaAndFileList.find(metaAndFile => metaAndFile.meta.mimeType === 'application/dicom');
        if(imageWithDicom && fundusStudyId) {
          // 患者情報はDICOMから取得
          const dicomJson = await this.dicomImagesService.extractDicomDataFromFile(imageWithDicom.file);

          let birthday : string | Date = '';
          if(dicomJson['x00100030']) {
            birthday = moment(dicomJson['x00100030'].trim(), 'YYYYMMDD').toDate();
          }

          let sex = ''
          if(dicomJson['x00100040']) {
            sex = dicomJson['x00100040'].trim() === 'M' ? '男性' : '女性';
          }

          const patientData = {
            id: fundusStudyId,
            name: '',
            sex,
            birthday: birthday,
          } as Patient

          studiesWithDicomData.set(fundusStudyId, patientData);
          await this.patientsService.updatePatient(fundusStudyId, patientData);
        }
      }

      this.studiesWithDicomData = studiesWithDicomData;

      if (
        fileNameError.length ||
        errorsFundusStudy1.length ||
        errorsFundusStudy2.length ||
        errorsFundusStudy3.length ||
        diagnosisSetError.length ||
        serverError
      ) {
        return message;
      }
    } catch (error) {
      console.error(error);
      return Messages.errors.upload;
    }

    return '';
  }

  /**
   * ファイルリストからマップを取得する
   * @param files File[]
   * @param tags string[]
   * @returns キーが眼底検査名がで、バリューが眼底検査画像のメタとファイルの配列のマップ
   */
  async getMetaAndFileMap(files: File[], tags: string[] = []) {
    const metaAndFileMap = new Map<string, MetaAndFile[]>();

    for (const file of files) {
      const meta = await this.getMeta(file, tags);
      const _metaAndFileList = metaAndFileMap.get(meta.name) || [];
      const metaAndFileList = [..._metaAndFileList, { meta, file }];
      metaAndFileMap.set(meta.name, metaAndFileList);
    }

    // インデックスで並べ替え
    for (const [name, metaAndFileList] of metaAndFileMap) {
      const sortedMetaAndFileList = this.groupAndSortByIndex(metaAndFileList);
      metaAndFileMap.set(name, sortedMetaAndFileList);
    }

    return metaAndFileMap;
  }

  // 撮影種類を判定
  private parseImageType(type: string) : "fundus" | "oct" {
    const str = type.toLowerCase();
    if (str.includes('color') || str.includes('opacitysuppression')) {
      return 'fundus';
    }
    return 'oct';
  }

  // dicom ファイルから画像メタを取得する
  private async getMetaFromDicom(file: File, tags: string[] = []) {
    const dicomJson = await this.dicomImagesService.extractDicomDataFromFile(file);
    const dicomKeyGet = (key: string, key2: string = '', key3: string = '') => {
      const val = dicomJson[key] || (key2 && dicomJson[key2]) || (key3 && dicomJson[key3]);
      return val ? val.trim() : '';
    }

    const shootingType = dicomKeyGet('x00181030','x0008103e','x00080060');
    const modality = dicomKeyGet('x00080060');
    const direction = dicomKeyGet('x00200060','x00200062');
    let type : 'fundus' | 'oct' = 'fundus'
    if(modality) {
      type = modality.toLowerCase() === 'opt' ? 'oct' : 'fundus'
    } else {
      type = this.parseImageType(shootingType);
    }

    let [time] = dicomKeyGet('x00080033','x00080030').split('.');
    const md5Hash = await this.getMD5(file);
    const own = await this.auth0Service.getOwnUserInfo();
    const id = dicomKeyGet('x00100020');
    const date = dicomKeyGet('x00080020');
    const typeDetail = dicomKeyGet('x00204000');

    if(time.length === 9) {
      time = time.substring(0, 6);
    } else if(time.length === 8) {
      time = time.substring(0, 5);
    }

    let meta : FundusStudyImageMeta = {
      id,
      name: `${id}_${date}`,
      direction: direction.toUpperCase(),
      time,
      number: time,
      mimeType: 'application/dicom',
      md5Hash,
      userId: own?.sub || '',
      organizationId: own ? own['org_id'] : '',
      date: `${date}${time}`,
      type: type,
      shootingType,
      typeDetail
    };

    if(tags.length > 0) {
      meta.tags = encodeURIComponent(tags.join(","));
    }

    return meta;
  }

  private async getMetaFromJpeg(file: File, tags: string[] = []) {
    const parts = file.name.split('.')[0].split('_');

    let id: string = '';
    let date: string = '';
    let time: string = '';
    let direction: string = '';
    let number: string = '';
    let shootingType: string = '';
    let typeDetail: string = '';

    if (isNaN(parseInt(parts[parts.length - 1]))) {
      // 最後の値が数値で無いケースの時は、ファイル名の最後に【_1】をつける
      parts.push('1');
    }

    const valSecondFromEnd = parts[parts.length - 2].toUpperCase();

    if(parts.length >= 6 && (valSecondFromEnd === 'R' || valSecondFromEnd === 'L')) {
      // パターン 1：各情報の個数が全部で6つ：眼科標準ファイル名の眼底画像がこのケース
      // パターン 2：最後から2番目がR/Lのどちらか、かつ、各情報の個数が全部で7つ

      [id, date, time, shootingType, direction, number] = [
        parts[0],
        parts[1],
        parts[2],
        parts[parts.length - 3],
        parts[parts.length - 2],
        parts[parts.length - 1],
      ];
    } else if(parts.length === 7) {
      // パターン 3・4：最後から3番目がR/Lのどちらか、かつ、各情報の個数が全部で7つ

      [id, date, time, shootingType, direction, typeDetail, number] = [
        parts[0],
        parts[1],
        parts[2],
        parts[parts.length - 4],
        parts[parts.length - 3],
        parts[parts.length - 2],
        parts[parts.length - 1],
      ];
    }

    const md5Hash = await this.getMD5(file);
    const own = await this.auth0Service.getOwnUserInfo();
    const name = `${id}_${date}`;
    const type = this.parseImageType(shootingType);

    let meta : FundusStudyImageMeta = {
      id,
      name,
      direction: direction.toUpperCase(),
      time,
      number: number.toString(),
      mimeType: file.type,
      md5Hash,
      userId: own?.sub || '',
      organizationId: own ? own['org_id'] : '',
      date: `${date}${time}`,
      type: type,
      shootingType,
      typeDetail
    };

    if(tags.length > 0) {
      meta.tags = encodeURIComponent(tags.join(","));
    }

    return meta;
  }

  /**
   * ファイルから眼底検査画像のメタを取得する
   * @param file ファイル
   * @param tags タグ
   * @returns 眼底検査画像のメタ
   */
  private async getMeta(file: File, tags: string[] = []) {
    const [_, ext] = file.name.toLowerCase().split('.');

    if(ext === 'jpeg' || ext === 'jpg' || ext === 'png') {
      return await this.getMetaFromJpeg(file, tags);

    } else {
      return await this.getMetaFromDicom(file, tags);
    }
  }

  /**
   * ファイルから MD5 を取得する
   * @param file ファイル
   * @returns MD5
   */
  private async getMD5(file: File) {
    return md5(new Uint8Array(await file.arrayBuffer()));
  }

  /**
   * ファイルがすべて JPEG かチェックする
   * @param files 複数のファイル
   * @returns すべて JPEG の場合 true
   */
  private async checkAllJPG(files: File[]) {
    const isJPGList = await Promise.all(
      files.map((file) => this.checkJPG(file))
    );
    return isJPGList.every((isJPG) => isJPG);
  }

  /**
   * ファイルが JPEG かチェックする
   * @param file ファイル
   * @returns JPEG の場合 true
   */
  private async checkJPG(file: File) {
    const fileType = await fromBlob(file);
    return fileType ? fileType.ext === 'jpg' : false;
  }

  /**
   * MetaAndFileがグループとインデックスによるソート
   * @param metaAndFiles MetaAndFile[]
   * @returns MetaAndFile[]
   */
  private groupAndSortByIndex(metaAndFiles: MetaAndFile[]) : MetaAndFile[] {
    let studyIndexGroups: string[] = [];
    let octIndexGroups: string[] = [];

    return metaAndFiles.sort((a, b) => {
      const valueA = a.meta.typeDetail + '_' + a.meta.number;
      const valueB = b.meta.typeDetail + '_' + b.meta.number;
      return valueA.localeCompare(valueB);
    }).map((metaAndFile) => {
      let group : string;
      if(metaAndFile.meta.typeDetail) {
        group = metaAndFile.meta.shootingType + metaAndFile.meta.typeDetail + metaAndFile.meta.number;
      } else {
        group = metaAndFile.meta.shootingType + metaAndFile.meta.number;
      }

      let newIndex = 0;
      if(metaAndFile.meta.type === 'oct') {
        if(octIndexGroups.includes(group)) newIndex = octIndexGroups.indexOf(group) + 1;
        else {
          newIndex = octIndexGroups.length + 1;
          octIndexGroups.push(group);
        }
      } else if(metaAndFile.meta.type === 'fundus') {
        if(studyIndexGroups.includes(group)) newIndex = studyIndexGroups.indexOf(group) + 1;
        else {
          newIndex = studyIndexGroups.length + 1;
          studyIndexGroups.push(group);
        }
      }

      if(newIndex) metaAndFile.meta.number = newIndex.toString();
      return metaAndFile;
    });
  }
}
