import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-outlined-button',
  templateUrl: './outlined-button.component.html',
  styleUrls: ['./outlined-button.component.scss'],
})
export class OutlinedButtonComponent {
  @Input() color!: 'primary' | 'success' | 'basic';
  @Input() width? = '100px';
  @Input() height? = '40px';
  @Input() disabled!: boolean;
  @Output() clicked = new EventEmitter<void>();
}
