
<input class="form-control"
       type="file"
       multiple
       hidden
       #uploadEl
       (change)="onFileSelect($event)"
       accept="image/jpeg,.csv"
>
<button
  class="import-button"
  mat-raised-button
  color="primary"
  [style]="buttonStyle"
  (click)="uploadEl.click()">
  <ng-content></ng-content>
</button>
