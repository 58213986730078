import {Component, OnInit} from '@angular/core';
import {Auth0Service, Auth0StoreService} from "@app/core/services";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {SnackBarComponent} from "@app/shared/components";
import {Router} from '@angular/router';

@Component({
  selector: 'app-display-setting',
  templateUrl: './display-setting.component.html',
  styleUrls: ['./display-setting.component.scss']
})
export class DisplaySettingComponent implements OnInit {
  constructor(
    private auth0Service: Auth0Service,
    private matSnackBar: MatSnackBar,
    private auth0StoreService: Auth0StoreService,
    private router: Router,
  ) { }

  defaultPageSize = '300';
  lastSavedValue = this.defaultPageSize;
  loaded = false;

  async ngOnInit() {
    this.loaded = false;
    const currentSettings = await this.auth0Service.getUserSettings();
    if (currentSettings.defaultPageSize) {
      this.defaultPageSize = `${currentSettings.defaultPageSize}`;
    }
    this.lastSavedValue = this.defaultPageSize;
    this.loaded = true;
  }

  async submit() {
    await this.auth0Service.saveUserSettings({
      defaultPageSize: Number.parseInt(this.defaultPageSize),
    });

    this.matSnackBar.openFromComponent(SnackBarComponent, {
      data: {
        success: true,
        message: '検査の表示件数を保存しました',
      },
      duration: 3 * 1000,
    });

    localStorage.removeItem('fundusApiCache');
    this.lastSavedValue = this.defaultPageSize;
    await this.auth0StoreService.fetchCurrentUserMeta();

    // 設定後の件数で検査一覧をリセット
    await this.router.navigate([], {
      queryParams: {
        offset: 0,
        limit: Number.parseInt(this.defaultPageSize),
      },
      queryParamsHandling: 'merge',
    });
  }
}
