import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Auth0Service } from '@app/core/services';
import { Invitation, Member, Organization, Role, User as UserData } from '@app/core/models';
import {User} from "@auth0/auth0-angular";

@Injectable({
  providedIn: 'root',
})
export class Auth0StoreService {
  organizationsSubject = new BehaviorSubject<Organization[] | null>(null);
  membersSubject = new BehaviorSubject<(Member & { role: Role })[] | null>(
    null
  );
  ownUserSubject = new BehaviorSubject<User | null>(
    null
  );
  invitationsSubject = new BehaviorSubject<Invitation[] | null>(null);
  currentUserRoleSubject = new BehaviorSubject<string | undefined>(undefined);
  currentUserDataSubject = new BehaviorSubject<UserData | null>(null);
  selectedOrganizationIdSubject = new BehaviorSubject<string | null>(null);

  /**
   * 施設一覧を取得する
   * @returns 施設一覧
   */
  get organizations() {
    return this.organizationsSubject.getValue();
  }

  /**
   * メンバー一覧を取得する
   * @returns メンバー一覧
   */
  get members() {
    return this.membersSubject.getValue();
  }

  /**
   * ログインしているユーザーを取得する
   * @returns ユーザー
   */
  get ownUser() {
    return this.ownUserSubject.getValue();
  }

  /**
   * 施設一覧の Observable を取得する
   * @returns 施設一覧
   */
  get organizations$() {
    return this.organizationsSubject.asObservable();
  }

  /**
   * メンバー一覧の Observable を取得する
   * @returns メンバー一覧
   */
  get members$() {
    return this.membersSubject.asObservable();
  }

  /**
   * 招待一覧の Observable を取得する
   * @returns 招待一覧
   */
  get invitations$() {
    return this.invitationsSubject.asObservable();
  }

  /**
   * 選択した施設の Observable を取得する
   * @returns string
   */
  get selectedOrganizationId$() {
    return this.selectedOrganizationIdSubject.asObservable();
  }

  /**
   * 選択した施設を取得する
   * @returns string
   */
  get selectedOrganizationId() {
    return this.selectedOrganizationIdSubject.getValue();
  }



  /**
   * 現ログインユーザのロールの Observable を取得する
   * @returns string
   */
  get currentUserRole$() {
    return this.currentUserRoleSubject.asObservable();
  }

  /**
   * 現ログインユーザの情報の Observable を取得する
   * @returns 現ログインユーザの情報
   */
  get currentUserData$() {
    return this.currentUserDataSubject.asObservable();
  }

  /**
   * 現ログインユーザのロールを取得する
   * @returns string
   */
  get currentUserRole() {
    return this.currentUserRoleSubject.getValue();
  }

  constructor(private auth0Service: Auth0Service) {}

  /**
   * メンバー一覧をフェッチする
   */
  clear() {
    this.membersSubject.next(null);
    this.invitationsSubject.next(null);
  }

  /**
   * 所属施設をフェッチする
   */
  async fetchBelongedOrganizations() {
    const response = await this.auth0Service.getBelongedOrganizations();
    this.organizationsSubject.next(response);
  }
  /**
   * 現ログインユーザをフェッチする
   */
  async fetchCurrentUser() {
    const currentUser = await this.auth0Service.getOwnUserInfo();

    try {
      const belongedOrganizations = await this.auth0Service.getBelongedOrganizations();
      this.organizationsSubject.next(belongedOrganizations);
    } catch (error) {
      console.error("Cannot fetch belonged organizations", error);
    }

    this.ownUserSubject.next(currentUser);
  }

  async fetchCurrentUserMeta() {
    let currentUser = await this.ownUserSubject.getValue();
    if(!currentUser) {
      currentUser = await this.auth0Service.getOwnUserInfo();
    }

    try {
      const userData = await this.auth0Service.getUser(currentUser?.sub!);
      this.currentUserDataSubject.next(userData);
    } catch (error) {
      console.error("Cannot fetch user meta", error);
    }

    try {
      const members = await this.auth0Service.getMembersWithRole();
      const currentMemberInfo = members?.find(member => {
        return currentUser?.sub === member.user_id;
      });

      this.currentUserRoleSubject.next(currentMemberInfo?.role.name);
    } catch (error) {
      console.error("Cannot fetch role", error);
    }

  }

  /**
   * メンバー一覧をフェッチする
   */
  async fetchMembers() {
    const response = await this.auth0Service.getMembersWithRole();
    this.membersSubject.next(response);
  }

  /**
   * メンバー一覧をフェッチする
   */
  async fetchInvitations() {
    const response = await this.auth0Service.getInvitations();
    this.invitationsSubject.next(response);
  }

  /**
   * 選択した施設を変更する
   */
  async changeSelectedOrganization(options: { organization: string }) {
    this.auth0Service.changeSelectedOrganization(options);
    this.selectedOrganizationIdSubject.next(options.organization);
  }

  /**
   * 選択した施設を設定する
   */
  async setSelectedOrganization(options: { organization: string }) {
    this.selectedOrganizationIdSubject.next(options.organization);
  }
}
