<div class="app-dropdown"
     [style.width]="width ? width + 'px' : '100%'"

>
  <div fxLayout="row" fxLayoutGap="10px">
    <div [matMenuTriggerFor]="menu"
         [style.height]="height ? height + 'px' : '40px'"
         [ngClass]="{ 'disabled' : disabled }" class="box" [style.opacity]="this.disabled ? 0.5 : 1">
      <label>
        <span class="placeholder" *ngIf="!value && placeholder">
          {{placeholder}}
        </span>
        <span class="value" *ngIf="value || !placeholder">
          {{valueLabel.length > maxTextLength ? valueLabel.substring(0, maxTextLength-5) + '...' : valueLabel}}
        </span>
      </label>
      <mat-icon fontSet="material-icons-outlined">
        expand_more
      </mat-icon>
    </div>
    <mat-menu #menu="matMenu" class="app-dropdown-panel" >
      <button mat-menu-item (click)="setValue('')" class="unselected" *ngIf="!required" [style.width.px]="dropdownWidth || 526">
        <div fxLayout="row" fxLayoutGap="10px;">
          <span class="left-icon">
          </span>
          <span>
            選択しない
          </span>
        </div>
      </button>
      <button mat-menu-item *ngFor="let option of _options" (click)="setValue(option.value)" [style.width.px]="dropdownWidth || 526">
        <div fxLayout="row" fxLayoutGap="10px;"  >
          <span class="left-icon" fxFlexAlign="center">
            <mat-icon class="check" fontSet="material-icons-outlined" *ngIf="option.value === value">
              check
            </mat-icon>
          </span>
          <span>
            {{option.label}}
          </span>
        </div>

      </button>
    </mat-menu>
  </div>

</div>
