import { Injectable } from '@angular/core';

import { DiagnosticReport, FundusStudy } from '@app/diagnosis/models';

@Injectable({
  providedIn: 'root',
})
export class SelectedFundusStudiesService {
  private selectedFundusStudies: FundusStudy[] = [];

  currentIndex: number = 0;

  /**
   * 選択した眼底検査の数を取得する
   * @returns 眼底検査の数
   */
  get length() {
    return this.selectedFundusStudies.length;
  }

  /**
   * 診断中の眼底検査を取得する
   * @returns 眼底検査
   */
  get current() {
    return this.selectedFundusStudies[this.currentIndex];
  }

  /**
   * 次の眼底検査を取得する
   * @returns 眼底検査
   */
  get next() {
    return this.selectedFundusStudies[this.currentIndex + 1];
  }

  /**
   * 前の眼底検査を取得する
   * @returns 眼底検査
   */
  get prev() {
    return this.selectedFundusStudies[this.currentIndex - 1];
  }

  /**
   * 最後の診断かどうか取得する
   * @returns 最後の診断かどうか
   */
  get last() {
    return this.currentIndex === this.length - 1;
  }

  /**
   * すべての診断を完了したかどうか取得する
   * @returns すべての診断を完了したかどうか
   */
  get completed() {
    return this.diagnosedNum === this.length;
  }

  /**
   * 診断を完了した数を取得する
   * @returns 診断を完了した数
   */
  get diagnosedNum() {
    return this.selectedFundusStudies.filter(
      (fundusStudy) => fundusStudy.diagnosticReport
    ).length;
  }

  /**
   * 診断をしていない数を取得する
   * @returns 診断をしていない数
   */
  get undiagnosedNum() {
    return this.selectedFundusStudies.filter(
      (fundusStudy) => !fundusStudy.diagnosticReport
    ).length;
  }

  /**
   * カレントインデックスをインクリメントする
   */
  nextIndex() {
    this.currentIndex < this.length && this.currentIndex++;
  }

  /**
   * カレントインデックスをデクリメントする
   */
  prevIndex() {
    this.currentIndex > 0 && this.currentIndex--;
  }

  /**
   * セッションストレージからの読み込み
   */
  loadFromSessionStorage() {
    const savedData = sessionStorage.getItem('selectedFundusStudies');
    if (savedData) {
      this.selectedFundusStudies = JSON.parse(savedData);
    }
  }

  /**
   * セッションストレージに保存する
   */
  saveSelectionToStorage() {
    sessionStorage.setItem(
      'selectedFundusStudies',
      JSON.stringify(this.selectedFundusStudies)
    );
  }

  /**
   * セッションストレージをクリアする
   */
  clearSessionStorage() {
    sessionStorage.removeItem('selectedFundusStudies');
  }

  /**
   * カレントインデックスを再設定する
   * @param studyId
   * @returns 再設定に成功したかどうか
   */
  resetIndexByStudyId(studyId: string) {
    const setIndex = this.selectedFundusStudies.findIndex(
      (fundusStudy) => fundusStudy.id === studyId
    );

    if (setIndex >= 0) {
      this.currentIndex = setIndex;
    }
    return setIndex >= 0;
  }

  /**
   * 眼底検査を設定する
   * @param fundusStudy 眼底検査
   */
  setFundusStudy(fundusStudy: FundusStudy) {
    this.selectedFundusStudies[this.currentIndex] = fundusStudy;
  }

  /**
   * 眼底検査を設定する
   * @param fundusStudies 眼底検査
   */
  setFundusStudies(fundusStudies: FundusStudy[]) {
    this.currentIndex = 0;
    this.selectedFundusStudies = [...fundusStudies];
  }

  /**
   * 診断レポートを設定する
   * @param diagnosticReport 診断レポート
   */
  setDiagnosticReport(diagnosticReport: DiagnosticReport) {
    this.selectedFundusStudies[this.currentIndex].diagnosticReport =
      diagnosticReport;
  }
}
