<button
  class="button"
  type="button"
  [matMenuTriggerFor]="menu"
  *ngIf="!readonly"
>
  <div fxLayout="row" fxLayoutAlign="center center">
    <span>{{ _options[value] }}</span>
    <mat-icon>expand_more</mat-icon>
  </div>
</button>

<div
  class="label"
  fxLayout="row"
  fxLayoutAlign="center center"
  [style.color]="hideDropdownIcon ? 'inherit' : '#757575'"
  *ngIf="readonly"
>
  <span>{{ _options[value] }}</span>
  <mat-icon *ngIf="!hideDropdownIcon">expand_more</mat-icon>
</div>

<mat-menu #menu="matMenu">
  <button
    type="button"
    mat-menu-item
    (click)="change(option.key)"
    *ngFor="let option of _options | keyvalue"
  >
    {{ option.value }}
  </button>
</mat-menu>
