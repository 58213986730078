import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-outlined-input',
  templateUrl: './outlined-input.component.html',
  styleUrls: ['./outlined-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OutlinedInputComponent),
      multi: true,
    },
  ],
})
export class OutlinedInputComponent implements ControlValueAccessor {
  value = '';
  onChange!: Function;
  onTouched!: Function;

  change(value: string) {
    this.value = value;
    this.onChange(value);
  }

  writeValue(obj: any) {
    this.value = obj;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
