import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

import {
  OrganizationInfoSettingComponent,
  OrganizationMembersSettingComponent,
  OrganizationIntegrationSettingComponent,
} from '@app/core/components';
import {TemplatesApiService} from "@app/core/services/templates-api.service";
import {Template} from "@app/core/models/template.model";
import {ConfirmDialogComponent, DialogData} from "@app/shared/components/confirm-dialog/confirm-dialog.component";
import {User} from "@auth0/auth0-angular";
import {Auth0Service, Auth0StoreService} from "@app/core/services";
import {Subject, takeUntil} from "rxjs";
interface TemplateListItem {
  id: number;
  templateId: string,
  name: string;
  isDefault: boolean;
}
@Component({
  selector: 'app-setting-dialog',
  templateUrl: './setting-dialog.component.html',
  styleUrls: ['./setting-dialog.component.scss'],
})

export class SettingDialogComponent implements OnInit,OnDestroy {
  kind: string = 'user';
  selectedTemplateName: string | null = null;
  selectedMenuItem = 'user:user';
  templateList : TemplateListItem[]  = [];
  templates : Template[] = []
  templateIndex = 0;
  hasChanges = false;
  currentUserInfo!: User;
  userRole!: string;
  private readonly destroy$ = new Subject<void>();

  navList = [
    {
      key: 'facility:info',
      name: '施設情報',
      component: OrganizationInfoSettingComponent,
    },
    {
      key: 'facility:members',
      name: 'メンバー',
      component: OrganizationMembersSettingComponent,
    },
    {
      key: 'facility:setting',
      name: '施設連携',
      component: OrganizationIntegrationSettingComponent,
    }
  ];

  get selectedTemplate() {
    const selectedTemplateItem = this.templateList.find(
      (template) => template.name.replace(':','') === this.selectedTemplateName
    );

    return this.templates.find(
      (template) => template.id === selectedTemplateItem?.templateId
    );
  }

  get selectedFacilityComponent() {
    return this.navList.find(item => item.key === this.selectedMenuItem)?.component;
  }

  constructor(
    public dialogRef: MatDialogRef<SettingDialogComponent>,
    private templateAPIService: TemplatesApiService,
    private dialog: MatDialog,
    private auth0Service: Auth0Service,
    private auth0StoreService: Auth0StoreService,
  ) {}

  async ngOnInit() {
    localStorage.removeItem('fundusApiCache');
    await this.fetchTemplates();
    this.dialogRef.disableClose = true;

    this.dialogRef.backdropClick().subscribe(
      () => {
        this.handleCloseDialog();
      }
    )

    this.auth0StoreService.ownUserSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        if(user) {
          this.currentUserInfo = user;
        }
      });

    this.auth0StoreService.currentUserRoleSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((role) => {
        this.userRole = role ?? '';
      });
  }

  async fetchTemplates() {
    try {
      const templateFetch = await this.templateAPIService.getTemplates();
      this.templates = templateFetch.templates;
      this.templateList = [];

      for(let template of this.templates) {
        this.templateList.push({
          id: this.templateIndex++,
          templateId: template.id || '',
          name: template.name,
          isDefault: template.isDefaultTemplate,
        });
      }
    } catch (e) {
      alert('サーバーエラー');
    }
  }
  handleSelected($event: string) {
    const [group, item] = $event.split(':');

    if(group === 'template') {
      this.kind = 'template';
      this.selectedTemplateName = item;

    } else if(group ==='facility') {
      this.kind = 'facility';
      this.selectedMenuItem = $event;
    } else {
      this.kind = group;
      this.selectedMenuItem = $event;
    }
  }

  handleCloseDialog(action : string = 'close') {
    if(this.hasChanges) {
      const data: DialogData = {
        title: '編集を中止しますか？',
        content: "入力内容は破棄されます。\nこの操作は元に戻せません。",
        actions: ['キャンセル', '中止'],
        color: 'warn',
      };
      this.dialog
        .open(ConfirmDialogComponent, {
          autoFocus: false,
          data,
        })
        .afterClosed()
        .subscribe(async (result) => {
          if (!result) {
            return;
          }

          if(action == 'close') {
            this.dialogRef.close();
          } else if(action == 'goToDefaultTemplate') {
            const defaultTemplate = this.templateList.find(
              (templateListItem) => {
                if(templateListItem.isDefault) {
                  return templateListItem;
                } else {
                  return null;
                }
              }
            );

            if(defaultTemplate) this.selectedTemplateName = defaultTemplate.name;
          }
        });
    } else {
      this.dialogRef.close();
    }

    localStorage.removeItem('fundusApiCache');
  }

  addTemplate() {
    this.kind = 'template';
    this.selectedTemplateName = null;
  }

  onTemplateChanged(templateHasChanges: boolean) {
    this.hasChanges = templateHasChanges;
  }

  async onTemplateSaved($event: Template) {
    await this.fetchTemplates();
    this.selectedMenuItem = 'template:' + $event.name.replace(':','');
    this.selectedTemplateName = $event.name.replace(':','');
  }

  async onTemplateDeleted($event: Template) {
    await this.fetchTemplates();
    const defaultTemplate = this.templateList.find((t) => t.isDefault);
    if (defaultTemplate) {
      this.selectedMenuItem = `template:${defaultTemplate.name}`;
      this.selectedTemplateName = defaultTemplate.name;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
