import { SelectionModel } from '@angular/cdk/collections';

export class SelectionModelOverride<
  T extends { id: string }
> extends SelectionModel<T> {
  override isSelected(row: T) {
    return !!this.selected.find((value) => value.id === row.id);
  }

  isAllSelected(rows: T[]) {
    return !rows.some((row) => !this.isSelected(row));
  }

  masterToggle(rows: T[]) {
    if (!this.isAllSelected(rows)) {
      this.select(...rows);
    } else {
      this.clear();
    }
  }
}
