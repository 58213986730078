import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { PatientsApiService } from '@app/diagnosis/services';
import { Patient } from '@app/diagnosis/models';

@Injectable({
  providedIn: 'root',
})
export class PatientsService {
  constructor(private patientsApiService: PatientsApiService) {}

  /**
   * 患者情報を更新する
   * @param id 眼底検査 ID
   * @param patient 患者情報
   * @returns 患者情報
   */
  async updatePatient(id: string, patient: Patient) {
    return firstValueFrom(this.patientsApiService.updatePatient(id, patient));
  }
}
