import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-setting-sidenav',
  templateUrl: './setting-sidenav.component.html',
  styleUrls: ['./setting-sidenav.component.scss'],
})
export class SettingSidenavComponent {
  @Input() templateList!: { id: number; name: string; isDefault: boolean }[];
  @Input() navList!: { name: string, key: string }[];
  @Input() isNewTemplate = false;
  @Output() selectedSetting = new EventEmitter<string>();
  @Output() addTemplate = new EventEmitter<void>();
  @Input() userRole?: string;
  openTemplate = false;
  openSetting = false;
  @Input() selectedMenuItem : string = 'user:user';

  get userCanAddTemplate(): boolean {
    return this.userRole == 'Admin';
  }

  navItemSelect(navKey: string) {
    this.selectedSetting.emit(navKey);
    this.selectedMenuItem = navKey;
  }

  addClick($event: Event) {
    this.addTemplate.emit();
    this.selectedMenuItem = '';
    $event.stopPropagation();
  }
}
