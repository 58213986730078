<ng-container *ngIf="!loaded; else select">
  <ngx-skeleton-loader
    [theme]="{
      height: '20px',
      'border-radius': '10px'
    }"
  ></ngx-skeleton-loader>
</ng-container>
<ng-template #select>
  <div class="app-select"
      [style.width]="width"
       *ngIf="!textonly"
       [class.error]="errorMessage"
    >
    <select
      autocomplete="off"
      [disabled]="disabled || !!readonly"
      [style.height]="height"
      [ngModel]="value"
      (ngModelChange)="setValue($event)"
      [class.placeholder]="!value"
      [class.readonly]="readonly"

      >
      <option value="" disabled selected *ngIf="allowEmpty">{{ placeholder }}</option>
      <option [value]="option.value" [selected]="option.value === value" *ngFor="let option of _options">
        {{ option.label }}
      </option>
    </select>
  </div>
  <div *ngIf="textonly" class="text-only-version">
    {{currentValueLabel}}
  </div>
  <div class="error-message" *ngIf="errorMessage && !textonly">
    {{errorMessage}}
  </div>
</ng-template>
