import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-collapsible-content',
  templateUrl: './collapsible-content.component.html',
  styleUrls: ['./collapsible-content.component.scss']
})
export class CollapsibleContentComponent {
  @Input() heading: string = '';
  @Input() collapsed = false;
  constructor() { }
}
