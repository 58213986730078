<div class="container" (mouseenter)="tooltip.show()" #container>
  <div
    class="tooltipHolder"
    matTooltipClass="tooltip"
    [matTooltip]="tooltipMessage"
    matTooltipPosition="above"
    #tooltip="matTooltip"
  >
  </div>
  <div class="content" >
    <ng-content></ng-content>
  </div>

</div>
