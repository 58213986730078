import { Component, OnInit, OnDestroy } from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import { AuthService, User } from '@auth0/auth0-angular';
import { Subscription } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import {
  Auth0StoreService,
  CollaboratorsApiService,
  ProgressService,
} from '@app/core/services';
import {
  Collaborators,
  Collaborator,
} from '@app/core/services/collaborators-api.service';
import {
  ConfirmDialogComponent,
  DialogData,
} from '@app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-organization-integration-setting',
  templateUrl: './organization-integration-setting.component.html',
  styleUrls: ['./organization-integration-setting.component.scss'],
})
export class OrganizationIntegrationSettingComponent
  implements OnInit, OnDestroy
{
  onetimekey! : UntypedFormControl;

  collaborators!: Collaborators;

  user!: User | null;

  userRole!: string | undefined;

  error = false;

  hoverdCollaboratorId?: string;

  userSubscription!: Subscription;

  userRoleSubscription!: Subscription;

  get organizations() {
    return this.auth0StoreService.organizations;
  }

  get organization() {
    return this.organizations?.find(
      (organization) => organization.id === this.user?.['org_id']
    );
  }

  constructor(
    private fb: UntypedFormBuilder,
    private collaboratorsApiService: CollaboratorsApiService,
    private auth0StoreService: Auth0StoreService,
    public auth: AuthService,
    private progressService: ProgressService,
    private dialog: MatDialog
  ) {}

  async ngOnInit() {
    this.onetimekey = this.fb.control('', [Validators.required]);
    this.collaborators = await this.collaboratorsApiService.getCollaborators();
    this.userSubscription = this.auth.user$.subscribe((user) => {
      this.user = user || null;
    });

    this.userRoleSubscription = this.auth0StoreService.currentUserRoleSubject.subscribe((role) => {
      this.userRole = role;
    });
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    this.userRoleSubscription.unsubscribe();
  }

  async submit() {
    this.progressService.startProgress();

    try {
      await this.collaboratorsApiService.createCollaborator({
        key: this.onetimekey.value,
        isPreview: false,
      });
      localStorage.removeItem('fundusApiCache');
      this.collaborators =
        await this.collaboratorsApiService.getCollaborators();
      this.onetimekey.setValue(null);
      this.error = false;
    } catch (error) {
      this.error = true;
    }

    this.progressService.endProgress();
  }

  async deleteCollaborator(collaborator: Collaborator) {
    const data: DialogData = {
      title: '施設の連携を削除しますか？',
      content: `すでに共有している検査がある場合、${collaborator.name}はその検査にアクセスできなくなります。`,
      actions: ['キャンセル', '削除'],
      color: 'warn',
    };
    this.dialog
      .open(ConfirmDialogComponent, {
        autoFocus: false,
        data,
      })
      .afterClosed()
      .subscribe(async (result) => {
        if (!result) {
          return;
        }
        try {
          await this.collaboratorsApiService.deleteCollaborator(
            collaborator.id
          );
          localStorage.removeItem('fundusApiCache');
          this.collaborators =
            await this.collaboratorsApiService.getCollaborators();
        } catch (error) {
          alert('サーバーエラー');
        }
      });
  }
}
