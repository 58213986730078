import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  @Input() value!: string;
  @Input() options!: string[] | {[p: string]: string};
  @Input() readonly = false;
  @Output() changed = new EventEmitter<string>();
  @Input() hideDropdownIcon = false;

  change(value: string) {
    this.value = value;
    this.changed.emit(value);
  }

  get _options() {
    let options: {[p: string]: string} & Iterable<string> = {
      [Symbol.iterator]: function* () {
        for (let key of Object.keys(this)) {
          yield this[key];
        }
      }
    };

    if(this.options instanceof Array) {
      this.options.forEach(option => {
        options[option] = option;
      });
    } else {
      for(let optionEntries of Object.entries(this.options)) {
        options[optionEntries[0]] = optionEntries[1];
      }
    }

    return options;
  }
}
