<mat-checkbox
  [ngModel]="value"
  (ngModelChange)="onChange($event)"
  [disabled]="!!disabled"
  [disableRipple]="!!disabled || readonly"
  (click)="handleClick($event)"
  [ngClass]="{readonly: readonly}"
>
{{label}}
</mat-checkbox>
