import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RevisionService {
  private revisionUrl = '/revision.json';

  constructor(private http: HttpClient) { }

  getRevision(): Observable<any> {
    const url = `${this.revisionUrl}?t=${Date.now()}`;
    return this.http.get<any>(url);
  }
}
