<div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="8px">
  <mat-icon class="material-icons-outlined check-circle" *ngIf="data?.success"
    >check_circle</mat-icon
  >
  <mat-icon class="material-icons-outlined error" *ngIf="data?.failure"
    >error</mat-icon
  >
  <span>{{ data.message }}</span>
  <span fxFlex></span>
  <mat-icon class="close" (click)="snackBarRef.closeWithAction()"
    >close</mat-icon
  >
</div>
