import { Injectable } from '@angular/core';
import { FundusStudiesService } from '@app/diagnosis/services/fundus-studies.service';
import { FundusStudiesResponse, Patient } from '@app/diagnosis/models';
import { PatientsService } from '@app/diagnosis/services/patients.service';

interface PatientData {
  study: string;
  birthday: string;
  name: string;
  sex: 'male' | 'female';
  pasts: string[];
  invalid: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PatientCsvUploadService {
  private _errors = {
    patientNotSet: {
      errorMessage:
        '患者情報が設定されない検査があります。\n患者情報が不足している検査：\n',
      studies: [] as string[],
    },
    studyNotFound: {
      errorMessage:
        '記載された患者情報に対応する検査が存在しません。\n不足している検査：\n',
      studies: [] as string[],
    },
    invalidDate: {
      errorMessage:
        '日付の形式はYYYYMMDDでなければなりません。\n患者情報が不正している検査：\n',
      studies: [] as string[],
    },
    invalidSex: {
      errorMessage: '性別が正しくありません。\n患者情報が不正している検査：\n',
      studies: [] as string[],
    },
    limit: {
      errorMessage: 'CSVの行数制限は1000に設定されています：\n',
      studies: [] as string[],
    },
  };

  constructor(
    private fundusStudiesService: FundusStudiesService,
    private patientsService: PatientsService
  ) {}
  async upload(
    files: File[],
    tags: string[] = [],
    studiesWithDicom: Map<string, Patient> = new Map<string, Patient>()
  ) {
    const results = [];
    let patientSet = 0;
    this._errors.patientNotSet.studies = [];
    this._errors.studyNotFound.studies = [];
    this._errors.invalidDate.studies = [];
    this._errors.invalidSex.studies = [];

    for (const file of files) {
      const arrayData = await this.readFile(file);
      results.push(arrayData);
    }

    for (const result of results) {
      for (const patientData of result) {
        if (
          !patientData.sex &&
          !patientData.pasts.length &&
          !patientData.birthday &&
          !patientData.name
        ) {
          this._errors['patientNotSet'].studies.push(patientData.study);
        }

        const fundusStudyList: FundusStudiesResponse =
          await this.fundusStudiesService.searchFundusStudies({
            studyNameExact: patientData.study,
            tags: tags.length > 0 ? tags.join(',') : '',
            limit: 1,
            offset: 0,
          });

        if (
          fundusStudyList?.fundusStudies &&
          fundusStudyList.fundusStudies.length > 0
        ) {
          const fundusStudyId = fundusStudyList.fundusStudies[0].id;

          if (studiesWithDicom.get(fundusStudyId)) {
            const patient = studiesWithDicom.get(fundusStudyId);
            await this.patientsService
              .updatePatient(fundusStudyId, {
                id: patient?.id,
                name: patient?.name,
                sex: patient?.sex,
                birthday: patient?.birthday,
                pasts: patientData.pasts,
              } as Patient)
              .then(() => {
                patientSet++;
              })
              .catch(() => {
                this._errors['patientNotSet'].studies.push(patientData.study);
              });
          } else {
            await this.patientsService
              .updatePatient(fundusStudyId, {
                id: fundusStudyId,
                name: patientData.name,
                sex: patientData.sex,
                birthday: patientData.birthday,
                pasts: patientData.pasts,
              } as Patient)
              .then(() => {
                patientSet++;
              })
              .catch(() => {
                this._errors['patientNotSet'].studies.push(patientData.study);
              });
          }

          await new Promise((resolve) => setTimeout(resolve, 20));
        } else {
          this._errors['studyNotFound'].studies.push(patientData.study);
        }
      }
    }

    return {
      patients: patientSet,
      errors: this._errors,
    };
  }

  readFile(file: File, allowErrors = false): Promise<PatientData[]> {
    try {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        const performReading = (encoding = 'shift-jis') => {
          reader.readAsText(file, encoding);

          reader.onload = () => {
            let csvData = reader.result as string;
            if (
              encoding === 'shift-jis' &&
              csvData.indexOf('男性') === -1 &&
              csvData.indexOf('女性') === -1
            ) {
              performReading('utf-8');
            } else {
              let arrayData = this.csvToArray(csvData, allowErrors);
              resolve(arrayData);
            }
          };

          reader.onerror = () => {
            reject(new Error('ファイルの読み込みエラー'));
          };
        };

        performReading();
      });
    } catch (err) {
      console.error(err);
      throw new Error('ファイルの読み込みエラー');
    }
  }

  private csvToArray(csvData: string, allowErrors = false): any[] {
    const result: any[] = [];
    const lines = csvData.split(/(\r?\n)/); // Split by line breaks, retaining the line breaks

    let inQuotes = false;
    let currentLine = '';
    const handleCurrentLine = () => {
      if (currentLine.trim() === '') return;

      const regex = /,(?=(?:[^"]*"[^"]*")*[^"]*$)/;
      const currentline = currentLine
        .trim()
        .split(regex)
        .map((cell) => {
          // Remove enclosing double quotes and unescape double quotes within quoted cells
          if (cell.startsWith('"') && cell.endsWith('"')) {
            return cell.slice(1, -1).replace(/""/g, '"').trim();
          }
          return cell;
        }); // Split by commas outside quotes

      if (!currentline[0] || !currentline[1]) {
        this._errors.patientNotSet.studies.push(currentline[0]);
        return;
      }

      const date1 = currentline[1] ? this.isValidDate(currentline[1]) : true;
      const date3 = currentline[3] ? this.isValidDate(currentline[3]) : true;

      let validGender = true;
      if (currentline[2]) {
        validGender = currentline[2] === '男性' || currentline[2] === '女性';
      }

      if (allowErrors || (date1 && date3 && validGender)) {
        const birthdate = currentline[3]
          ? `${currentline[3].slice(0, 4)}-${currentline[3].slice(
              4,
              6
            )}-${currentline[3].slice(6, 8)}`
          : '';
        const pasts: string[] = [];
        for (let j = 4; j < currentline.length; j++) {
          if (currentline[j] && currentline[j].trim() !== '') {
            pasts.push(currentline[j]);
          }
        }

        result.push({
          study: `${currentline[0]}_${currentline[1]}`,
          birthday: birthdate && new Date(birthdate),
          name: '',
          sex: currentline[2],
          pasts: pasts,
          invalid: !(date1 && date3 && validGender),
        });
      } else {
        if (!date1 || !date3)
          this._errors.invalidDate.studies.push(
            `${currentline[0]}_${currentline[1]}`
          );
        else if (!validGender)
          this._errors.invalidSex.studies.push(
            `${currentline[0]}_${currentline[1]}`
          );
      }
    };

    for (let i = 0; i < lines.length; i++) {
      if (lines[i] === '\n' || lines[i] === '\r\n') {
        if (!inQuotes) {
          handleCurrentLine();
          currentLine = '';
        } else {
          currentLine += lines[i]; // Retain the line break if inside quotes
        }
      } else {
        for (let j = 0; j < lines[i].length; j++) {
          const char = lines[i][j];
          if (char === '"') {
            inQuotes = !inQuotes;
          }
          currentLine += char;
        }
      }
    }

    // Handle the last line
    if (currentLine) {
      handleCurrentLine();
    }

    return result;
  }

  private isValidDate(date: string): boolean {
    if (date.length !== 8) {
      return false;
    }

    let year = +date.slice(0, 4);
    let month = +date.slice(4, 6) - 1;
    let day = +date.slice(6, 8);

    let dateObj = new Date(year, month, day);

    // Check if date is in the past
    if (dateObj >= new Date()) {
      return false;
    }

    return !(
      dateObj.getFullYear() !== year ||
      dateObj.getMonth() !== month ||
      dateObj.getDate() !== day
    );
  }
}
