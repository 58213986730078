import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { TemplatesApiService } from '@app/core/services/templates-api.service';
import { Template, Object1 } from '@app/core/models/template.model';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SnackBarComponent } from '@app/shared/components';
import { FundusStudiesFacadeService } from '@app/diagnosis/services';
import { firstValueFrom } from 'rxjs';
import {
  ConfirmDialogComponent,
  DialogData as ConfirmDialogData,
} from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

interface DiagnosisValue {
  assessment?: string;
  code: string;
  classification: string;
  _code?: string;
  _classification?: string;
  new?: boolean;
}

import {
  scheieH,
  scheieS,
  keithWagner,
  wongMitchell,
  modifiedDavis,
  newFukuda,
  overallAssessment,
  scott,
  findings,
} from '@app/diagnosis/constants';
@Component({
  selector: 'app-template-setting',
  templateUrl: './template-setting.component.html',
  styleUrls: ['./template-setting.component.scss'],
})
export class TemplateSettingComponent implements OnInit, OnChanges {
  @Input() set template(template: Template | undefined) {
    this._template = template;
    const initFormValues: { [key: string]: any } = {
      name: template?.name || '',
      scheie: template?.scheieH.required || false,
      keithWagner: template?.keithWagner.required || false,
      wongMitchell: template?.wongMitchell.required || false,
      modifiedDavis: template?.modifiedDavis.required || false,
      newFukuda: template?.newFukuda.required || false,
      scott: template?.scott.required || false,
      findingsText: template?.findings.required || false,
      overallAssessment: template?.overallAssessment.required || false,
      individualAssessment: template?.individualAssessment?.required || false,
      isDefaultTemplate: template?.isDefaultTemplate || false,
    };

    this.initFormValues = initFormValues;

    this.form.reset();
    for (let field in initFormValues) {
      this.form.controls[field].setValue(initFormValues[field]);
    }
  }

  @Input() templateList: { id: number; name: string; isDefault: boolean }[] =
    [];
  @Input() userRole?: string;
  @Output() templateUpdated = new EventEmitter<Template>();
  @Output() templateAdded = new EventEmitter<Template>();
  @Output() templateChanged = new EventEmitter<boolean>();
  @Output() templateDeleted = new EventEmitter<Template>();
  @Output() editCanceled = new EventEmitter();
  _template?: Template;
  initFormValues = {};
  form!: UntypedFormGroup;

  defaultClassifications = [
    {
      field: 'scheieS',
      name: 'Scheie (S)',
      defaultValues: scheieS,
      canAdd: false,
    },
    {
      field: 'scheieH',
      name: 'Scheie (H)',
      defaultValues: scheieH,
      canAdd: false,
    },
    {
      field: 'keithWagner',
      name: 'Keith-Wagner',
      defaultValues: keithWagner,
      canAdd: false,
    },
    {
      field: 'wongMitchell',
      name: 'Wong-Mitchell',
      defaultValues: wongMitchell,
      canAdd: true,
    },
    {
      field: 'modifiedDavis',
      name: '改変Davis',
      defaultValues: modifiedDavis,
      canAdd: true,
    },
    {
      field: 'newFukuda',
      name: '新福田',
      defaultValues: newFukuda,
      canAdd: true,
    },
    { field: 'scott', name: 'Scott', defaultValues: scott, canAdd: true },
    {
      field: 'findings',
      name: 'その他所見',
      defaultValues: findings,
      canAdd: true,
    },
    {
      field: 'overallAssessment',
      name: '総合判定',
      defaultValues: overallAssessment,
      canAdd: true,
    },
    {
      field: 'individualAssessment',
      name: '左右判定',
      defaultValues: overallAssessment,
      canAdd: true,
    },
  ];

  selectedDiagnosisItem = 'scheieS';
  diagnosisValues: { [key: string]: DiagnosisValue[] } = {};
  diagnosisValueErrors: { [key: string]: 'isEmpty' | 'isDuplicate' } = {};
  changedDiagnosisValues = false;
  removedDiagnosisValues = false;

  get name() {
    return this.form.controls['name'] as UntypedFormControl;
  }

  get nameAlreadyExists() {
    if (this._template?.name === this.name.value) {
      return false;
    }
    return this.templateList.some((template) => template.name === this.name.value)
  }

  get currentSelectedDiagnosisItem(): string {
    if (this.showDiagnosisItem(this.selectedDiagnosisItem)) {
      return this.selectedDiagnosisItem;
    } else if (this.hasCheckedOne) {
      for (let diagnosisItem of this.defaultClassifications) {
        if (this.showDiagnosisItem(diagnosisItem.field)) {
          return diagnosisItem.field;
        }
      }
    }

    return '';
  }

  get isReadOnly(): boolean {
    return this.userRole !== 'Admin';
  }

  get hasCheckedOne(): boolean {
    return (
      this.form.value.scheie ||
      this.form.value.keithWagner ||
      this.form.value.wongMitchell ||
      this.form.value.modifiedDavis ||
      this.form.value.newFukuda ||
      this.form.value.scott ||
      this.form.value.findingsText ||
      this.form.value.overallAssessment ||
      this.form.value.individualAssessment
    );
  }

  get diagnosisItemsWithErrors(): string[] {
    let diagnosisItemsWithErrors: string[] = [];

    for (let field in this.diagnosisValues) {
      for (let diagnosisValue in this.diagnosisValueErrors) {
        if (diagnosisValue.includes(field)) {
          diagnosisItemsWithErrors.push(field);
        }
      }
    }

    return diagnosisItemsWithErrors;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private templateApiService: TemplatesApiService,
    @Inject(MAT_DIALOG_DATA) public data: { organizationId: string },
    private matSnackBar: MatSnackBar,
    private fundusStudiesFacadeService: FundusStudiesFacadeService,
    private dialog: MatDialog,
  ) {
    this.form = this.fb.group({
      name: [''],
      scheie: [''],
      keithWagner: [''],
      wongMitchell: [''],
      modifiedDavis: [''],
      newFukuda: [''],
      scott: [''],
      findingsText: [''],
      overallAssessment: [''],
      individualAssessment: [''],
      isDefaultTemplate: false,
    });

    this.form.valueChanges.subscribe((_) => {
      this.resetUncheckedDiagnosisItems();
      this.templateChanged.emit(this.hasChanges());
    });
  }

  ngOnInit() {
    this.resetDiagnosisValues();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['template']) {
      this.resetDiagnosisValues();
      this.templateChanged.emit(false);
    }
  }

  async resetDiagnosisValues() {
    this.diagnosisValues = {};
    this.diagnosisValueErrors = {};
    this.changedDiagnosisValues = false;
    this.removedDiagnosisValues = false;
    this.selectedDiagnosisItem = 'scheieS';

    this.defaultClassifications.forEach((diagnosisItem) => {
      this.diagnosisValues[diagnosisItem.field] = [];
      diagnosisItem.defaultValues.forEach((defaultValue, index) => {
        let valuesFromTemplate =
          this._template?.[diagnosisItem.field as keyof Template];

        if (valuesFromTemplate) {
          let fieldValues: DiagnosisValue[] = [];
          (valuesFromTemplate as Object1).options.forEach((value) => {
            fieldValues.push({
              classification: value.classification,
              code: value.code,
              assessment: value.assessment,
            });
          });
          this.diagnosisValues[diagnosisItem.field] = fieldValues;
        } else {
          this.diagnosisValues[diagnosisItem.field].push({
            code: (index + 1).toString(),
            classification: defaultValue,
          });
        }
      });
    });

    if (this.individualAssessmentOnly()) {
      this.diagnosisValues['overallAssessment'] = this.diagnosisValues['individualAssessment'];
    } else {
      this.diagnosisValues['individualAssessment'] = this.diagnosisValues['overallAssessment'];
    }
  }
  async saveTemplate() {
    if (this.individualAssessmentOnly()) {
      this.diagnosisValues['overallAssessment'] = this.diagnosisValues['individualAssessment'];
    } else {
      this.diagnosisValues['individualAssessment'] = this.diagnosisValues['overallAssessment'];
    }

    let object1Values: { [key: string]: Object1 } = {};
    Object.entries(this.diagnosisValues).forEach(([key, values]) => {
      object1Values[key] = { required: false, options: [] };
      if (key === 'scheieH' || key === 'scheieS') {
        object1Values[key].required = this.form.value.scheie;
      } else if (key === 'findings') {
        object1Values[key].required = this.form.value.findingsText;
      } else {
        object1Values[key].required = this.form.value[key];
      }

      object1Values[key].options = [];
      values.forEach((value, index) => {
        object1Values[key].options.push({
          id: index + 1,
          assessment: value.assessment ?? '',
          code: value.code.toString().trim(),
          classification: value.classification.trim(),
        });
      });
    });

    const template: Template = {
      organizationId: this.data.organizationId,
      name: this.form.value.name,
      isDefaultTemplate: this.form.value.isDefaultTemplate || false,
      scheieH: object1Values['scheieH'],
      scheieS: object1Values['scheieS'],
      keithWagner: object1Values['keithWagner'],
      wongMitchell: object1Values['wongMitchell'],
      modifiedDavis: object1Values['modifiedDavis'],
      newFukuda: object1Values['newFukuda'],
      scott: object1Values['scott'],
      findings: object1Values['findings'],
      overallAssessment: object1Values['overallAssessment'],
      individualAssessment: object1Values['individualAssessment'],
    };

    if (this._template?.id) template.id = this._template.id.toString();

    try {
      const savedTemplate = await this.templateApiService.upsertTemplate(
        template
      );
      let successMessage = '診断テンプレートを変更しました';
      this.initFormValues = Object.assign({}, this.form.value);

      if (template.id) {
        this.templateUpdated.emit(savedTemplate);
      } else {
        this.templateAdded.emit(savedTemplate);
        successMessage = '診断テンプレートを作成しました';
      }

      await this.fundusStudiesFacadeService.fetchFundusStudies();

      this.matSnackBar.openFromComponent(SnackBarComponent, {
        data: {
          success: true,
          message: successMessage,
        },
        duration: 3 * 1000,
      });
    } catch (e) {
      let errorMessage = '診断テンプレートの作成が失敗しました';
      if (this._template?.id) {
        errorMessage = '診断テンプレートの変更が失敗しました';
      }
      alert(errorMessage);
    }
  }

  hasChanges(): boolean {
    return (
      JSON.stringify(this.form.value) !== JSON.stringify(this.initFormValues) ||
      this.changedDiagnosisValues ||
      this.removedDiagnosisValues
    );
  }

  handleCancel() {
    this.editCanceled.emit();
  }
  userCanUpdate(): boolean {
    return this.userRole == 'Admin';
  }

  isSaveAllowed(): boolean {
    return (
      this.name.value &&
      !this.nameAlreadyExists &&
      this.hasCheckedOne &&
      this.hasChanges() &&
      this.userCanUpdate() &&
      Object.keys(this.diagnosisValueErrors).length == 0
    );
  }

  resetUncheckedDiagnosisItems() {
    let diagnosisValues = JSON.parse(JSON.stringify(this.diagnosisValues));
    let diagnosisValueErrors = JSON.parse(
      JSON.stringify(this.diagnosisValueErrors)
    );

    this.defaultClassifications.forEach((diagnosisItem) => {
      if (!this.showDiagnosisItem(diagnosisItem.field)) {
        diagnosisValues[diagnosisItem.field] = [];

        diagnosisItem.defaultValues.forEach((defaultValue, index) => {
          diagnosisValues[diagnosisItem.field].push({
            code: index + 1,
            classification: defaultValue,
          });
        });

        Object.keys(diagnosisValueErrors).forEach((errorKey) => {
          if (errorKey.includes(diagnosisItem.field)) {
            delete this.diagnosisValueErrors[errorKey];
          }
        });
      }
    });

    if (this.individualAssessmentOnly()) {
      diagnosisValues['overallAssessment'] = diagnosisValues['individualAssessment']
    } else {
      diagnosisValues['individualAssessment'] = diagnosisValues['overallAssessment'];
    }

    this.diagnosisValues = diagnosisValues;
  }

  showDiagnosisItem(diagnosisItem: string) {
    if (diagnosisItem == 'scheieH' || diagnosisItem == 'scheieS') {
      return this.form.value.scheie;
    } else if (diagnosisItem == 'findings') {
      return this.form.value.findingsText;
    } else if (diagnosisItem === 'individualAssessment') {
      return this.individualAssessmentOnly();
    } else {
      return this.form.value[diagnosisItem];
    }
  }

  changeDiagnosisValues(
    $event: Event,
    field: string,
    index: number,
    type: string
  ): void {
    let newVal = ($event.target as HTMLInputElement).value;
    if (type === 'code') {
      if (this.diagnosisValues[field][index]['_code'] === newVal) {
        delete this.diagnosisValues[field][index]['_code'];
      } else if (!this.diagnosisValues[field][index]['_code']) {
        this.diagnosisValues[field][index]['_code'] =
          this.diagnosisValues[field][index].code;
      }

      this.diagnosisValues[field][index]['code'] = newVal;
    } else if (type === 'classification') {
      if (this.diagnosisValues[field][index]['_classification'] === newVal) {
        delete this.diagnosisValues[field][index]['_classification'];
      } else if (!this.diagnosisValues[field][index]['_classification']) {
        this.diagnosisValues[field][index]['_classification'] =
          this.diagnosisValues[field][index].classification;
      }

      this.diagnosisValues[field][index]['classification'] = newVal;
    }

    this.checkDiagnosisValueErrors();
  }

  addDiagnosisValue(field: string, index: number): void {
    let diagnosisValues = JSON.parse(JSON.stringify(this.diagnosisValues));
    diagnosisValues[field].splice(index + 1, 0, {
      code: '',
      classification: '',
      new: true,
    });

    this.diagnosisValues = diagnosisValues;
    this.checkDiagnosisValueErrors();
  }

  removeDiagnosisValue(field: string, index: number): void {
    let diagnosisValues = JSON.parse(JSON.stringify(this.diagnosisValues));
    diagnosisValues[field].splice(index, 1);

    this.diagnosisValues = diagnosisValues;
    this.removedDiagnosisValues = true;
    this.checkDiagnosisValueErrors();
  }

  checkDiagnosisValueErrors(): void {
    this.diagnosisValueErrors = {};
    this.changedDiagnosisValues = false;

    Object.entries(this.diagnosisValues).forEach(([field, values]) => {
      values.forEach((value, index) => {
        if (!value.code) {
          this.diagnosisValueErrors[`${field}:${index}:code`] = 'isEmpty';
        } else if ('_code' in value) {
          const duplicate = values.find((checkValue, checkIndex) => {
            return checkValue.code === value.code && checkIndex !== index;
          });

          if (duplicate) {
            this.diagnosisValueErrors[`${field}:${index}:code`] = 'isDuplicate';
          }
          this.changedDiagnosisValues = true;
        }

        if (!value.classification) {
          this.diagnosisValueErrors[`${field}:${index}:classification`] =
            'isEmpty';
        } else if ('_classification' in value) {
          const duplicate = values.find((checkValue, checkIndex) => {
            return (
              checkValue.classification === value.classification &&
              checkIndex !== index
            );
          });

          if (duplicate) {
            this.diagnosisValueErrors[`${field}:${index}:classification`] =
              'isDuplicate';
          }
          this.changedDiagnosisValues = true;
        }

        if (value.new) {
          this.changedDiagnosisValues = true;
        }
      });
    });

    this.templateChanged.emit(this.hasChanges());
  }

  hasCategoryAsHeader(field: string) {
    return !['overallAssessment', 'individualAssessment', 'findings'].includes(field);
  }

  individualAssessmentOnly(): boolean {
    return this.form.value['individualAssessment'] && !this.form.value['overallAssessment']
  }

  userCanDelete(): boolean {
    return this.userRole === 'Admin';
  }

  isDeleteAllowed(): boolean {
    return (
      !!this._template?.id &&
      !this._template?.isDefaultTemplate &&
      !this._template?.isUsed &&
      this.userCanDelete()
    );
  }

  async deleteTemplate() {
    if (!this._template?.id) {
      return;
    }

    const data: ConfirmDialogData = {
      title: '診断テンプレートを削除しますか？',
      content: 'この操作は元に戻せません',
      actions: ['キャンセル', '削除'],
      color: 'warn',
    };
    const confirm =  await firstValueFrom(this.dialog.open(ConfirmDialogComponent, {
        autoFocus: false,
        data,
      }).afterClosed());
    if (!confirm) {
      return;
    }

    try {
      await this.templateApiService.deleteTemplate(this._template?.id)
      this.templateDeleted.emit(this._template);
      this.matSnackBar.openFromComponent(SnackBarComponent, {
        data: {
          success: true,
          message: '診断テンプレートを削除しました',
        },
        duration: 3 * 1000,
      });
    } catch (e) {
      alert('診断テンプレートの削除に失敗しました');
    }
  }

  protected readonly Object = Object;
}
