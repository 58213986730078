import {
  Component,
  Input,
  forwardRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface OptionData {
  value: string;
  label: string;
}

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
})
export class DropdownComponent implements ControlValueAccessor {
  @Input() set options( options: Array<string | OptionData>) {
    let optionsObj = [];

    for(let option of options) {
      if(typeof option === 'string') {
        optionsObj.push({
          value: option,
          label: option,
        });
      } else {
        optionsObj.push(option);
      }
    }
    this._options = optionsObj;
  }
  @Input() placeholder = '';
  @Input() height! : number;
  @Input() width! : number;
  @Input() dropdownWidth! : number;
  @Input() required : boolean = false;
  @Input() loaded?: boolean;
  @Input() maxTextLength = 40;
  @Output() changed = new EventEmitter<string>();

  value = '';
  disabled = false;
  onChange!: Function;
  OnTouched!: Function;
  _options: OptionData[] = []

  get valueLabel() {
    return this._options.find(option => option.value === this.value)?.label ?? '';
  }

  setValue(value: string) {
    this.value = value;
    this.onChange(value);
    this.changed.emit(value);
  }

  writeValue(obj: any) {
    this.value = obj;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.OnTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
