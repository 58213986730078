<input
  autocomplete="off"
  [placeholder]="placeholder"
  [type]="type"
  [min]="min"
  [style.width]="width"
  [style.height]="height"
  [ngModel]="value"
  (ngModelChange)="onChange($event)"
  [disabled]="!!disabled"
  [max]="max"
/>
