import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { FundusStudyImageSignedURLRequest } from '@app/diagnosis/models';
import { environment } from '@environments/environment';
import {map} from "rxjs/operators";

/**
 * 眼底検査画像 API の I/F が定義されたクラス
 */
@Injectable({
  providedIn: 'root',
})
export class FundusStudyImagesApiService {
  apiPath = `${environment.apiUrl}/signed-url/fundus-study-images`;

  constructor(private http: HttpClient) {}

  /**
   * 眼底検査画像をダウンロードする URL を発行する
   * @param id 眼底検査画像 ID
   * @returns 署名付き URL
   */
  getDownloadUrl(id: string) {
    return this.http.get<{ signedUrl: string }>(`${this.apiPath}/${id}`);
  }

  /**
   * 眼底検査画像をダウンロードする URL を発行する
   * @param fundusStudyImage 眼底検査画像のメタ
   * @returns 署名付き URL
   */
  getUploadUrl(fundusStudyImage: FundusStudyImageSignedURLRequest) {
    return this.http.put<{ signedUrl: string }>(this.apiPath, fundusStudyImage);
  }

  /**
   * 眼底検査画像をダウンロードする
   * @param signedUrl 署名付き URL
   * @returns Blob
   */
  download(signedUrl: string) {
    return this.http.get(signedUrl, { observe: 'response', responseType: 'blob' }).pipe(
      map((response) => {
        return {
          response,
          type: response.headers.get('Content-Type')
        };
      })
    );
  }

  /**
   * 眼底検査画像をアップロードする
   * @param signedUrl 署名付き URL
   * @param file ファイル
   * @param fundusStudyImage 眼底検査画像
   */
  async upload(
    signedUrl: string,
    file: File,
    fundusStudyImage: FundusStudyImageSignedURLRequest
  ) {
    let headers　: {[header: string]: string} = {
      'Content-Type': fundusStudyImage.mimeType,
      'X-Goog-Meta-Study-Name': fundusStudyImage.name,
      'X-Goog-Meta-Direction': fundusStudyImage.direction,
      'X-Goog-Meta-Number': fundusStudyImage.number,
      'X-Goog-Meta-MD5-Hash': fundusStudyImage.md5Hash,
      'X-Goog-Meta-User-ID': fundusStudyImage.userId,
      'X-Goog-Meta-Organization-ID': fundusStudyImage.organizationId,
      'X-Goog-Meta-Study-Date': fundusStudyImage.date,
      'X-Goog-Meta-Type': fundusStudyImage.type,
    };

    if(fundusStudyImage.tags) {
      headers['X-Goog-Meta-Tags'] = fundusStudyImage.tags;
    }

    return this.http.put<void>(signedUrl, file, {
      headers
    });
  }
}
