import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

import { environment } from '@environments/environment';

export interface Share {
  fundusStudyId: string;
  purpose: string;
  result?: string;
}

export interface SharedOrganizationsResponse {
  shares: {
    organizationId: string;
    purpose: string;
  }[]
}

@Injectable({
  providedIn: 'root',
})
export class SharesApiService {
  apiPath = `${environment.apiUrl}`;

  constructor(private http: HttpClient) {}

  getShares(fundusStudyId: string) {
    return firstValueFrom(
      this.http.get<SharedOrganizationsResponse>(`${this.apiPath}/fundus-studies/${fundusStudyId}/shares`)
    );
  }

  upsertShares(id: string, request: Share[]) {
    return firstValueFrom(
      this.http.post<Share[]>(`${this.apiPath}/organizations/${id}/shares`, {
        shares: request,
      })
    );
  }
  removeShares(id: string, request: Share[]) {
    return firstValueFrom(
      this.http.post<Share[]>(`${this.apiPath}/organizations/${id}/shares/delete`, {
        shares: request,
      })
    );
  }

  changeShareRequest(organizationId: string, fundusStudyId: string, status: 'approve' | 'disapprove') {
    return firstValueFrom(
      this.http.put<null>(`${this.apiPath}/organizations/${organizationId}/shares/${fundusStudyId}`, {
        requestResult: status
      })
    );
  }
}
