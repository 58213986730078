import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

import { OrganizationsGuard } from '@app/core/services';

const routes: Routes = [
  { path: '', redirectTo: '/diagnoses', pathMatch: 'full' },
  {
    path: 'organizations',
    loadChildren: () =>
      import('@app/organizations/organizations.module').then(
        (m) => m.OrganizationsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'diagnoses',
    loadChildren: () =>
      import('@app/diagnosis/diagnosis.module').then((m) => m.DiagnosisModule),
    canActivate: [AuthGuard, OrganizationsGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
