import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-import-button',
  templateUrl: './import-button.component.html',
  styleUrls: ['./import-button.component.scss'],
})
export class ImportButtonComponent {
  @Output() import = new EventEmitter<FileList>();
  @ViewChild("uploadEl") uploadEl!: ElementRef;
  @Input() width!: string | undefined;
  @Input() height!: string | undefined;
  @Input() padding!: string | undefined;

  onFileSelect(e: any) {
      const files = e.target.files;
      this.import.emit(files);
      this.uploadEl.nativeElement.value = "";
  }

  get buttonStyle() {
    let css : {[key: string]: string} = {};
    if(this.width) {
      css["width"] = this.width + "px";
    }

    if(this.height) {
      css["height"] = this.height + "px";
    }

    if(this.padding) {
      css["padding"] = this.padding;
    }

    return css;
  }
}
