import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
  FundusStudiesParams,
  FundusStudiesResponse,
  FundusStudy,
  FundusStudiesCount, FundusStudiesSearchFilters, FundusStudyTags,
} from '@app/diagnosis/models';
import { environment } from '@environments/environment';

/**
 * 眼底検査 API の I/F が定義されたクラス
 */
@Injectable({
  providedIn: 'root',
})
export class FundusStudiesApiService {
  apiPath = `${environment.apiUrl}/fundus-studies`;

  constructor(private http: HttpClient) {}

  createFundusStudy(name: string, studyDate: string, tags: string[] = []) {
    return this.http.post<{ id: string }>(this.apiPath, {
      name: name,
      studyDate: studyDate,
      tags: tags,
    });
  }

  /**
   * 眼底検査一覧を取得する
   * @param fundusStudiesParams クエリパラメータ
   * @returns 眼底検査一覧
   */
  getFundusStudyList(fundusStudiesParams: FundusStudiesParams) {
    return this.http.get<FundusStudiesResponse | {}>(this.apiPath, {
      params: { ...fundusStudiesParams },
    });
  }

  /**
   * 眼底検査を取得する
   * @param id 眼底検査 ID
   * @returns 眼底検査
   */
  getFundusStudy(id: string) {
    return this.http.get<FundusStudy>(`${this.apiPath}/${id}`);
  }

  /**
   * 眼底検査を削除する
   * @param id 眼底検査 ID
   * @returns 空のオブジェクト
   */
  deleteFundusStudy(id: string) {
    return this.http.delete<{}>(`${this.apiPath}/${id}`);
  }

  /**
   * 眼底検査数を取得する
   * @returns 眼底検査数
   */
  getFundusStudyCount() {
    return this.http.get<FundusStudiesCount>(`${this.apiPath}/count`);
  }

  /**
   * 眼底検査のテンプレートを変更する
   * @param id 眼底検査 ID
   * @param templateId 眼底検査のテンプレート ID
   * @returns 眼底検査
   */
  changeFundusStudyTemplate(id: string, templateId: string) {
    return this.http.patch<FundusStudy>(`${this.apiPath}/${id}/diagnostic-template`, {
      diagnosticTemplateId: templateId
    });
  }

  /**
   * 眼底検査を検索する
   * @returns 眼底検査一覧
   * @param fundusStudiesSearchParams
   */
  searchFundusStudies(fundusStudiesSearchParams: FundusStudiesSearchFilters) {
    return this.http.get<FundusStudiesResponse | {}>(`${this.apiPath}/search`, {
      params: { ...fundusStudiesSearchParams },
    });
  }

  /**
   * 眼底検索結果の件数を取得する
   * @returns 眼底検査数
   * @param fundusStudiesSearchParams
   */
  searchFundusStudyCount(fundusStudiesSearchParams: FundusStudiesSearchFilters) {
    return this.http.get<FundusStudiesCount>(`${this.apiPath}/search/count`, {
      params: { ...fundusStudiesSearchParams },
    });
  }

  /**
   * 眼底検査タグ一覧を取得する
   * @returns 眼底検査タグ一覧
   */
  getFundusStudyTags() {
    return this.http.get<FundusStudyTags>(`${this.apiPath}/tags`);
  }
}
