import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {debounceTime, fromEvent, Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-text-auto-truncate',
  templateUrl: './text-auto-truncate.component.html',
  styleUrls: ['./text-auto-truncate.component.scss']
})
export class TextAutoTruncateComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('textWrap', { static: true }) textWrap!: ElementRef<HTMLElement>;
  @ViewChild('textInner', { static: true }) textEl!: ElementRef<HTMLElement>;
  private destroy$ = new Subject<void>();


  isOverflowing: boolean = false;
  normalTextWidth: number = 0;
  tooltipText: string = '';
  tooltipOffset: number = 0;
  isHovered: boolean = false;
  adjusting = false;

  ngOnInit() {
    fromEvent(window, 'resize')
      .pipe(
        debounceTime(1000),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        setTimeout(() => {
          this.checkOverflow();
        }, 300);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit() {
    this.tooltipText = this.textEl.nativeElement.textContent as string;
    this.checkOverflow();
  }

  private checkOverflow() {
    if(this.adjusting) return;

    this.adjusting = true;
    const wrapWidth = this.textWrap.nativeElement.offsetWidth;
    const textWidth = this.textEl.nativeElement.offsetWidth;
    this.tooltipOffset = wrapWidth;

    if(wrapWidth > textWidth) {
      this.normalTextWidth = textWidth;
    }

    if(this.normalTextWidth) {
      this.isOverflowing = textWidth < this.normalTextWidth;
    } else {
      this.isOverflowing = textWidth >= wrapWidth;
    }

    this.adjusting = false;
  }
}
