export const findings = [
  '異常所見なし',
  '判定不能',
  '緑内障の疑い',
  '緑内障性乳頭変化',
  '加齢黄斑変性',
  '網膜色素上皮の異常',
  '黄斑円孔',
  '網膜前膜',
  '黄斑部異常',
  '視神経乳頭陥凹の拡大',
  '網膜神経線維層欠損',
  '視神経乳頭出血',
  'ドルーゼン',
  '視神経乳頭の異常',
  '視神経乳頭浮腫',
  'うっ血乳頭',
  'その他の視神経乳頭異常',
  '糖尿病網膜症',
  '軟性白斑',
  '硬性白斑',
  '網膜出血',
  '点状出血',
  '網膜血管障害',
  '網膜中心静脈閉塞症',
  '網膜静脈分枝閉塞症',
  '網膜中心動脈閉塞症',
  '網膜血管異常',
  '網脈絡膜変性・萎縮',
  '網脈絡膜色素斑',
  '網膜色素変性',
  '有髄神経線維',
  '新生物',
  '硝子体混濁',
  '星状硝子体症',
  '閃輝性融解',
  'レーザー治療後',
  'その他の所見',
];
