<form
  class="card"
  fxLayout="column"
  fxLayoutGap="12px"
>
  <span class="title">プロフィール</span>
  <div fxLayout="column" fxLayoutGap="20px" *ngIf="loaded">
    <div fxLayout="column" fxLayoutGap="8px" *ngIf="loaded">
      <div fxLayout="column" fxLayoutGap="12px" *ngIf="loaded">
        <div fxLayout="row" fxLayoutAlign="left center" fxLayoutGap="20px">
          <div class="label" [style.height.px]="40">ユーザー名</div>
          <input type="text" class="user-name-input" [(ngModel)]="userName" name="userName" />
        </div>
        <div class="reset-to-google">
          <div  [ngClass]="{disabled: !canResetGoogleName}" (click)="canResetGoogleName && resetToGoogleName()">
            Google アカウント名に戻す
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="left center" fxLayoutGap="20px">
        <div class="label">メールアドレス</div>
        <div>{{userEmail}}</div>
      </div>
    </div>

    <mat-divider></mat-divider>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
    <app-button color="gray" type="button" width="94px" height="25px" [mat-dialog-close]="true"
    >キャンセル</app-button
    >
    <app-button
      type="submit"
      color="primary"
      width="52px"
      height="25px"
      (click)="submit()"
      [disabled]="lastSavedValue === userName || !userName"
    >保存</app-button
    >
  </div>

</form>
