import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import {Template, TemplatesResponse} from "@app/core/models/template.model";

import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TemplatesApiService {

  apiPath = `${environment.apiUrl}/templates`;

  constructor(private http: HttpClient) {}

  getTemplates() {
    return firstValueFrom(
      this.http.get<TemplatesResponse>(`${this.apiPath}`)
    );
  }

  upsertTemplate(request: Template) {
    return firstValueFrom(
      this.http.post<Template>(`${this.apiPath}`, request)
    );
  }

  deleteTemplate(id: string) {
    return firstValueFrom(
      this.http.delete<Template>(`${this.apiPath}/${id}`)
    );
  }
}
