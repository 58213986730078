export const newFukuda = [
  '網膜症なし',
  'A1',
  'A2',
  'B1',
  'A3',
  'A4',
  'A5',
  'B2',
  'B3',
  'B4',
  'B5',
];
