import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Auth0Service } from '@app/core/services';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsGuard  {
  constructor(private router: Router, private auth0Service: Auth0Service) {}

  async canActivate() {
    const organizations = await this.auth0Service.getBelongedOrganizations();
    if (!organizations.length) {
      this.router.navigate(['/organizations/create']);
    }
    return !!organizations.length;
  }
}
