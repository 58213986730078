import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { saveAs } from 'file-saver';

import { DiagnosticReport, FundusStudy } from '@app/diagnosis/models';
import { ProgressService } from '@app/core/services';
import { DiagnosticReportsApiService } from '@app/diagnosis/services';
import { b64toBlob } from '@app/shared/functions';

/**
 * 診断レポート API のビジネスロジックが実装されたクラス
 */
@Injectable({
  providedIn: 'root',
})
export class DiagnosticReportsService {
  constructor(
    private progressService: ProgressService,
    private diagnosticReportsApiService: DiagnosticReportsApiService
  ) {}

  /**
   * 署名付き URL からファイル名を取得する
   * @param signedUrl 署名付き URL
   * @returns ファイル名
   */
  private getFileName(signedUrl: string) {
    const paths = signedUrl.split('?')[0].split('/');
    return paths[paths.length - 1];
  }

  /**
   * 診断レポートを取得する
   * @param id 診断レポート ID
   * @returns 診断レポート
   */
  async getDiagnosticReport(id: string) {
    return firstValueFrom(
      this.diagnosticReportsApiService.getDiagnosticReport(id)
    );
  }

  /**
   * 診断レポート PDF を取得する
   * @param id 診断レポート ID
   * @returns 署名付き URL
   */
  async getDiagnosticReportPDF(id: string) {
    return firstValueFrom(
      this.diagnosticReportsApiService.getDiagnosticReportPDF(id)
    );
  }

  /**
   * 診断レポート PDF を取得する
   * @param ids 診断レポートID
   * @returns PDF (base64)
   */
  async getDiagnosticReportsPDF(ids: string[]) {
    return firstValueFrom(
      this.diagnosticReportsApiService.getDiagnosticReportsPDF(ids)
    );
  }

  /**
   * 診断レポート PDF を取得する
   * @param ids 診断レポートID
   * @returns SignedURL
   */
  async getMergedDiagnosticReportPDF(ids: string[]) {
    return firstValueFrom(
      this.diagnosticReportsApiService.getMergedDiagnosticReportPDF(ids)
    );
  }

  /**
   * 診断レポート一覧 PDF を取得する
   * @param ids 診断レポートID
   * @returns PDF (base64)
   */
  async getDiagnosticReportListPDF(ids: string[]) {
    return firstValueFrom(
      this.diagnosticReportsApiService.getDiagnosticReportListPDF(ids)
    );
  }

  /**
   * 診断レポート一覧 CSV を取得する
   * @param ids 診断レポートID
   * @returns PDF (base64)
   * @param encoding エンコーディング
   * @param excludeHeader ヘッダーを除外する
   */
  async getDiagnosticReportListCSV(ids: string[], encoding: string = 'shift-jis', excludeHeader: boolean = false) {
    return firstValueFrom(
      this.diagnosticReportsApiService.getDiagnosticReportListCSV(ids, encoding, excludeHeader)
    );
  }

  /**
   * 診断レポートを作成する
   * @param diagnosticReport 診断レポート
   * @returns 診断レポート
   */
  async createDiagnosticReport(diagnosticReport: DiagnosticReport) {
    return firstValueFrom(
      this.diagnosticReportsApiService.createDiagnosticReport(diagnosticReport)
    ).catch(() => alert('サーバーエラー'));
  }

  /**
   * 診断レポートを更新する
   * @param diagnosticReport 診断レポート
   * @returns 診断レポート
   */
  async updateDiagnosticReport(diagnosticReport: DiagnosticReport) {
    return firstValueFrom(
      this.diagnosticReportsApiService.updateDiagnosticReport(diagnosticReport)
    ).catch(() => alert('サーバーエラー'));
  }

  /**
   * 眼底検査から診断レポート ID を取り出し、診断レポート PDF をダウンロードする
   * @param fundusStudies 眼底検査
   * @returns 診断レポート
   */
  async downloadDiagnosticReportPDFs(fundusStudies: FundusStudy[]) {
    this.progressService.startProgress();

    try {
      const diagnosticReports = fundusStudies
        .filter(({ diagnosticReport }) => diagnosticReport?.id)
        .map(({ diagnosticReport }) => diagnosticReport as DiagnosticReport);

      for (const diagnosticReport of diagnosticReports) {
        const { signedUrl } = await this.getDiagnosticReportPDF(
          diagnosticReport.id
        );

        saveAs(signedUrl, this.getFileName(signedUrl));
      }
    } catch (error) {
      alert('サーバーエラー');
    }

    this.progressService.endProgress();
  }

  /**
   * 診断レポート PDF をダウンロードする
   * @param fundusStudies 眼底検査
   * @param filename ファイル名
   */
  async downloadDiagnosticReportsPDF(fundusStudies: FundusStudy[], filename = "") {
    this.progressService.startProgress();
    filename = filename || "診断結果詳細一覧";

    try {
      const ids = fundusStudies
        .filter((fundusStudy) => fundusStudy.diagnosticReport)
        .map((fundusStudy) => fundusStudy.diagnosticReport?.id || '');
      const { pdf } = await this.getDiagnosticReportsPDF(ids);
      saveAs(b64toBlob(pdf), `${filename}.pdf`);
    } catch (error) {
      alert('サーバーエラー');
    }

    this.progressService.endProgress();
  }

  /**
   * 診断レポート PDF をダウンロードする
   * @param fundusStudies 眼底検査
   * @param filename ファイル名
   */
  async downloadMergedDiagnosticReportPDF(fundusStudies: FundusStudy[], filename = "") {
    this.progressService.startProgress();
    filename = filename || "診断結果詳細一覧";

    try {
      const ids = fundusStudies
        .filter((fundusStudy) => fundusStudy.diagnosticReport)
        .map((fundusStudy) => fundusStudy.diagnosticReport?.id || '');
      const { signedUrl } = await this.getMergedDiagnosticReportPDF(ids);
      saveAs(signedUrl, `${filename}.pdf`);
    } catch (error) {
      alert('サーバーエラー');
    }

    this.progressService.endProgress();
  }

  /**
   * 診断レポート一覧 PDF をダウンロードする
   * @param fundusStudies 眼底検査
   * @param filename ファイル名
   */
  async downloadDiagnosticReportListPDF(fundusStudies: FundusStudy[], filename = "") {
    this.progressService.startProgress();
    filename = filename || '診断結果一覧';

    try {
      const ids = fundusStudies
        .filter((fundusStudy) => fundusStudy.diagnosticReport)
        .map((fundusStudy) => fundusStudy.diagnosticReport?.id || '');
      const { pdf } = await this.getDiagnosticReportListPDF(ids);
      saveAs(b64toBlob(pdf), `${filename}.pdf`);
    } catch (error) {
      alert('サーバーエラー');
    }

    this.progressService.endProgress();
  }

  /**
   * 診断レポート一覧 CSV をダウンロードする
   * @param fundusStudies 眼底検査
   * @param encoding エンコーディング
   * @param excludeHeader ヘッダーを除外する
   * @param filename ファイル名
   */
  async downloadDiagnosticReportListCSV(fundusStudies: FundusStudy[], encoding: string = 'shift-jis', excludeHeader: boolean = false, filename = "") {
    this.progressService.startProgress();
    filename = filename || '診断結果一覧';

    try {
      const ids = fundusStudies
        .filter((fundusStudy) => fundusStudy.diagnosticReport)
        .map((fundusStudy) => fundusStudy.diagnosticReport?.id || '');
      const { pdf } = await this.getDiagnosticReportListCSV(ids, encoding, excludeHeader);
      saveAs(b64toBlob(pdf), `${filename}.csv`);
    } catch (error) {
      alert('サーバーエラー');
    }

    this.progressService.endProgress();
  }
}
