export const scott = [
  '網膜症なし',
  '第Ia期',
  '第II期',
  '第IIIa期',
  '第IIIb期',
  '第Ib期',
  '第IV期',
  '第Va期',
  '第Vb期',
];
