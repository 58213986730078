import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let lsCache: any = {};

    const lsCacheString = localStorage.getItem('fundusApiCache');
    if (lsCacheString) {
      lsCache = JSON.parse(lsCacheString);
    }

    const cacheDuration = 60 * 60 * 1000; // キャッシュの有効期限は1時間

    const isOrgEndpoint = req.method === 'GET' && req.url.match(/\/api\/v2\/users\/.*\/organizations/);
    const isUserEndpoint = req.method === 'GET' && req.url.match(/\/api\/v2\/users\/[^\/]+$/);
    const isMemberEndpoint = req.method === 'GET' && req.url.match(/\/api\/v2\/organizations\/.*\/members/);
    const isCollaboratorsEndpoint = req.method === 'GET' && req.url.match(/\/v1\/collaborators/);
    const isUserPatch = req.method === 'PATCH' && req.url.match(/\/api\/v2\/users\/[^\/]+$/);

    if (isUserEndpoint || isOrgEndpoint || isMemberEndpoint || isCollaboratorsEndpoint) {
      const cachedResponse = lsCache?.[req.url];

      if (cachedResponse && (Date.now() - cachedResponse.timestamp) < cacheDuration) {
        return of(new HttpResponse({ body: JSON.parse(cachedResponse.data) }));
      }

      return next.handle(req).pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            lsCache[req.url] = {
              timestamp: Date.now(),
              data: JSON.stringify(event.body)
            };
            localStorage.setItem('fundusApiCache', JSON.stringify(lsCache));
          }
        })
      );

    } else if (isUserPatch) {
      return next.handle(req).pipe(
        tap(_ => {
          delete lsCache[req.url];
          localStorage.setItem('fundusApiCache', JSON.stringify(lsCache));
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
