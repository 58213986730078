<div
  class="container"
  [ngClass]="[color]"
  [class.readonly]="readonly"
  [style.height]="!readonly ? '40px' : ''"
  [style.opacity]="opacity"
  fxLayout="row"
  fxLayoutAlign=" center"
>
  <input
    type="text"
    fxFlex
    fxFlexAlign="stretch"
    [placeholder]="placeholder"
    [readonly]="readonly || disabled"
    [ngModel]="value.email"
    (ngModelChange)="setInputValue($event)"
  />
  <app-menu
    [value]="value.role"
    [options]="options"
    [readonly]="(readonly && !selectable) || disabled"
    [style.padding-right]="!readonly ? '8px' : ''"
    [style.padding-left]="!readonly ? '12px' : ''"
    [matTooltip]="selectTooltip || ''"
    (changed)="setSelectValue($event)"
    [hideDropdownIcon]="hideDropdownIcon"
  ></app-menu>
</div>
