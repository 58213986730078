import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-about-fundus',
  templateUrl: './about-fundus.component.html',
  styleUrls: ['./about-fundus.component.scss']
})
export class AboutFundusComponent {

  constructor(public dialogRef: MatDialogRef<AboutFundusComponent>) {}

}


