import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() type: 'submit' | 'reset' | 'button' = 'submit';
  @Input() color!: 'primary' | 'success' | 'warn' | 'gray';
  @Input() shadow = false;
  @Input() width? = '100px';
  @Input() height? = '40px';
  @Input() disabled!: boolean;
  @Output() clicked = new EventEmitter<void>();
}
