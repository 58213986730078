import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { MaterialModule } from '@app/material/material.module';
import { AppComponent, SettingDialogComponent } from '@app/core/containers';
import {
  OrganizationInfoSettingComponent,
  OrganizationMembersSettingComponent,
  SettingSidenavComponent,
  OrganizationIntegrationSettingComponent,
} from '@app/core/components';
import {CacheInterceptor, JwtInterceptor} from '@app/core/interceptors';
import { SharedModule } from '@app/shared/shared.module';
import { AboutFundusComponent } from './containers/about-fundus/about-fundus.component';
import { TemplateSettingComponent } from '@app/core/components';
import { ShortcutKeySettingComponent } from './components/shortcut-key-setting/shortcut-key-setting.component';
import { ReportSettingComponent } from './components/report-setting/report-setting.component';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from "@angular/material/legacy-slide-toggle";
import { ImportSettingComponent } from './components/import-setting/import-setting.component';
import { UserInfoSettingComponent } from './components/user-info-setting/user-info-setting.component';
import { DisplaySettingComponent } from './components/display-setting/display-setting.component';

@NgModule({
    declarations: [
        AppComponent,
        SettingDialogComponent,
        OrganizationInfoSettingComponent,
        OrganizationMembersSettingComponent,
        SettingSidenavComponent,
        OrganizationIntegrationSettingComponent,
        AboutFundusComponent,
        TemplateSettingComponent,
        ShortcutKeySettingComponent,
        ReportSettingComponent,
        ImportSettingComponent,
        UserInfoSettingComponent,
        DisplaySettingComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        MaterialModule,
        SharedModule,
        MatSlideToggleModule,
    ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
  ],
})
export class CoreModule {}
