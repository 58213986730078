import {Component, forwardRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {MatDateRangePicker} from "@angular/material/datepicker";
import {DatePipe} from "@angular/common";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MomentDateAdapter} from "@angular/material-moment-adapter";

export const FUNDUS_DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: FUNDUS_DATE_FORMATS },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangePickerComponent),
      multi: true,
    },
  ]
})
export class DateRangePickerComponent implements ControlValueAccessor, OnChanges {
  @Input() label!: string;
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() displayDateRangePicker: boolean = true;
  @ViewChild('picker') picker!: MatDateRangePicker<any>;
  value:  {start: string | null, end: string | null} = { start: null, end: null };
  onChange!: Function;
  OnTouched!: Function;
  constructor(private datePipe: DatePipe) { }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['displayDateRangePicker'] && changes['displayDateRangePicker'].currentValue === true) {
      if(changes['displayDateRangePicker'].previousValue === false) {
        setTimeout(() => {
          this.picker.open()
        });
      }
    }
  }

  dateChange(event: any, type: 'start' | 'end') {
    if(type ==='start') {
      this.value.start = this.datePipe.transform(event.value, 'yyyy-MM-dd');
    } else {
      this.value.end = this.datePipe.transform(event.value, 'yyyy-MM-dd');
    }

    this.onChange(this.value);
  }
  dateChangeByText(event: any, type: 'start' | 'end') {
    if(type ==='start') {
      this.value.start = this.datePipe.transform(event.target.value, 'yyyy-MM-dd');
    } else {
      this.value.end = this.datePipe.transform(event.target.value, 'yyyy-MM-dd');
    }
    this.onChange(this.value);
  }

  writeValue(obj: any) {
    obj && (this.value = obj);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.OnTouched = fn;
  }

}
